<template>
    <div class="sidebar">
        <el-menu
                class="sidebar-el-menu"
                :default-active="onRoutes"
                :collapse="collapse"
                background-color="#324157"
                text-color="#bfcbd9"
                active-text-color="#20a0ff"
                unique-opened
                router
        >
            <template v-for="item in items">
                <template v-if="item.subs">
                    <el-submenu :index="item.index" :key="item.index">
                        <template slot="title">
                            <i :class="item.icon"></i>
                            <span slot="title">{{ item.title }}</span>
                        </template>
                        <template v-for="subItem in item.subs">
                            <el-submenu
                                    v-if="subItem.subs"
                                    :index="subItem.index"
                                    :key="subItem.index"
                            >
                                <template slot="title">{{ subItem.title }}</template>
                                <el-menu-item
                                        v-for="(threeItem,i) in subItem.subs"
                                        :key="i"
                                        :index="threeItem.user"
                                >{{ threeItem.title }}</el-menu-item>
                            </el-submenu>
                            <el-menu-item
                                    v-else
                                    :index="subItem.index"
                                    :key="subItem.index"
                            >{{ subItem.title }}</el-menu-item>
                        </template>
                    </el-submenu>
                </template>
                <template v-else>
                    <el-menu-item :index="item.index" :key="item.index">
                        <i :class="item.icon"></i>
                        <span slot="title">{{ item.title }}</span>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>
    </div>
</template>

<script>
    import bus from '../common/bus';
    export default {
        data() {
            return {
                collapse: false,
                items: [
                    {
                        icon: 'el-icon-lx-people',
                        index: 'user',
                        title: '用户管理'
                    },
                    {
                        icon: 'el-icon-lx-service',
                        index: 'permission',
                        title: '权限管理'
                    },
                    {
                        icon: 'el-icon-lx-message',
                        index: 'tabs',
                        title: '栏目管理'
                    },
                    {
                        icon: 'el-icon-lx-cascades',
                        index: 'news',
                        title: '新闻管理'
                    },
                    {
                        icon: 'el-icon-office-building',
                        index: 'comment',
                        title: '评论管理'
                    },
                    {
                        icon: 'el-icon-video-play',
                        index: 'live',
                        title: '直播管理'
                    },
                    {
                        icon: 'el-icon-date',
                        index: 'notice',
                        title: '赛事预告'
                    },
                    {
                        icon: 'el-icon-edit-outline',
                        index: 'suggest',
                        title: '意见反馈'
                    },
                    {
                        icon: 'el-icon-s-finance',
                        index: 'push',
                        title: '消息推送'
                    },
                    {
                        icon: 'el-icon-picture-outline',
                        index: 'banner',
                        title: '轮播图'
                    },
                    {
                        icon: 'el-icon-lx-people',
                        index: 'riding',
                        title: '骑行管理'
                    },
                    {
                        icon: 'el-icon-data-line',
                        index: 'activity',
                        title: '活动管理'
                    },
                    {
                        icon: 'el-icon-help',
                        index: 'enroll',
                        title: '报名管理'
                    },
                    {
                        icon: 'el-icon-help',
                        index: 'medal',
                        title: '奖章管理'
                    },
                    {
                        icon: 'el-icon-help',
                        index: 'pc',
                        title: '川体门户网站管理',
                        subs: [{
                            icon: 'el-icon-help',
                            index: 'bannerManage',
                            title: '轮播图管理'
                        },
                        {
                            icon: 'el-icon-help',
                            index: 'liveManage',
                            title: '直播管理'
                        },
                        {
                            icon: 'el-icon-help',
                            index: 'depthManage',
                            title: '深度聚焦管理'
                        },
                        {
                            icon: 'el-icon-help',
                            index: 'photoManage',
                            title: '赛事精彩锦集管理'
                        }
                    ]
                    },
                ]
            };
        },
        computed: {
            onRoutes() {
                return this.$route.path.replace('/', '');
            }
        },
        created() {
            var userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
            var menu = [];
            this.items.forEach(function (item){
                userPermissions.forEach(function (perm) {
                    // console.log('perm.permissionCode='+perm.permissionCode+'---index='+item.index);
                    if(perm.permissionCode == item.index){
                        menu.push(item);
                    }
                });
            });
            this.items = menu;
            // console.log('menu='+JSON.stringify(menu));
            // 通过 Event Bus 进行组件间通信，来折叠侧边栏
            bus.$on('collapse', msg => {
                this.collapse = msg;
                bus.$emit('collapse-content', msg);
            });
        }
    };
</script>

<style scoped>
    .sidebar {
        display: block;
        position: absolute;
        left: 0;
        top: 70px;
        bottom: 0;
        overflow-y: scroll;
    }
    .sidebar::-webkit-scrollbar {
        width: 0;
    }
    .sidebar-el-menu:not(.el-menu--collapse) {
        width: 250px;
    }
    .sidebar > ul {
        height: 100%;
    }
</style>
