var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.parameter.id ? "编辑深度聚焦" : "新增深度聚焦",
            visible: _vm._dialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm._dialogVisible = $event
            },
            close: _vm.cancel,
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                { attrs: { "label-width": "180px" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序：", required: "" } },
                    [
                      _c("el-input-number", {
                        model: {
                          value: _vm.parameter.num,
                          callback: function ($$v) {
                            _vm.$set(_vm.parameter, "num", $$v)
                          },
                          expression: "parameter.num",
                        },
                      }),
                      _c("span", [_vm._v("（数字越大越靠前）")]),
                    ],
                    1
                  ),
                  _vm.parameter.linkType == 0
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "关联新闻id", required: "" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入新闻id",
                              clearable: "",
                            },
                            model: {
                              value: _vm.newsId,
                              callback: function ($$v) {
                                _vm.newsId = $$v
                              },
                              expression: "newsId",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.parameter.linkType == 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "外链地址：", required: "" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: _vm.parameter.url,
                              callback: function ($$v) {
                                _vm.$set(_vm.parameter, "url", $$v)
                              },
                              expression: "parameter.url",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题：", required: "" } },
                    [
                      _vm.parameter.linkType == 1
                        ? _c("el-input", {
                            attrs: { placeholder: "请输入", clearable: "" },
                            model: {
                              value: _vm.parameter.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.parameter, "title", $$v)
                              },
                              expression: "parameter.title",
                            },
                          })
                        : _c("div", [_vm._v(_vm._s(_vm.title))]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态：", required: "" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 0 },
                          model: {
                            value: _vm.parameter.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.parameter, "status", $$v)
                            },
                            expression: "parameter.status",
                          },
                        },
                        [_vm._v("上架")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.parameter.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.parameter, "status", $$v)
                            },
                            expression: "parameter.status",
                          },
                        },
                        [_vm._v("下架")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                [_vm._v("保存")]
              ),
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }