<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-service"></i> 权限管理
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <el-button
                        type="primary"
                        icon="el-icon-lx-roundadd"
                        class="handle-del mr10"
                        @click="addRole"
                >新增角色</el-button>
            </div>
            <el-table
                :data="tableData"
                border
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
            >
                <el-table-column prop="id" label="角色编号" align="center"></el-table-column>
                <el-table-column prop="roleName" label="角色名" align="center"></el-table-column>
                <el-table-column prop="updatedTime" label="修改时间" align="center"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button
                                type="text"
                                icon="el-icon-edit"
                                @click="handleEdit(scope.row.id, scope.row)"
                        >编辑</el-button>
                        <el-button
                                type="text"
                                icon="el-icon-lx-profile"
                                @click="handlePer(scope.row.id)"
                        >设置权限</el-button>
                        <el-button
                                type="text"
                                icon="el-icon-delete"
                                class="red"
                                @click="handleDelete(scope.row.id)"
                        >删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :current-page="query.pageIndex"
                    :page-size="query.pageSize"
                    :total="pageTotal"
                    @current-change="handlePageChange"
                ></el-pagination>
            </div>
        </div>

        <!-- 新增弹出框 -->
        <el-dialog title="新增角色" :visible.sync="addVisible" width="30%">
            <el-form ref="eform" :model="form" label-width="70px">
                <el-form-item label="角色名">
                    <el-input v-model="form.roleName"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveAdd">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 编辑弹出框 -->
        <el-dialog title="编辑角色" :visible.sync="editVisible" width="30%" @close="clean">
            <el-form ref="form" :model="form" label-width="70px">
                <el-form-item v-show="false" label="编号">
                    <el-input v-model="form.id"></el-input>
                </el-form-item>
                <el-form-item label="角色名">
                    <el-input v-model="form.roleName"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="clean">取 消</el-button>
                <el-button type="primary" @click="saveEdit">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 权限设置 -->
        <el-dialog title="权限设置" :visible.sync="perVisible" width="30%">
            <el-tree
                    :data="treeData"
                    show-checkbox
                    node-key="id"
                    ref="tree"
                    @check="treeCk"
                    highlight-current
                    check-strictly
                    :props="defaultProps"
            >
            </el-tree>
            <span slot="footer" class="dialog-footer">
                <el-button @click="perVisible = false">取 消</el-button>
                <el-button type="primary" @click="savePer">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {getAllRole} from '../../api/role';
    import {addRole} from '../../api/role';
    import {editRole} from '../../api/role';
    import {delRole} from '../../api/role';
    import {getAllPermission} from "../../api/role";
    import {getRolePermission} from "../../api/role";
    import {saveRolePermission} from "../../api/role";

    var getAllRoleRequest = {url :"usercenter/role/page-list"};
    var addRoleRequest = {url:"/usercenter/role"};
    var editRoleRequest = {url :"/usercenter/role"};
    var delRoleRequest = {url :"/usercenter/role/{id}"};
    var saveRoleRequest = {url:"/usercenter/role-permission"};

    var getAllPer = {url:"/usercenter/permission/all"};
    var getRolePer  = {url :"/usercenter/role-permission/"};
    export default {
    name: 'basetable',
    data() {
        return {
            query: {
                pageIndex: 1,
                pageSize: 10,
            },
            tableData: [],
            defaultProps: {
                children: 'children',
                label: 'permissionName'
            },
            treeData:[],
            addVisible:false,
            editVisible:false,
            perVisible:false,
            multipleSelection: [],
            delList: [],
            selectKeys :[],
            pageTotal: 0,
            form: {},
            idx: -1,
            id: -1,
            roleId:-1,
            saveRoleQuery:{
                permissionIdList:[],
                roleId:0
            }
        };
    },
    mounted(){
        window.addEventListener('beforeunload', e => this.beforeunloadHandler(e));
        window.addEventListener('unload', e => this.unloadHandler(e));
    },
    destroyed() {
        window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e));
        window.removeEventListener('unload', e => this.unloadHandler(e))
    },
    created() {
        this.getData();
        this.initTreeData();
    },
    methods: {
        beforeunloadHandler(){
            this._beforeUnload_time=new Date().getTime();
        },
        unloadHandler(e){
            this._gap_time=new Date().getTime()-this._beforeUnload_time;
            //判断是窗口关闭还是刷新
            if(this._gap_time<=5){
                //关闭窗口前，清除用户
                localStorage.removeItem('ms_username');
                localStorage.removeItem('token');
                localStorage.removeItem('userPermissions');
            }
        },
        // 获取表单数据
        getData() {
            getAllRoleRequest.data = this.query;
            getAllRole(getAllRoleRequest).then(res => {
                this.tableData = res.data.list;
                this.pageTotal = res.data.total;
            });
        },
        // 刷新页面数据
        handleRefresh() {
            this.$set(this.query, 'pageIndex', 1);
            this.getData();
        },
        // 清空数据
        clean(){
            this.editVisible=false;
            this.handleRefresh();
        },
        // 分页导航
        handlePageChange(val) {
            this.$set(this.query, 'pageIndex', val);
            this.getData();
        },
        //新增角色
        addRole(){
            this.form={};
            this.addVisible = true;
        },
        // 新增保存
        saveAdd(){
            addRoleRequest.data = this.form;
            addRole(addRoleRequest).then(res =>{
                if (res.code == 0) {
                    this.$message.success(`添加成功`);
                    this.addVisible = false;
                    this.handleRefresh();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        //编辑角色
        handleEdit(id,row){
            this.id = id;
            this.form = row;
            this.form.id = this.id;
            this.editVisible = true;
        },
        saveEdit(){
            editRoleRequest.data = this.form;
            editRole(editRoleRequest).then(res =>{
                if (res.code == 0) {
                    this.$message.success(`修改成功`);
                    this.editVisible = false;
                    this.handleRefresh();
                } else {
                    this.$message.error(res.message);
                }
            })
        },
        getPermission() {
            if (window.sessionStorage.permission) {
                return JSON.parse(window.sessionStorage.permission);
            }
            return null;
        },
        // 删除操作
        handleDelete(id) {
            // 二次确认删除
            this.$confirm('确认删除此角色？', '提示', {
                type: 'warning'
            }).then(() => {
                delRoleRequest.id = id;
                delRole(delRoleRequest).then(res => {
                    if (res.code == 0) {
                        this.$message.success(`删除成功`);
                        this.handleRefresh();
                    } else {
                        this.$message.error(res.message);
                    }
                });
            });
        },
        initTreeData(){
            getAllPermission(getAllPer).then(res =>{
                this.treeData=res.data;
            })
        },
        //权限设置
        handlePer(id){
            this.roleId = id;
            //获取该角色的所有权限
            getRolePer.id = id;
            getRolePermission(getRolePer).then(res =>{
                this.$refs.tree.setCheckedNodes(res.data);
            });
            this.perVisible = true;
        },
        //保存权限设置
        savePer(){
            this.selectKeys = this.$refs.tree.getCheckedKeys();
            this.saveRoleQuery.permissionIdList = this.selectKeys;
            this.saveRoleQuery.roleId = this.roleId;
            saveRoleRequest.data = this.saveRoleQuery;
            saveRoleRequest.data.roleId  = this.roleId;
            saveRolePermission(saveRoleRequest).then(res =>{
                if (res.code ==0 ){
                    this.$message.success(`设置成功`);
                    this.perVisible = false;
                }else {
                    this.$message.error(res.message);
                }
            })
        },
        //点击权限节点
        treeCk(data){
            let checkNode  = this.$refs.tree.getNode(data);
            //勾选子节点，默认勾选父节点
            if (checkNode.checked && checkNode.parent.level===1 && !checkNode.parent.checked){
                this.$refs.tree.setChecked(checkNode.parent.data.id,true);
            }

            //取消父节点，默认取消所有子节点
            if(!checkNode.checked && checkNode.parent.level===0){
                let cancelKeys = [];
                checkNode.childNodes.forEach(function (cn) {
                    cancelKeys.push(cn.data.id);
                });
                let arr = this.$refs.tree.getCheckedKeys();
                cancelKeys.forEach(function (c) {
                   arr.forEach(function (k) {
                       if (c===k){
                           let index = arr.indexOf(c);
                           if(index!=-1){
                               arr.splice(index,1);
                           }
                       }
                   })
                });
                this.$refs.tree.setCheckedKeys(arr);
            }
        }
    }
};
</script>

<style scoped>
.handle-box {
    margin-bottom: 20px;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
</style>
