var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加组别",
            visible: _vm._dialogVisible,
            "show-close": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm._dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: { "label-width": "130px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "组别名称：", required: "" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.groupInfo.groupName,
                          callback: function ($$v) {
                            _vm.$set(_vm.groupInfo, "groupName", $$v)
                          },
                          expression: "groupInfo.groupName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "参赛价格：", required: "" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          precision: 2,
                          step: 0.1,
                          min: 0,
                          max: 99999999,
                        },
                        model: {
                          value: _vm.groupInfo.price,
                          callback: function ($$v) {
                            _vm.$set(_vm.groupInfo, "price", $$v)
                          },
                          expression: "groupInfo.price",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "参赛人数：", required: "" } },
                    [
                      _c("el-input-number", {
                        attrs: { min: 0, max: 99999999 },
                        model: {
                          value: _vm.groupInfo.stock,
                          callback: function ($$v) {
                            _vm.$set(_vm.groupInfo, "stock", $$v)
                          },
                          expression: "groupInfo.stock",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "参赛年龄：", required: "" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetimerange",
                          "range-separator": "至",
                          "start-placeholder": "最大",
                          "end-placeholder": "最小",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        model: {
                          value: _vm.ageTime,
                          callback: function ($$v) {
                            _vm.ageTime = $$v
                          },
                          expression: "ageTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "参赛性别：", required: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.groupInfo.gender,
                            callback: function ($$v) {
                              _vm.$set(_vm.groupInfo, "gender", $$v)
                            },
                            expression: "groupInfo.gender",
                          },
                        },
                        _vm._l(_vm.options, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "组别类型：", required: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.groupInfo.groupType,
                            callback: function ($$v) {
                              _vm.$set(_vm.groupInfo, "groupType", $$v)
                            },
                            expression: "groupInfo.groupType",
                          },
                        },
                        _vm._l(_vm.options2, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "组别比赛地址：", required: "" } },
                    [
                      _c("SelectAddress", {
                        attrs: { addressCode: _vm.addressCode, site: _vm.site },
                        on: {
                          update: _vm.update,
                          "update:addressCode": function ($event) {
                            _vm.addressCode = $event
                          },
                          "update:address-code": function ($event) {
                            _vm.addressCode = $event
                          },
                          "update:site": function ($event) {
                            _vm.site = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "详细地址：", required: "" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.groupInfo.addressDetail,
                          callback: function ($$v) {
                            _vm.$set(_vm.groupInfo, "addressDetail", $$v)
                          },
                          expression: "groupInfo.addressDetail",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "比赛时间：", required: "" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetimerange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        model: {
                          value: _vm.gameTime,
                          callback: function ($$v) {
                            _vm.gameTime = $$v
                          },
                          expression: "gameTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "线上缴付：", required: "" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 0 },
                          model: {
                            value: _vm.groupInfo.payType,
                            callback: function ($$v) {
                              _vm.$set(_vm.groupInfo, "payType", $$v)
                            },
                            expression: "groupInfo.payType",
                          },
                        },
                        [_vm._v("是")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.groupInfo.payType,
                            callback: function ($$v) {
                              _vm.$set(_vm.groupInfo, "payType", $$v)
                            },
                            expression: "groupInfo.payType",
                          },
                        },
                        [_vm._v("否")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                [_vm._v("确定")]
              ),
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }