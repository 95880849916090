import request from '../utils/request';

export const fetchDataCustom = customParam => {
    return request(customParam);
};

export const fetchDataPost = query => {
    return request({
        url: query.url,
        method: 'post',
        data: query.data
    });
};

export const fetchDataGet = query => {
    let url = query.url.replace("{id}", query.id);
    return request({
        url: url,
        method: 'get',
        params: query.data
    });
};

export const fetchDataPut = query => {
    let url = query.url.replace("{id}", query.id);
    if (query.isParamData) {
        return request({
            url: url,
            method: 'put',
            params: query.data
        });
    } else {
        return request({
            url: url,
            method: 'put',
            data: query.data
        });
    }
};

export const fetchDataDel = query => {
    let url = query.url.replace("{id}", query.id);
    return request({
        url: url,
        method: 'delete',
        data: query.data
    });
};

export const getPermission = query => {
    return request({
        url: query.url,
        method: 'get',
        params: query.data
    });
};
