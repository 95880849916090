<template>
	<div>
		<div class="container">
			<!-- 1.赛事编辑 -->
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item>赛事编辑 </el-breadcrumb-item>
				<el-breadcrumb-item>组别详情</el-breadcrumb-item>
				<el-breadcrumb-item><span class="compile">报名表单</span></el-breadcrumb-item>
			</el-breadcrumb>

			<el-form ref="ruleForm" label-width="130px" class="demo-ruleForm">
				<el-form-item label="姓名：" required>
					<el-input disabled v-model="value1" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="性别：" required>
					<el-select disabled v-model="value13" placeholder="请选择">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="出生日期：" required>
					<el-date-picker disabled v-model="value2" type="date" placeholder="选择日期"> </el-date-picker>
				</el-form-item>
				<el-form-item label="手机号：" required>
					<el-input disabled v-model="value3" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="证件号：" required>
					<el-input disabled v-model="value4" placeholder="请输入身份证号"></el-input>
				</el-form-item>
				<el-form-item label="政治面貌：">
					<el-select disabled v-model="value5" placeholder="请选择">
						<el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value"> </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="民族：" required>
					<el-select disabled v-model="value6" placeholder="请选择">
						<el-option v-for="item in nationList" :key="item.name" :label="item.name" :value="item.name"> </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="是否体育从事者：">
					<el-select disabled v-model="value7" placeholder="请选择">
						<el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value"> </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="从事职业：">
					<el-select disabled v-model="value8" placeholder="请选择">
						<el-option v-for="item in options4" :key="item.value" :label="item.label" :value="item.value"> </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="所在地址：" required>
					<el-input disabled v-model="value1" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="详细地址：" required>
					<el-input disabled v-model="value9" placeholder="请输入"></el-input>
				</el-form-item>
				<!-- <el-form-item label="紧急联系人：" required>
					<el-input disabled v-model="value10" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="紧急联系电话：" required>
					<el-input disabled v-model="value11" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="与紧急人关系：" required> 
					<el-select disabled v-model="value12" placeholder="请选择">
						<el-option v-for="item in options5" :key="item.value" :label="item.label" :value="item.value"> </el-option>
					</el-select>
				</el-form-item> -->
				<!-- 添加的活动字段 -->
				<el-form-item
					v-for="(item, index) in extraList"
					:key="index + '1'"
					:label="item.key + '：'"
					:required="item.required"
				>
				<div class="elasticity">
					<!-- 单行输入框 -->
					<el-input v-if="item.type == 'text'" disabled placeholder="请输入内容" v-model="item.value[0]" clearable> </el-input>
					<!-- 多行输入框 -->
					<el-input
						v-if="item.type == 'textarea'"
						disabled
						type="textarea"
						placeholder="请输入内容"
						v-model="item.value[0]"
						clearable
					>
					</el-input>
					<!-- 上传图片 -->
					<!-- <UploadImg v-if="item.type == 'uploadImg'" v-model="item.value[0]" /> -->
					<el-image v-if="item.type == 'uploadImg'">
						<div slot="error" class="image-slot">
							<i class="el-icon-picture-outline"></i>
						</div>
					</el-image>
					<!-- 下拉选择框 -->
					<el-select v-if="item.type == 'select'" disabled v-model="item.value[0]" placeholder="请选择">
						<el-option v-for="item in item.item" :key="item[0]" :label="item[0]" :value="item[0]"> </el-option>
					</el-select>
					<!-- 单选框 -->
					<div v-if="item.type == 'radio'">
						<el-radio v-model="item.value[0]" disabled v-for="items in item.item" :key="items.value" :label="items.value">{{
							items[0]
						}}</el-radio>
					</div>
					<!-- 多选框-->
					<div v-if="item.type == 'checkbox'">
						<el-checkbox v-for="items in item.item" disabled :key="items" v-model="items.status">{{ items[0] }}</el-checkbox>
					</div>
					<i class="addIcon el-icon-remove-outline" @click="dellineAdd(index)"></i>
					</div>
				</el-form-item>
				<!-- 按钮 -->
				<el-form-item label="">
					<el-button type="primary" @click="back" v-if="stepState">上一步</el-button>
					<el-button @click="submit">添加报名字段</el-button>
					<el-button type="primary" @click="establish" v-if="detailState">创建报名表</el-button>
				</el-form-item>
			</el-form>
		</div>
		<DialogAdd :dialogVisible.sync="dialogVisible" @extra="extra" />
	</div>
</template>
<script>
import bus from '@/components/common/bus'; //bus全局通讯
import DialogAdd from './components/DialogAdd2'; //添加活动字段
import UploadImg from './components/UploadImg'; //上传图片
import SelectAddress from './components/SelectAddress'; //选择地址
import * as API from '@/api/activity';
export default {
	name: 'registrationForm',
	components: {
		UploadImg,
		DialogAdd,
		SelectAddress
	},
	data() {
		return {
			detailState:true,
			stepState: true,
			saveState: {},
			info: '',
			sponsor: '',
			groupInfo: '',
			extraList: [],
			dialogVisible: false,
			value1: '',
			value2: '',
			value3: '',
			value4: '',
			value5: '',
			value6: '',
			value7: '',
			value8: '',
			value9: '',
			value10: '',
			value11: '',
			value12: '',
			value13: '',
			value14: '',
			options: [
				{
					value: '选项1',
					label: '男'
				},
				{
					value: '选项2',
					label: '女'
				}
			],
			options2: [
				{
					value: '群众',
					label: '群众'
				},
				{
					value: '共青团员',
					label: '共青团员'
				},
				{
					value: '预备党员',
					label: '预备党员'
				},
				{
					value: '党员',
					label: '党员'
				}
			],
			options3: [
				{
					value: '是',
					label: '是'
				},
				{
					value: '否',
					label: '否'
				}
			],
			options4: [
				{
					value: '运动员',
					label: '运动员'
				},
				{
					value: '教练员',
					label: '教练员'
				},
				{
					value: '裁判员',
					label: '裁判员'
				},
				{
					value: '其他',
					label: '其他'
				}
			],
			options5: [
				{
					value: '0',
					label: '父子/女'
				},
				{
					value: '1',
					label: '母子/女'
				},
				{
					value: '2',
					label: '兄弟/姐妹'
				},
				{
					value: '3',
					label: '朋友/同事'
				},
				{
					value: '4',
					label: '其他'
				}
			],
			nationList: [
				{ id: 1, name: '汉族' },
				{ id: 2, name: '蒙古族' },
				{ id: 3, name: '回族' },
				{ id: 4, name: '藏族' },
				{ id: 5, name: '维吾尔族' },
				{ id: 6, name: '苗族' },
				{ id: 7, name: '彝族' },
				{ id: 8, name: '壮族' },
				{ id: 9, name: '布依族' },
				{ id: 10, name: '朝鲜族' },
				{ id: 11, name: '满族' },
				{ id: 12, name: '侗族' },
				{ id: 13, name: '瑶族' },
				{ id: 14, name: '白族' },
				{ id: 15, name: '土家族' },
				{ id: 16, name: '哈尼族' },
				{ id: 17, name: '哈萨克族' },
				{ id: 18, name: '傣族' },
				{ id: 19, name: '黎族' },
				{ id: 20, name: '傈僳族' },
				{ id: 21, name: '佤族' },
				{ id: 22, name: '畲族' },
				{ id: 23, name: '高山族' },
				{ id: 24, name: '拉祜族' },
				{ id: 25, name: '水族' },
				{ id: 26, name: '东乡族' },
				{ id: 27, name: '纳西族' },
				{ id: 28, name: '景颇族' },
				{ id: 29, name: '柯尔克孜族' },
				{ id: 30, name: '土族' },
				{ id: 31, name: '达翰尔族' },
				{ id: 32, name: '么佬族' },
				{ id: 33, name: '羌族' },
				{ id: 34, name: '布朗族' },
				{ id: 35, name: '撒拉族' },
				{ id: 36, name: '毛南族' },
				{ id: 37, name: '仡佬族' },
				{ id: 38, name: '锡伯族' },
				{ id: 39, name: '阿昌族' },
				{ id: 40, name: '普米族' },
				{ id: 41, name: '塔吉克族' },
				{ id: 42, name: '怒族' },
				{ id: 43, name: '乌孜别克族' },
				{ id: 44, name: '俄罗斯族' },
				{ id: 45, name: '鄂温克族' },
				{ id: 46, name: '德昂族' },
				{ id: 47, name: '保安族' },
				{ id: 48, name: '裕固族' },
				{ id: 49, name: '京族' },
				{ id: 50, name: '塔塔尔族' },
				{ id: 51, name: '独龙族' },
				{ id: 52, name: '鄂伦春族' },
				{ id: 53, name: '赫哲族' },
				{ id: 54, name: '门巴族' },
				{ id: 55, name: '珞巴族' },
				{ id: 56, name: '基诺族' },
				{ id: 57, name: '其他' }
			]
		};
	},
	methods: {
		   //删除标签页
        delline() {
           bus.$emit('deleteTag', ['activityEdit','groupDetail','registrationForm']);
        },
		//删除自定义报名字段
		dellineAdd(index) {
			this.extraList.splice(index, 1);
		},
		//上一步
		back() {
			this.$router.push({
				path: 'groupDetail'
			});
		},
		//添加报名字段
		submit() {
			this.dialogVisible = true;
		},
		//创建报名表
		establish() {
			let res = JSON.parse(localStorage.getItem('editData')); //活动基本信息
			let res2 = JSON.parse(localStorage.getItem('tableData')); //组别list
			this.info = res.info; //活动基本信息
			this.sponsor = res.sponsor; //主办方
			this.groupInfo = res2; //组别
			this.info.customTag = JSON.stringify(res.extraList); //活动自定义标签
			this.info.applyUserCustomTag = JSON.stringify(this.extraList); //用户自定义标签
			this.addActivity();
		},
		//拿到添加的额外参数
		extra(val) {
			this.extraList.push(val);
		},

		/** 添加或编辑活动详情 */
		async addActivity() {
			let parameter = {
				info: this.info,
				sponsor: this.sponsor,
				groupInfo: this.groupInfo,
				id: this.saveState.id
			};
			let res = await API.addActivity(parameter);
		
			if (res.code == 0) {
				  this.delline()
					this.$message({
				message: '活动创建成功',
				 type: 'success'
			});
				this.$router.push({
					path: 'activity'
				});
			}else{
				  this.$message.error(res.message);
			}
		},
		/** 获取活动详情 */
		async getActivityInfo() {
			let parameter = {
				id: this.saveState.id
			};
			let res = await API.getActivityInfo(parameter);
			this.info = res.data.info;
			this.sponsor = res.data.sponsor;
			this.groupInfo = res.data.groupInfo;
			this.extraList = JSON.parse(this.info.applyUserCustomTag);
		}
	},
	activated() {
		this.stepState = true;
		this.saveState = JSON.parse(localStorage.getItem('addEdit'));
		//id == 'detail' 为详情按钮进来，不能创建
		if(this.saveState.state == 'detail'){
			this.detailState = false
		}else{
			this.detailState = true
		}
		//编辑时id会有值
		if (this.saveState.id) {
			if (this.$route.params.info) {
				this.extraList = JSON.parse(this.$route.params.info.applyUserCustomTag);
			}
		}
		if (this.$route.params.id) {
			this.stepState = false; //编辑报名表时，不能上一步
			this.saveState.id = this.$route.params.id;
			this.getActivityInfo();
		}
	}
};
</script>
<style  scoped lang="less">
.elasticity {
	display: flex;
	align-items: center; 
}
.el-icon-picture-outline {
	font-size: 100px;
	color: #c0c4cc;
}
.addIcon {
	margin-left: 20px;
	font-size: 22px;
	cursor: pointer;
}
.el-input {
	width: 50%;
}
.demo-ruleForm {
	margin-top: 30px;
}
.compile {
	color: #409eff;
	font-weight: bold;
}
</style>