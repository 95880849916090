<template>
  <div>
    <div class="container">
       <div class="title" style="margin-bottom:20px">活动名称:{{activityName}}</div>
      <el-form ref="ruleForm" label-width="130px" class="demo-ruleForm" :inline="true">
        <!-- 个人信息 -->
        <div class="title">个人信息</div>
        <el-form-item label="姓名：">
          <div>{{ user.name }}</div>
        </el-form-item>
        <el-form-item label="性别：">
          <div>{{ user.gender == 0 ? '女' : '男' }}</div>
        </el-form-item>
        <el-form-item label="出生日期：">
          <div>{{ user.birthday }}</div>
        </el-form-item>
        <el-form-item label="手机号：">
          <div>{{ user.phone }}</div>
        </el-form-item>
        <el-form-item label="证件号：">
          <div>{{ user.idCard }}</div>
        </el-form-item>
        <el-form-item label="政治面貌：">
          <div>{{ user.politicsStatus }}</div>
        </el-form-item>
        <el-form-item label="民族：">
          <div>{{ user.nation }}</div>
        </el-form-item>
        <el-form-item label="是否体育从事者：">
          <div>{{ user.sportsPractitioner == 0 ? '不是' : '是' }}</div>
        </el-form-item>
        <el-form-item label="从事职业：">
          <div>{{ user.occupation }}</div>
        </el-form-item>
        <el-form-item label="所在地址：">
          <div>{{ user.address }}</div>
        </el-form-item>
        <el-form-item label="详细地址：">
          <div>{{ user.addressDetails }}</div>
        </el-form-item>
        <el-form-item label="紧急联系人：">
          <div>{{ user.emergencyContactName }}</div>
        </el-form-item>
        <el-form-item label="紧急联系电话：">
          <div>{{ user.emergencyContactPhone }}</div>
        </el-form-item>
        <el-form-item label="与紧急人关系：">
          <div>{{ user.emergencyContactRelation }}</div>
        </el-form-item>
        <!-- 添加的活动字段 -->

        <div>
          <el-tag>个人自定义字段</el-tag>
        </div>

        <el-form-item v-for="(item, index) in user.items" :key="index + '1'" :label="item.key + '：'"
          :required="item.required">
          <el-image v-if="item.type == 'uploadImg'" :src="item.value[0]" style="width:200px;height:200px"></el-image>
          <div v-else>{{ item.value[0] }}</div>
        </el-form-item>
        <!-- 组别信息 -->
        <div class="title">组别信息</div>
        <el-form-item label="组别名称：">
          <div>{{ group.groupName }}</div>
        </el-form-item>
        <el-form-item label="参赛价格：">
          <div>{{ group.price }}</div>
        </el-form-item>
        <el-form-item label="参赛人数：">
          <div>{{ group.stock }}</div>
        </el-form-item>
        <el-form-item label="参赛年龄：">
          <div>{{ group.minAge }} 至 {{ group.maxAge }}</div>
        </el-form-item>
        <el-form-item label="参赛性别：">
          <div>{{ gender[group.gender] }}</div>
        </el-form-item>
        <el-form-item label="组别类型：">
          <div>{{ group.groupType }}</div>
        </el-form-item>
        <el-form-item label="组别比赛地址：">
          <div>{{ group.address }}</div>
        </el-form-item>
        <el-form-item label="详细地址：">
          <div>{{ group.addressDetail }}</div>
        </el-form-item>
        <el-form-item label="比赛时间：">
          <div>{{ group.matchStartTime }} 至 {{ group.matchEndTime }}</div>
        </el-form-item>
        <el-form-item label="线上缴付：">
          <div>{{ group.payType == 0 ? '线上支付' : '线下支付' }}</div>
        </el-form-item>
        <!-- 添加的活动字段 -->
        <div>
          <el-tag>组别自定义字段</el-tag>
        </div>
        <el-form-item v-for="(item, index) in group.customItems" :key="index + '1'" :label="item.key + '：'"
          :required="item.required">
          <el-image v-if="item.type == 'uploadImg'" :src="item.value[0]" style="width:200px;height:200px"></el-image>
          <div v-else>{{ item.value[0] }}</div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import * as API from '@/api/activity';
export default {
    name: 'index',
    data() {
        return {
          activityName:"",
            gender: {
                0: '女',
                1: '男',
                2: '不限'
            },
            name: '张三',
            id: '',
            detail: {},
            group: {},
            user: {}
        };
    },
    methods: {
        //报名人详情
        async getActivityUserInfo() {
            let parameter = {
                id: this.id
            };
            let res = await API.getActivityUserInfo(parameter);
            this.group = res.data.group;
            this.user = res.data.user;
            this.activityName = res.data.activityName
        }
    },
    mounted() {
        if (this.$route.query.id) {
            this.id = this.$route.query.id;
            this.getActivityUserInfo();
        }
    },
    activated() {
        if (this.$route.query.id) {
            this.id = this.$route.query.id;
            this.getActivityUserInfo();
        }
    }
};
</script>
<style  scoped lang="less">
.el-form-item {
    width: 49%;
}
.title {
    font-weight: bold;
}
</style>