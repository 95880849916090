<script src="../../main.js"></script>
<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-people"></i> 评论管理
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">

                <el-input v-model="query.articleTitle" placeholder="新闻名称" class="handle-input mr10"></el-input>
                <el-input v-model="query.commentDesc" placeholder="评论内容" class="handle-input mr10"></el-input>
                <el-button type="primary" icon="el-icon-search"  @click="handleSearch">搜索</el-button>
            </div>
            <el-table
                    :data="tableData"
                    border
                    class="table"
                    ref="multipleTable"
                    header-cell-class-name="table-header"
            >
                <el-table-column prop="cusNikeName" label="评论人"></el-table-column>
                <el-table-column prop="cusHeadImg" label="评论人头像">
                    <template slot-scope="scope">
                    <el-image
                            style="width: 100px; height: 100px"
                            :src="scope.row.cusHeadImg"
                           ></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="articleTitle" label="新闻标题"></el-table-column>
                <el-table-column prop="colSort" label="评论内容">
                    <template slot-scope="scope">
                        <el-popover
                                placement="top-start"
                                width="200"
                                trigger="hover"
                                :content=scope.row.commentDesc>
                            <el-button slot="reference">查看评论内容</el-button>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column prop="commentStatus" label="评论状态">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.commentStatus==0" type="info">待审批</el-tag>
                        <el-tag v-if="scope.row.commentStatus==1" type="success">审批通过</el-tag>
                        <el-tag v-if="scope.row.commentStatus==2" type="danger">审批不通过</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="createdTime" label="评论时间"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button
                                type="text"
                                icon="el-icon-edit"
                                @click="handleEdit(scope.row.id, scope.row)"
                                v-if="globalPermission.comment.edit"
                        >审核状态
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                        background
                        layout="total, prev, pager, next"
                        :current-page="query.pageNum"
                        :page-size="query.pageSize"
                        :total="pageTotal"
                        @current-change="handlePageChange"
                ></el-pagination>
            </div>
        </div>

        <!-- 编辑弹出框 -->
        <el-dialog title="审核状态" :visible.sync="editVisible" width="30%">
            <el-form ref="form" :model="form" label-width="70px">
                <el-form-item label="评论内容">
                    <el-input v-model="form.commentDesc"></el-input>
                </el-form-item>
                <el-form-item label="审批状态">
                    <el-radio-group v-model="form.commentStatus">
                        <el-radio :label="0">待审批</el-radio>
                        <el-radio :label="1">通过</el-radio>
                        <el-radio :label="2">不通过</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="审批意见">
                    <el-input v-model="form.approveStr"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="clean">取 消</el-button>
                <el-button type="primary" @click="saveEdit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {getAllComment, saveComment} from '../../api/comment';

    var queryRequest = {url: "/manager/comment/getComments"};
    var updateRequest = {url: "/manager/comment/approvalComments"};
    export default {
        name: 'usertable',
        data() {
            return {
                query: {
                    articleId:'',
                    commentDesc: '',
                    articleTitle: '',
                    pageNum: 1,
                    pageSize: 10
                },
                updateQuery: {
                    approveStr:'',
                    id: '',
                    status: '',
                },
                globalPermission: this.getPermission(),
                tableData: [],
                multipleSelection: [],
                editVisible: false,
                editRolesVisible: false,
                formIdShow: false,
                pageTotal: 0,
                form: {},
                idx: -1,
                id: -1,
            };
        },
        created() {
            queryRequest.data = this.query;
            this.getData();
        },
        methods: {
            // 获取数据
            getData() {
                //获取栏目列表
                getAllComment(queryRequest).then(res => {
                    this.tableData = res.data.list;
                    this.pageTotal = res.data.total;
                });
            },
            clean(){
                this.editVisible=false;
                this.handleSearch();
            },
            getPermission() {
                return JSON.parse(sessionStorage.getItem("permission"));
            },
            // 触发搜索按钮
            handleSearch() {
                this.$set(this.query, 'pageNum', 1);
                this.getData();
            },
            // 编辑操作
            handleEdit(id, row) {
                this.id = id;
                this.form = row;
                this.form.id = this.id;
                this.editVisible = true;
            },
            // 保存编辑
            saveEdit() {
                this.updateQuery.status=this.form.commentStatus;
                this.updateQuery.id = this.id;
                this.updateQuery.approveStr = this.form.approveStr;
                updateRequest.data = this.updateQuery;
                saveComment(updateRequest).then(res => {
                    if (res.code == 0) {
                        this.$message.success(`修改成功`);
                        this.editVisible = false;
                        this.handleSearch();
                    } else {
                        this.$message.error(res.message);
                    }
                });
            },
            // 分页导航
            handlePageChange(val) {
                this.$set(this.query, 'pageNum', val);
                this.getData();
            }
        }
    };
</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 130px;
        display: inline-block;
    }

    .table {
        width: 100%;
        font-size: 14px;
    }

    .red {
        color: #ff0000;
    }

    .mr10 {
        margin-right: 10px;
    }

    .table-td-thumb {
        display: block;
        margin: auto;
        width: 40px;
        height: 40px;
    }

    .show {
        display: block;
    }

    .hide {
        display: none;
    }

    .checkbox-rows {
        width: 130px;
        margin-top: 10px;
    }

    .el-checkbox.is-bordered + .el-checkbox.is-bordered {
        margin-left: 0px;
    }
</style>
