var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "el-breadcrumb",
        { attrs: { "separator-class": "el-icon-arrow-right" } },
        [
          _c("el-breadcrumb-item", [
            _c("span", { staticClass: "compile" }, [_vm._v("赛事编辑")]),
          ]),
          _c("el-breadcrumb-item", [_vm._v("组别详情")]),
          _c("el-breadcrumb-item", [_vm._v("报名表单")]),
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: { "label-width": "140px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "赛事名称：", required: "" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.info.matchName,
                  callback: function ($$v) {
                    _vm.$set(_vm.info, "matchName", $$v)
                  },
                  expression: "info.matchName",
                },
              }),
            ],
            1
          ),
          _vm._l(_vm.sponsor, function (item, index) {
            return _c(
              "div",
              { key: index, staticClass: "aRow" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: `赛事主办方：(${index + 1})`,
                      required: "",
                    },
                  },
                  [
                    _c("el-input", {
                      staticStyle: { width: "300px" },
                      model: {
                        value: item.sponsor,
                        callback: function ($$v) {
                          _vm.$set(item, "sponsor", $$v)
                        },
                        expression: "item.sponsor",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "联系方式：" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "300px" },
                      model: {
                        value: item.phone,
                        callback: function ($$v) {
                          _vm.$set(item, "phone", $$v)
                        },
                        expression: "item.phone",
                      },
                    }),
                  ],
                  1
                ),
                index == 0
                  ? _c("i", {
                      staticClass: "addIcon el-icon-circle-plus-outline",
                      on: { click: _vm.addition },
                    })
                  : _c("i", {
                      staticClass: "addIcon el-icon-remove-outline",
                      on: {
                        click: function ($event) {
                          return _vm.delline(index)
                        },
                      },
                    }),
              ],
              1
            )
          }),
          _c(
            "el-form-item",
            { attrs: { label: "活动类型：", required: "" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "handle-input mr10",
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.info.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.info, "type", $$v)
                    },
                    expression: "info.type",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.type,
                    attrs: { label: item.type, value: item.type },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否为职业赛事：", required: "" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.info.professional,
                    callback: function ($$v) {
                      _vm.$set(_vm.info, "professional", $$v)
                    },
                    expression: "info.professional",
                  },
                },
                [_vm._v("是")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: 0 },
                  model: {
                    value: _vm.info.professional,
                    callback: function ($$v) {
                      _vm.$set(_vm.info, "professional", $$v)
                    },
                    expression: "info.professional",
                  },
                },
                [_vm._v("否")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动级别：" } },
            [
              _c("el-input", {
                attrs: { maxlength: "4" },
                model: {
                  value: _vm.info.level,
                  callback: function ($$v) {
                    _vm.$set(_vm.info, "level", $$v)
                  },
                  expression: "info.level",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "场地类型：" } },
            [
              _c("el-input", {
                attrs: { maxlength: "4" },
                model: {
                  value: _vm.info.siteType,
                  callback: function ($$v) {
                    _vm.$set(_vm.info, "siteType", $$v)
                  },
                  expression: "info.siteType",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "关注基础量：", required: "" } },
            [
              _c("el-input-number", {
                attrs: { min: 0 },
                model: {
                  value: _vm.info.concernCount,
                  callback: function ($$v) {
                    _vm.$set(_vm.info, "concernCount", $$v)
                  },
                  expression: "info.concernCount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "活动地址：",
                required: _vm.info.type == "线下赛",
              },
            },
            [
              _c("SelectAddress", {
                attrs: { addressCode: _vm.addressCode, site: _vm.site },
                on: {
                  "update:addressCode": function ($event) {
                    _vm.addressCode = $event
                  },
                  "update:address-code": function ($event) {
                    _vm.addressCode = $event
                  },
                  "update:site": function ($event) {
                    _vm.site = $event
                  },
                  update: _vm.update,
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "详细地址：",
                required: _vm.info.type == "线下赛",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.info.addressDetail,
                  callback: function ($$v) {
                    _vm.$set(_vm.info, "addressDetail", $$v)
                  },
                  expression: "info.addressDetail",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "报名时间：", required: "" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetimerange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.applyTime,
                  callback: function ($$v) {
                    _vm.applyTime = $$v
                  },
                  expression: "applyTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "比赛时间：", required: "" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetimerange",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.gameTime,
                  callback: function ($$v) {
                    _vm.gameTime = $$v
                  },
                  expression: "gameTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动图片：", required: "" } },
            [
              _c("UploadImg", {
                model: {
                  value: _vm.info.activityPicUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.info, "activityPicUrl", $$v)
                  },
                  expression: "info.activityPicUrl",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "赛事介绍：", required: "" } },
            [
              _c("UploadImg", {
                model: {
                  value: _vm.info.matchIntroduceUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.info, "matchIntroduceUrl", $$v)
                  },
                  expression: "info.matchIntroduceUrl",
                },
              }),
            ],
            1
          ),
          _vm._l(_vm.extraList, function (item, index) {
            return _c(
              "el-form-item",
              { key: index + "1", attrs: { label: item.key + "：" } },
              [
                _c(
                  "div",
                  { staticClass: "elasticity" },
                  [
                    item.type == "text"
                      ? _c("el-input", {
                          attrs: { placeholder: "请输入内容", clearable: "" },
                          model: {
                            value: item.value[0],
                            callback: function ($$v) {
                              _vm.$set(item.value, 0, $$v)
                            },
                            expression: "item.value[0]",
                          },
                        })
                      : _vm._e(),
                    item.type == "textarea"
                      ? _c("el-input", {
                          attrs: {
                            type: "textarea",
                            placeholder: "请输入内容",
                            clearable: "",
                          },
                          model: {
                            value: item.value[0],
                            callback: function ($$v) {
                              _vm.$set(item.value, 0, $$v)
                            },
                            expression: "item.value[0]",
                          },
                        })
                      : _vm._e(),
                    item.type == "uploadImg"
                      ? _c("UploadImg", {
                          model: {
                            value: item.value[0],
                            callback: function ($$v) {
                              _vm.$set(item.value, 0, $$v)
                            },
                            expression: "item.value[0]",
                          },
                        })
                      : _vm._e(),
                    item.type == "select"
                      ? _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: item.value[0],
                              callback: function ($$v) {
                                _vm.$set(item.value, 0, $$v)
                              },
                              expression: "item.value[0]",
                            },
                          },
                          _vm._l(item.item, function (item) {
                            return _c("el-option", {
                              key: item[0],
                              attrs: { label: item[0], value: item[0] },
                            })
                          }),
                          1
                        )
                      : _vm._e(),
                    item.type == "radio"
                      ? _c(
                          "div",
                          _vm._l(item.item, function (items) {
                            return _c(
                              "el-radio",
                              {
                                key: items.value,
                                attrs: { label: items.value },
                                model: {
                                  value: item.value[0],
                                  callback: function ($$v) {
                                    _vm.$set(item.value, 0, $$v)
                                  },
                                  expression: "item.value[0]",
                                },
                              },
                              [_vm._v(_vm._s(items[0]))]
                            )
                          }),
                          1
                        )
                      : _vm._e(),
                    item.type == "checkbox"
                      ? _c(
                          "div",
                          _vm._l(item.item, function (items) {
                            return _c(
                              "el-checkbox",
                              {
                                key: items,
                                model: {
                                  value: items.status,
                                  callback: function ($$v) {
                                    _vm.$set(items, "status", $$v)
                                  },
                                  expression: "items.status",
                                },
                              },
                              [_vm._v(_vm._s(items[0]))]
                            )
                          }),
                          1
                        )
                      : _vm._e(),
                    _c("i", {
                      staticClass: "addIcon el-icon-remove-outline",
                      on: {
                        click: function ($event) {
                          return _vm.dellineAdd(index)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          }),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.back } },
                [_vm._v("放弃编辑")]
              ),
              _c("el-button", { on: { click: _vm.addActivity } }, [
                _vm._v("添加活动字段"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("下一步")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("DialogAdd", {
        attrs: { dialogVisible: _vm.dialogVisible },
        on: {
          "update:dialogVisible": function ($event) {
            _vm.dialogVisible = $event
          },
          "update:dialog-visible": function ($event) {
            _vm.dialogVisible = $event
          },
          extra: _vm.extra,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }