<template>
	<div>
		<el-upload
			ref="uploadPicture"
			class="upload-demo"
			action="https://api.sctynews.cn/manager/file/uploadLoopImg"
			:headers="getToken"
			:data="uploadData"
			:on-success="refreshPic"
			:show-file-list="false"
		>
			<div class="imgBox">
				<img :src="img" alt="" class="imaPreview" v-if="img" />
				<div v-else>
					<i class="el-icon-upload"></i>
					<div>上传图片</div>
				</div>
			</div>
		</el-upload>
		<!-- headers 设置上传的请求头部  -->
		<!-- before-upload 上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传  -->
		<!-- data 上传时附带的额外参数  -->
		<!-- on-success 文件上传成功时的钩子  -->
	</div>
</template>
<script>
export default {
	name: 'index',
	model: {
		//这个事件名可以随意写，它实际上是规定了子组件要更新父组件值需要注册的方法
		event: 'update'
	},

	props: {
		value: {
			type: [String, Array]
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(val) {
				this.img = val;
			}
		}
	},
	data() {
		return {
			getToken: { 'auth-token': localStorage.getItem('token') },
			uploadData: { id: 99 },
			img: ''
		};
	},
	methods: {
		//图片上传成功
		refreshPic(response) {
			if (response) {
				this.img = response.data;
				this.$emit('update', this.img);
			}
		}
	},
	mounted() {}
};
</script>
<style  scoped>
.imaPreview {
	width: 100%;
	height: 100%;
	background-size: 100% 100%;
}
</style>