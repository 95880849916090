<template>
    <div class="parcel">
        <!-- slidesList:{{slidesList}} -->
        <!-- 上传图片组件 -->
        <el-upload
            class="avatar-uploader parentLevel"
            :before-upload="beforeAvatarUpload"
            drag
            :action="actionUrl"
            :headers="getToken"
            :on-success="refreshPic"
            :show-file-list="false"
            multiple
        >
            <i class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <div
            v-for="(item, index) in slidesList"
            :key="index"
            class="parentLevel"
        >
            <el-image
                class="eleImg"
                :src="item"
                :preview-src-list="slidesList"
                @mouseenter="enter(index)"
                @error="errorFun(index)"
            >
            </el-image>
            <div
                @mouseleave="leave(index)"
                class="shade"
                v-if="maskLayer == index"
            >
                <i
                    class="el-icon-upload2 IconStyle"
                    title="置顶"
                    v-if="slidesList.length > 1"
                    @click="stick(index)"
                ></i>
                <i
                    class="el-icon-zoom-in IconStyle"
                    title="预览"
                    @click="preview(index)"
                ></i>
                <i
                    class="el-icon-delete IconStyle"
                    title="删除"
                    @click="deleteIn(index)"
                ></i>
            </div>
        </div>
        <!-- 大图预览 -->
        <el-image-viewer
            v-if="showViewer"
            :on-close="closeViewer"
            :url-list="slidesList"
            :initial-index="imageIndex"
        />
    </div>
</template>
<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
    components: {
        ElImageViewer,
    },
    name: "UploadImg",
    model: {
        //这个事件名可以随意写，它实际上是规定了子组件要更新父组件值需要注册的方法
        event: "update",
    },

    props: {
        value: {
            type: [String, Array],
        },
        max: {
            // type: String,
            default: "1",
        },
        isLimitSize: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                if (val) {
                    //回显数据
                    if (typeof val == "string") {
                        this.slidesList = [val];
                    } else {
                        this.slidesList = val;
                    }
                } else {
                    // 初始化
                    if (this.max == 1) {
                        this.slidesList = "";
                    } else {
                        this.slidesList = [];
                    }
                }
            },
        },
    },
    data() {
        return {
            getToken: {
                Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
            uploadData: { file: 99 },
            img: "",
            actionUrl: process.env.VUE_APP_ApiUrlLog + 'third/files/upload',
            imageIndex: 0,
            showViewer: false,
            maskLayer: null,
            slidesList: [],
        };
    },
    methods: {
        //图片加载失败
        errorFun(index) {
            console.log("图片加载失败");
            console.log(index);
            this.deleteIn(index);
            // this.$message.error("图片加载失败");
        },
        //上传文件之前
        beforeAvatarUpload(e) {
          console.log(e)
            if (this.isLimitSize) {
                const isLtSize = e.size / 1024 / 1024 < 2;
                if (!isLtSize) {
                    this.$message.error("上传图片大小不能超过 2MB!");
                    return false;
                }
            }
           
            if (this.slidesList.length >= this.max) {
                this.$message.error(`最多上传${this.max}张图片`);
                return false;
            }
        },
        /**置顶 */
        stick(index) {
            let item = this.slidesList[index];
            this.slidesList.splice(index, 1);
            this.slidesList.unshift(item);
            if (this.max !== 1) {
                this.$emit("update", this.slidesList);
            }
        },
        /**删除 */
        deleteIn(index) {
            this.slidesList.splice(index, 1);
            if (this.max == 1) {
                this.$emit("update", "");
            } else {
                this.$emit("update", this.slidesList);
            }
        },
        /**预览 */
        preview(index) {
            this.showViewer = true;
            this.imageIndex = index;
        },
        closeViewer() {
            this.showViewer = false;
        },
        /** 移入 */
        enter(index) {
            this.maskLayer = index;
        },
        /** 移出 */
        leave(index) {
            this.maskLayer = null;
        },
        //图片上传成功
        refreshPic(response) {
            if (response) {
                this.img = response.data + '?imageMogr2/format/jpeg';
                if (this.max == 1) {
                    this.$emit("update", this.img);
                    this.slidesList = [];
                    this.$set(this.slidesList, 0, this.img);
                } else {
                    if (this.slidesList.length >= this.max) {
                        this.$message.error(`最多上传${this.max}张图片`);
                        return false;
                    }
                    console.log("图片地址");
                    console.log(this.img);
                    this.slidesList.push(this.img);
                    this.$emit("update", this.slidesList);
                }
            }
        },
    },
    mounted() {},
};
</script>
<style lang="less" scoped>
.parcel {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
/deep/ .el-upload-dragger {
    width: 100px !important;
    height: 100px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
/deep/ .el-upload--text {
    border: 1px solid #fff;
    width: 101px;
    height: 101px;
}
// /deep/ .el-image-viewer__wrapper {
//     z-index: 2002 !important;
// } 
.el-image-viewer__wrapper {
  z-index: 9999 !important; /* 设置一个较高的 z-index 值 */
}
.imaPreview {
    width: 60px !important;
    height: 60px !important;
}
.avatar-uploader {
    display: flex;
    justify-content: center;
    align-items: center;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.avatar {
    width: 178px;
    height: 178px;
    display: block;
}

.ImageList {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    width: 50%;
    height: 150px;
    margin-top: 20px;
}
.eleImg {
    width: 100px;
    height: 100px;
    border-radius: 6px;
    cursor: pointer;
    margin: 0px 10px;
    // z-index: 9999;
}
.shade {
    border-radius: 6px;
    margin: 0px 10px;
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    // padding: 10px;
    cursor: pointer;
}
.parentLevel {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 120px !important;
}
.IconStyle {
    color: #ffffff;
    font-size: 20px;
}
</style>