<script src="../../main.js"></script>
<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-people"></i> 用户管理
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <el-button
                        type="primary"
                        icon="el-icon-lx-roundadd"
                        class="handle-del mr10"
                        @click="addItem"
                        v-if="globalPermission.user.add"
                >新增用户
                </el-button>
                <el-input v-model="query.username" placeholder="用户名" class="handle-input mr10"></el-input>
                <el-input v-model="query.name" placeholder="姓名" class="handle-input mr10"></el-input>
                <el-select v-model="query.status" placeholder="状态" class="handle-input mr10">
                    <el-option
                            v-for="item in statusOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
                <el-button type="primary" icon="el-icon-search"  @click="handleSearch">搜索</el-button>
            </div>
            <el-table
                    :data="tableData"
                    border
                    class="table"
                    ref="multipleTable"
                    header-cell-class-name="table-header"
                    @selection-change="handleSelectionChange"
            >
                <el-table-column prop="username" label="用户名"></el-table-column>
                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column prop="phone" label="手机号"></el-table-column>
                <el-table-column prop="cityName" label="账号权限"></el-table-column>
                <el-table-column label="状态">
                    <template slot-scope="scope">
                        <el-tag
                                :type="scope.row.status===0?'success':(scope.row.status===1?'danger':'')"
                        >{{scope.row.status==0?"启用":"禁用"}}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="updatedTime" label="创建时间"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button
                                type="text"
                                icon="el-icon-edit"
                                @click="handleEdit(scope.row.id, scope.row)"
                                v-if="globalPermission.user.edit"
                        >编辑
                        </el-button>
                        <el-button
                                type="text"
                                icon="el-icon-delete"
                                class="red"
                                @click="handleDelete(scope.row.id, scope.row)"
                                v-if="globalPermission.user.delete"
                        >删除
                        </el-button>
                        <el-button
                                type="text"
                                :icon="scope.row.status===0?'el-icon-warning':(scope.row.status===1?'el-icon-success':'')"
                                :class="scope.row.status===0?'red':''"
                                @click="handleForbid(scope.row.id, scope.row.status)"
                                v-if="globalPermission.user.forbid"
                        >{{scope.row.status==0?"禁用":"启用"}}
                        </el-button>
                        <el-button
                                type="text"
                                icon="el-icon-setting"
                                @click="handleRoles(scope.row.id, scope.row)"
                                v-if="globalPermission.user.role"
                        >赋予角色
                        </el-button>
                        <el-button
                                type="text"
                                icon="el-icon-setting"
                                @click="resetUserPassword(scope.row.id)"
                        >重置密码
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                        background
                        layout="total, prev, pager, next"
                        :current-page="query.pageNum"
                        :page-size="query.pageSize"
                        :total="pageTotal"
                        @current-change="handlePageChange"
                ></el-pagination>
            </div>
        </div>

        <!-- 编辑弹出框 -->
        <el-dialog title="编辑" :visible.sync="editVisible" width="30%">
            <el-form ref="form" :model="form" label-width="70px">
                <el-form-item label="用户姓名">
                  <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="登录账号">
                    <el-input v-model="form.username"></el-input>
                </el-form-item>
                <el-form-item label="手机号码" >
                    <el-input v-model="form.phone"></el-input>
                </el-form-item>
                <el-form-item label="账号权限" >
                  <el-select v-model="form.city" :disabled="editShow" placeholder="是否市州账号" class="handle-input mr10">
                    <el-option
                        v-for="item in colOptions"
                        :key="item.key"
                        :label="item.label"
                        :value="item.key">
                    </el-option>
                  </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveEdit">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 授予角色弹出框 -->
        <el-dialog title="角色管理" :visible.sync="editRolesVisible" width="30%">
            <el-form label-width="70px">
                <template>
                    <el-checkbox-group v-model="checkUserRolesIds">
                        <el-checkbox class="checkbox-rows" v-for="role in allUserRoles" :label="role.id" border  :key="role">
                            {{role.roleName}}
                        </el-checkbox>
                    </el-checkbox-group>
                </template>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editRolesVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveUserRoleList">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {fetchDataGet} from '../../api/user';
    import {fetchDataPost} from '../../api/user';
    import {fetchDataPut} from '../../api/user';
    import {fetchDataDel} from '../../api/user';
    import {fetchDataCustom} from '../../api/user';
    import {getAllTabs} from "../../api/tabs";

    var queryRequest = {url: "/usercenter/user/page-list"};
    var addRequest = {url: "/usercenter/user"};
    var queryTabsRequest = {url: "/manager/column/getCityColumns"};
    var updateRequest = {url: "/usercenter/user"};
    var delRequest = {url: "/usercenter/user/{id}"};
    var forbidRequest = {url: "/usercenter/user/forbid/{id}"};
    var saveUserRolesRequest = {url: "/usercenter/user-role"};
    var resetPasswordRequest = {url: "/usercenter/user/reset-password/{id}"};
    export default {
        name: 'usertable',
        data() {
            return {
                query: {
                    phone: '',
                    name: '',
                    username: '',
                    status: '',
                    pageNum: 1,
                    pageSize: 10
                },
                statusOptions: [{
                    value: '0',
                    label: '启用'
                }, {
                    value: '1',
                    label: '禁用'
                }, {
                    value: '',
                    label: '所有'
                }],
                globalPermission: this.getPermission(),
                colOptions:this.getColOptions(),
                tableData: [],
                allUserRoles: [],
                checkUserRolesIds: [],
                multipleSelection: [],
                delList: [],
                editVisible: false,
                editRolesVisible: false,
                formIdShow: false,
                isCurEdit: false,
                editShow: false,
                pageTotal: 0,
                form: {},
                idx: -1,
                id: -1,
                htmlStr: ""
            };
        },
        created() {
            queryRequest.data = this.query;
            this.getData();
        },
        methods: {
            // 获取数据
            getData() {
                //获取用户列表
                fetchDataPost(queryRequest).then(res => {
                    this.tableData = res.data.list;
                    this.pageTotal = res.data.total || 50;
                });
                //获取所有角色列表
                fetchDataGet({url: "/usercenter/role/all"}).then(res => {
                    this.allUserRoles = res.data;
                });
            },
            getColOptions(){
              const data = [];
              getAllTabs(queryTabsRequest).then(res =>{
                res.data.forEach(function (tabs) {
                  data.push({
                    label: tabs.colName,
                    key: tabs.id
                  });
                });
              });
              return data;
            },
            getPermission() {
                return JSON.parse(sessionStorage.getItem("permission"));
            },
            // 触发搜索按钮
            handleSearch() {
                this.$set(this.query, 'pageIndex', 1);
                this.getData();
            },

            // 删除操作
            handleDelete(id, row) {
                // 二次确认删除
                this.$confirm('确定要删除吗？', '提示', {
                    type: 'warning'
                }).then(() => {
                    delRequest.id = id;
                    fetchDataDel(delRequest).then(res => {
                        if (res.code == 0) {
                            this.$message.success(`删除成功`);
                            this.handleSearch();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                    //this.tableData.splice(index, 1);
                }).catch(() => {
                });
            },
            // 启用禁用
            handleForbid(id, status) {
                // 二次确认启用禁用
                let text = status == 1 ? '启用' : status == 0 ? '禁用' : '未知';
                if (text == '未知') {
                    this.$message.error(`数据错误`);
                    return false;
                }
                this.$confirm('确定要' + text + '吗？', '提示', {
                    type: 'warning'
                }).then(() => {
                    forbidRequest.id = id;
                    forbidRequest.isParamData = true;
                    forbidRequest.data = {status: status ^ 1};
                    fetchDataPut(forbidRequest).then(res => {
                        if (res.code == 0) {
                            this.$message.success(text + '成功');
                            this.handleSearch();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                }).catch(() => {
                });
            },
            // 多选操作
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            // 重置用户密码
            resetUserPassword(id) {
                var text = "重置密码";
                this.$confirm('确定要' + text + '吗？', '提示', {
                    type: 'warning'
                }).then(() => {
                    resetPasswordRequest.id = id;
                    fetchDataPut(resetPasswordRequest).then(res => {
                        if (res.code == 0) {
                            this.$message.success(text + '成功');
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                }).catch(() => {
                });
            },
            // 新增
            addItem() {
                this.form={};
                this.editVisible = true;
                this.editShow = false;
                this.isCurEdit = false;
            },
            // 编辑操作
            handleEdit(id, row) {
                this.id = id;
                this.form = row;
                this.form.id = this.id;
                this.editShow = true;
                this.editVisible = true;
                this.isCurEdit = true;
            },
            // 授权操作
            handleRoles(id, row) {
                //获取用户已有角色列表
                fetchDataGet({url: "/usercenter/user-role/" + id}).then(res => {
                    var checkUserRolesIdsArr = [];
                    res.data.forEach(function (userRole) {
                        checkUserRolesIdsArr.push(userRole.id);
                    });
                    this.checkUserRolesIds = checkUserRolesIdsArr;
                });
                this.id = id;
                this.editRolesVisible = true;
            },
            // 保存编辑
            saveEdit() {
                //this.$set(this.tableData, this.idx, this.form);
                if (this.isCurEdit) {
                    updateRequest.data = this.form;
                    fetchDataPut(updateRequest).then(res => {
                        if (res.code == 0) {
                            this.$message.success(`修改成功`);
                            this.editVisible = false;
                            this.handleSearch();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                } else {
                    addRequest.data = this.form;
                    fetchDataPost(addRequest).then(res => {
                        if (res.code == 0) {
                            this.$message.success(`新增成功`);
                            this.editVisible = false;
                            this.handleSearch();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                }
            },
            saveUserRoleList() {
                saveUserRolesRequest.data = {
                    "roleIdList": this.checkUserRolesIds,
                    "userId": this.id
                };
                fetchDataPost(saveUserRolesRequest).then(res => {
                    if (res.code == 0) {
                        this.$message.success(`角色保存成功`);
                        this.editRolesVisible = false;
                        this.handleSearch();
                    } else {
                        this.$message.error(res.message);
                    }
                });

            },
            // 分页导航
            handlePageChange(val) {
                this.$set(this.query, 'pageNum', val);
                this.getData();
            }
        }
    };
</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 130px;
        display: inline-block;
    }

    .table {
        width: 100%;
        font-size: 14px;
    }

    .red {
        color: #ff0000;
    }

    .mr10 {
        margin-right: 10px;
    }

    .table-td-thumb {
        display: block;
        margin: auto;
        width: 40px;
        height: 40px;
    }

    .show {
        display: block;
    }

    .hide {
        display: none;
    }

    .checkbox-rows {
        width: 130px;
        margin-top: 10px;
    }

    .el-checkbox.is-bordered + .el-checkbox.is-bordered {
        margin-left: 0px;
    }
</style>
