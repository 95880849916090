var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-card",
        { staticClass: "card", attrs: { shadow: "always" } },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { inline: "" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "奖章名称：" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.medalName,
                      callback: function ($$v) {
                        _vm.medalName = $$v
                      },
                      expression: "medalName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "赛事名称：" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.matchName,
                      callback: function ($$v) {
                        _vm.matchName = $$v
                      },
                      expression: "matchName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.deleted,
                        callback: function ($$v) {
                          _vm.deleted = $$v
                        },
                        expression: "deleted",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.search } },
                    [_vm._v("搜索")]
                  ),
                ],
                1
              ),
              _c("el-form-item"),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "card", attrs: { shadow: "always" } },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "btn" },
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.additional } },
                  [_vm._v("新增")]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "location" }, [
              _vm._v("\n          总条数:"),
              _c("span", [_vm._v(_vm._s(_vm.pager.total))]),
            ]),
          ]),
          _c(
            "el-table",
            { attrs: { data: _vm.tableData, border: "" } },
            [
              _c("el-table-column", {
                attrs: { align: "center", label: "序号", width: "60" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.pager.pageIndex - 1) * _vm.pager.pageSize +
                                scope.$index +
                                1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "medalName",
                  label: "奖章名称",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "brandName",
                  label: "奖章/证书",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          staticStyle: { width: "150px", height: "100px" },
                          attrs: {
                            fit: "contain",
                            src: scope.row.icon,
                            "preview-src-list": [scope.row.icon],
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", prop: "number", label: "拥有用户数" },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "gmtCreate",
                  label: "创建时间",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "matchName",
                  label: "赛事名称",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", prop: "deleted", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n               " +
                            _vm._s(_vm.Enum[scope.row.deleted]) +
                            "\n             "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", prop: "categoryId", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.row)
                              },
                            },
                          },
                          [_vm._v("修改")]
                        ),
                        scope.row.deleted == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.soldOut(scope.row.id, 0)
                                  },
                                },
                              },
                              [_vm._v("上架")]
                            )
                          : _c(
                              "el-button",
                              {
                                staticStyle: { color: "#F56C6C" },
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.soldOut(scope.row.id, 1)
                                  },
                                },
                              },
                              [_vm._v("下架")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "paging" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "current-page": _vm.currentPage,
                  "page-sizes": [10, 15, 30, 50, 100],
                  "page-size": 10,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.pager.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("DialogADD", {
        attrs: {
          dialogVisible: _vm.dialogVisible,
          title: _vm.title,
          editData: _vm.editData,
        },
        on: {
          "update:dialogVisible": function ($event) {
            _vm.dialogVisible = $event
          },
          "update:dialog-visible": function ($event) {
            _vm.dialogVisible = $event
          },
          update: _vm.update,
        },
      }),
      _c("AuditDialog", {
        attrs: { dialogVisible: _vm.dialogVisible2 },
        on: {
          "update:dialogVisible": function ($event) {
            _vm.dialogVisible2 = $event
          },
          "update:dialog-visible": function ($event) {
            _vm.dialogVisible2 = $event
          },
        },
      }),
      _c("CompetitionDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible3,
          activityID: _vm.activityID,
        },
        on: {
          "update:dialogVisible": function ($event) {
            _vm.dialogVisible3 = $event
          },
          "update:dialog-visible": function ($event) {
            _vm.dialogVisible3 = $event
          },
          updateActivityID: _vm.updateActivityID,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }