var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "parcel" },
    [
      _c(
        "el-upload",
        {
          staticClass: "avatar-uploader parentLevel",
          attrs: {
            "before-upload": _vm.beforeAvatarUpload,
            drag: "",
            action: "#",
            "http-request": _vm.customUpload,
            "show-file-list": false,
            multiple: "",
          },
        },
        [_c("i", { staticClass: "el-icon-plus avatar-uploader-icon" })]
      ),
      _vm._l(_vm.slidesList, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "parentLevel" },
          [
            _c("el-image", {
              staticClass: "eleImg",
              attrs: { src: item, "preview-src-list": _vm.slidesList },
              on: {
                mouseenter: function ($event) {
                  return _vm.enter(index)
                },
                error: function ($event) {
                  return _vm.errorFun(index)
                },
              },
            }),
            _vm.maskLayer == index
              ? _c(
                  "div",
                  {
                    staticClass: "shade",
                    on: {
                      mouseleave: function ($event) {
                        return _vm.leave(index)
                      },
                    },
                  },
                  [
                    _vm.slidesList.length > 1
                      ? _c("i", {
                          staticClass: "el-icon-upload2 IconStyle",
                          attrs: { title: "置顶" },
                          on: {
                            click: function ($event) {
                              return _vm.stick(index)
                            },
                          },
                        })
                      : _vm._e(),
                    _c("i", {
                      staticClass: "el-icon-zoom-in IconStyle",
                      attrs: { title: "预览" },
                      on: {
                        click: function ($event) {
                          return _vm.preview(index)
                        },
                      },
                    }),
                    _c("i", {
                      staticClass: "el-icon-delete IconStyle",
                      attrs: { title: "删除" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteIn(index)
                        },
                      },
                    }),
                  ]
                )
              : _vm._e(),
          ],
          1
        )
      }),
      _vm.showViewer
        ? _c("el-image-viewer", {
            attrs: {
              "on-close": _vm.closeViewer,
              "url-list": _vm.slidesList,
              "initial-index": _vm.imageIndex,
            },
          })
        : _vm._e(),
      _c("vue-image-cropper-new", {
        ref: "cropperImage",
        attrs: { "dialog-visible": _vm.dialogVisible },
        on: {
          "update:dialogVisible": function ($event) {
            _vm.dialogVisible = $event
          },
          "update:dialog-visible": function ($event) {
            _vm.dialogVisible = $event
          },
          uploadImgSuccess: _vm.clickSubmit,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑图片",
            visible: _vm.picModeDialogVisible,
            "show-close": false,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.picModeDialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
            _vm._v("图片格式：" + _vm._s(_vm.imageType)),
          ]),
          _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
            _vm._v(_vm._s(_vm.oldImageTotalSize) + " "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.image_size > 1000,
                    expression: "image_size > 1000",
                  },
                ],
                staticStyle: { color: "red" },
              },
              [_vm._v("（图片大小已超过1M）")]
            ),
          ]),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "20px" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.picMode,
                    callback: function ($$v) {
                      _vm.picMode = $$v
                    },
                    expression: "picMode",
                  },
                },
                [_vm._v("原图")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "2" },
                  model: {
                    value: _vm.picMode,
                    callback: function ($$v) {
                      _vm.picMode = $$v
                    },
                    expression: "picMode",
                  },
                },
                [_vm._v("图片裁剪")]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.picModeCancel } },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.picModeConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }