import request from '../utils/request';
import http from '../utils/http';


export const getAllNews = query => {
    return request({
        url: query.url,
        method: 'post',
        data: query.data
    });
};

export const delNews = query => {
    query.url ="/manager/article/delArticleById?id="+ query.id;
    return request({
        url: query.url,
        method: 'get',
        params: query.data
    });
};

export const delNewsPic = query => {
    return request({
        url: query.url,
        method: 'get',
        params: query.data
    });
};

export const saveNews = query => {
    return request({
        url: query.url,
        method: 'post',
        data: query.data
    });
};

export const saveStatus = query => {
    return request({
        url: query.url,
        method: 'post',
        data: query.data
    });
};

export const editNews = query => {
    return request({
        url: query.url,
        method: 'post',
        data: query.data
    });
};
// 修改发布时间
export const editNews_changeTime = query => {
    return request({
        url: query.url,
        method: 'post',
        data: query.data
    });
};

export const getDetail = query => {
    query.url ="/manager/article/getArticleDetail?id="+ query.id;
    return request({
        url: query.url,
        method: 'get',
        params: query.data
    });
};

// 查看h5普通二维码
export const getGeneralCode = query => {
    return http.get(`third/imagetool/getTextCodeBase64?text=${query.link}`)
  }
