<template>
  <div>
    <!-- 筛选 -->
    <el-card shadow="always" class="card">
      <el-form ref="form" inline>

        <el-form-item label="奖章名称：">
          <el-input v-model="medalName" clearable></el-input>
        </el-form-item>
        <el-form-item label="赛事名称：">
          <el-input v-model="matchName" clearable></el-input>
        </el-form-item>
          <el-form-item label="状态：">
          <el-select v-model="deleted" placeholder="请选择" clearable>
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <!-- <el-button type="primary">重置</el-button> -->
        </el-form-item>
      </el-form>
    </el-card>
    <!-- 表格 -->
    <el-card shadow="always" class="card" >
      <div>
            <div class="btn">
          <el-button type="primary" @click="additional">新增</el-button>
        </div>
        <div class="location">
          总条数:<span>{{ pager.total }}</span>
        </div>
      </div>
      <el-table :data="tableData" border>
             <el-table-column align="center" label="序号" width="60">
                    <template slot-scope="scope">
                        <span>{{
              (pager.pageIndex - 1) * pager.pageSize + scope.$index + 1
            }}</span>
                      </template>
                  </el-table-column>
        <el-table-column align="center" prop="medalName" label="奖章名称" />
        <el-table-column align="center" prop="brandName" label="奖章/证书" >
               <template slot-scope="scope">
            <el-image
              fit="contain"
              style="width: 150px; height: 100px"
              :src="scope.row.icon"
              :preview-src-list="[scope.row.icon]"
            />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="number" label="拥有用户数" />
        <!-- <el-table-column align="center" prop="categoryName" label="创建人" /> -->
        <el-table-column align="center" prop="gmtCreate" label="创建时间" /> 
        <el-table-column align="center" prop="matchName" label="赛事名称" /> 
        <!-- <el-table-column align="center" prop="createdDate" label="最后修改人" />  -->
        <!-- <el-table-column align="center" prop="createdDate" label="最后修改时间" />  -->
        <el-table-column align="center" prop="deleted" label="状态" >
             <template slot-scope="scope">
               {{Enum[scope.row.deleted]}}
             </template>
        </el-table-column> 
          <el-table-column align="center" prop="categoryId" label="操作">
            <template slot-scope="scope">
              <!-- <el-button type="text" @click="audit">审核</el-button> -->
              <el-button type="text" @click="edit(scope.row)">修改</el-button>
              <el-button type="text" v-if="scope.row.deleted == 1" @click="soldOut(scope.row.id,0)">上架</el-button>
              <el-button type="text" v-else style="color:#F56C6C" @click="soldOut(scope.row.id,1)">下架</el-button>
              <!-- <el-button type="text" @click="relevance(scope.row.matchId)">关联赛事详情</el-button> -->
            </template>
          </el-table-column>
      </el-table>
      <div class="paging">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="currentPage" :page-sizes="[10, 15, 30, 50, 100]" :page-size="10"
          layout="total, sizes, prev, pager, next, jumper" :total="pager.total">
        </el-pagination>
      </div>
    </el-card>
    <DialogADD :dialogVisible.sync="dialogVisible" :title="title" :editData="editData" @update="update" />
    <AuditDialog :dialogVisible.sync="dialogVisible2"  />
    <CompetitionDialog :dialogVisible.sync="dialogVisible3" :activityID="activityID" @updateActivityID="updateActivityID" />
  </div>
</template>
<script>
import * as API from "./api/index";
import DialogADD from "./components/DialogADD"; //新增
import AuditDialog from "./components/AuditDialog"; //审核
import CompetitionDialog from "./components/CompetitionDialog"; //关联赛事
export default {
  name: "topic",
      components: {
    DialogADD,
    AuditDialog,
    CompetitionDialog,
  },
  data() {
    return {
      activityID:"",
      editData:{},
      ids:"",
      grounding:"",
      Enum:{
        0:"上架",
        1:"下架",
      },
      deleted:"",
      matchName:"",
      medalName:"",
      title:'新增',
      dialogVisible:false,
      dialogVisible2:false,
      dialogVisible3:false,
      figure: 1,
      value1: "",
      value2: "",
      value3: "",
      options: [
        {
          value: "0",
          label: "上架",
        },
        {
          value: "1",
          label: "下架",
        },
      ],
      tableData: [],
      currentPage:1 ,
      pager: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },

  methods: {
    //刷新活动id
    updateActivityID(){
        this.activityID =""
    },
    //刷新
    update(){
      this.editData = {}
      this.GetTableData()
    },
    //发布
    publish(){
          this.$message({
          message: '发布成功',
          type: 'success'
        });
    },
    //上下架
    soldOut(id,val){
       this.ids = id
       this.grounding = val
       this.achievementUpdate()
    },
    //审核
    audit(){
this.dialogVisible2 =true
    },
    
    //新增
    additional(){
        this.dialogVisible =true
        this.title = '新增'
    },
    //修改
    edit(val){
      this.editData = val
        this.dialogVisible =true
          this.title = '修改'
    },
           //关联赛事
    relevance(id) {
      this.activityID = id
      this.dialogVisible3 =true
    },
   search() {
         this.pager.pageIndex = 1;
      this.GetTableData();
    },
    //运动奖牌列表
    async GetTableData() {
      let parameter = {
        matchName: this.matchName,
        medalName: this.medalName,
        deleted: this.deleted,
        pageNum: this.pager.pageIndex,
        pageSize: this.pager.pageSize,
      };
      let res = await API.achievementList(parameter);
      this.tableData = res.data.list;
      this.pager.total = res.data.total;
    },
    //上下架
    async achievementUpdate() {
      let parameter = {
      deleted:this.grounding,
      id:this.ids,
      };
      let res = await API.achievementUpdate(parameter);
      this.$message({
          message: '操作成功',
          type: 'success'
        });
      this.GetTableData()
    },
    handleSizeChange(val) {
      this.pager.pageSize = val;
      this.GetTableData();
    },
    handleCurrentChange(val) {
         this.pager.pageIndex = val;
      this.GetTableData();
    },
  },
  mounted() {
    this.GetTableData();
  },
};
</script>
<style style scoped lang="less">
.card {
  width: 98%;
  margin: 10px auto;
  font-size: 16px;
}
.location {
  float: right;
  margin-bottom: 6px;
  span {
    color: #409eff;
  }
}
.btn {
  float: left;
  margin-bottom: 6px;
}
.paging {
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>