var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          ref: "uploadPicture",
          staticClass: "upload-demo",
          attrs: {
            action: "https://api.sctynews.cn/manager/file/uploadLoopImg",
            headers: _vm.getToken,
            data: _vm.uploadData,
            "on-success": _vm.refreshPic,
            "show-file-list": false,
          },
        },
        [
          _c("div", { staticClass: "imgBox" }, [
            _vm.img
              ? _c("img", {
                  staticClass: "imaPreview",
                  attrs: { src: _vm.img, alt: "" },
                })
              : _c("div", [
                  _c("i", { staticClass: "el-icon-upload" }),
                  _c("div", [_vm._v("上传图片")]),
                ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }