<template>
  <div class="container">
    <!-- 1.赛事编辑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item><span class="compile">赛事编辑</span> </el-breadcrumb-item>
      <el-breadcrumb-item>组别详情</el-breadcrumb-item>
      <el-breadcrumb-item>报名表单</el-breadcrumb-item>
    </el-breadcrumb>

    <el-form ref="ruleForm" label-width="140px" class="demo-ruleForm">
      <el-form-item label="赛事名称：" required>
        <el-input v-model="info.matchName"></el-input>
      </el-form-item>
      <div class="aRow" v-for="(item, index) in sponsor" :key="index">
        <el-form-item :label="`赛事主办方：(${index + 1})`" required>
          <el-input v-model="item.sponsor" style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="联系方式：">
          <el-input v-model="item.phone" style="width: 300px"></el-input>
        </el-form-item>
        <!-- 添加 -->
        <i class="addIcon el-icon-circle-plus-outline" v-if="index == 0" @click="addition"></i>
        <!-- 删除 -->
        <i class="addIcon el-icon-remove-outline" v-else @click="delline(index)"></i>
      </div>

      <el-form-item label="活动类型：" required>
        <el-select v-model="info.type" placeholder="请选择" class="handle-input mr10">
          <el-option v-for="item in options" :key="item.type" :label="item.type" :value="item.type"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否为职业赛事：" required>
        <el-radio v-model="info.professional" :label='1'>是</el-radio>
        <el-radio v-model="info.professional" :label='0'>否</el-radio>
      </el-form-item>
      <el-form-item label="活动级别：">
        <el-input v-model="info.level" maxlength="4"></el-input>
      </el-form-item>
      <el-form-item label="场地类型：">
        <el-input v-model="info.siteType" maxlength="4"></el-input>
      </el-form-item>
      <el-form-item label="关注基础量：" required>
        <!-- <el-input v-model="info.concernCount"></el-input> -->
        <el-input-number v-model="info.concernCount" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item label="活动地址：" :required="info.type == '线下赛'">
        <SelectAddress :addressCode.sync="addressCode" :site.sync="site" @update="update" />
      </el-form-item>
      <el-form-item label="详细地址：" :required="info.type == '线下赛'">
        <el-input v-model="info.addressDetail"></el-input>
      </el-form-item>
      <el-form-item label="报名时间：" required>
        <el-date-picker v-model="applyTime" type="datetimerange" range-separator="至" start-placeholder="开始日期"
          value-format="yyyy-MM-dd HH:mm:ss" end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="比赛时间：" required>
        <el-date-picker v-model="gameTime" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="活动图片：" required>
        <UploadImg v-model="info.activityPicUrl" />
      </el-form-item>
      <el-form-item label="赛事介绍：" required>
        <UploadImg v-model="info.matchIntroduceUrl" />
      </el-form-item>

      <!-- 添加的活动字段 -->
      <el-form-item v-for="(item, index) in extraList" :key="index + '1'" :label="item.key + '：'">
        <div class="elasticity">
          <!-- 单行输入框 -->
          <el-input v-if="item.type == 'text'" placeholder="请输入内容" v-model="item.value[0]" clearable> </el-input>
          <!-- 多行输入框 -->
          <el-input v-if="item.type == 'textarea'" type="textarea" placeholder="请输入内容" v-model="item.value[0]"
            clearable>
          </el-input>
          <!-- 上传图片 -->
          <UploadImg v-if="item.type == 'uploadImg'" v-model="item.value[0]" />
          <!-- 下拉选择框 -->
          <el-select v-if="item.type == 'select'" v-model="item.value[0]" placeholder="请选择">
            <el-option v-for="item in item.item" :key="item[0]" :label="item[0]" :value="item[0]"> </el-option>
          </el-select>
          <!-- 单选框 -->
          <div v-if="item.type == 'radio'">
            <el-radio v-model="item.value[0]" v-for="items in item.item" :key="items.value" :label="items.value">{{
						items[0]
					}}</el-radio>
          </div>
          <!-- 多选框-->
          <div v-if="item.type == 'checkbox'">
            <el-checkbox v-for="items in item.item" :key="items" v-model="items.status">{{ items[0] }}</el-checkbox>
          </div>
          <i class="addIcon el-icon-remove-outline" @click="dellineAdd(index)"></i>
        </div>
      </el-form-item>
      <!-- 按钮 -->
      <el-form-item label="">
        <el-button type="danger" @click="back">放弃编辑</el-button>
        <el-button @click="addActivity">添加活动字段</el-button>
        <el-button type="primary" @click="submit">下一步</el-button>
      </el-form-item>
    </el-form>
    <DialogAdd :dialogVisible.sync="dialogVisible" @extra="extra" />
  </div>
</template>

<script>
let id = 0;
import UploadImg from './components/UploadImg'; //上传图片
import DialogAdd from './components/DialogAdd'; //添加活动字段
import SelectAddress from './components/SelectAddress'; //选择地址
import * as API from '@/api/activity';
import bus from '@/components/common/bus'; //bus全局通讯
export default {
    components: {
        UploadImg,
        DialogAdd,
        SelectAddress
    },
    name: 'activityEdit',
    data() {
        return {
            site: ['四川', '', ''],
            info: {
                professional: '', //是否为职业赛事
                matchName: '', //赛事名称
                activityPicUrl: '', //活动图片地址
                matchIntroduceUrl: '', //赛事介绍图片地址
                type: '', //活动类型
                concernCount: '', //关注基础量
                address: '', //三级联动地址 通过-拼接(省-市-区)
                addressDetail: '', //详细地址
                applyStartTime: '', //报名开始时间
                applyEndTime: '', //报名结束时间
                matchStartTime: '', //比赛开始时间
                matchEndTime: '', //比赛结束时间
                level: '', //活动级别
                siteType: '', //场地类型
                areaId: '', //区编号-ID
                cityId: '', //市编号-ID
                provinceId: '' //省编号-ID
            }, //赛事详情
            siteData: { site: [] },
            extraList: [],
            applyTime: [],
            gameTime: [],
            dialogVisible: false,
            sponsor: [{ sponsor: '', phone: '' }], //主办方
            update2: '',
            img: '',
            uploadData: { id: 99 },
            getToken: { 'auth-token': localStorage.getItem('token') },
            value1: '',
            value: '',
            options: [],
            ids: '',
            addressCode: [26, '', ''],
            groupInfo: []
        };
    },

    methods: {
        //删除标签页
        dellineBus() {
            bus.$emit('deleteTag', ['activityEdit', 'groupDetail', 'registrationForm']);
        },
        //删除自定义活动字段
        dellineAdd(index) {
            this.extraList.splice(index, 1);
        },
        //上一步
        back() {
            this.dellineBus();
            this.$router.push({
                path: 'activity'
            });
        },
        /** 获取活动类型列表 */
        async getTypeList() {
            let parameter = {};
            let res = await API.getTypeList(parameter);
            this.options = res.data;
        },
        //地址数据
        update(val) {
            this.siteData = val;
        },
        //拿到添加的额外参数
        extra(val) {
            this.extraList.push(val);
        },
        //添加活动字段
        addActivity() {
            this.dialogVisible = true;
        },
        //添加
        addition() {
            if (this.sponsor.length < 4) {
                this.sponsor.push({ sponsor: '', phone: '' });
            } else {
                this.$message.error('最多只能创建4个赛事主办方');
            }
        },
        //删除
        delline(index) {
            this.sponsor.splice(index, 1);
        },
        //下一步
        submit() {
            // return
            //报错处理
            if (this.applyTime == null) this.applyTime = [];
            if (this.gameTime == null) this.gameTime = [];
            //赛事名称
            if (this.info.matchName.length > 20 || this.info.matchName.length == 0) {
                return this.$message.error('赛事名称不能为空，最多20个字符');
            }
            //主办方
            for (let i = 0; i < this.sponsor.length; i++) {
                if (this.sponsor[i].phone == '' || this.sponsor[i].sponsor == '') {
                    return this.$message.error('赛事主办方不能为空');
                }
            }
            //活动类型
            if (!this.info.type) {
                return this.$message.error('活动类型不能为空');
            }
            //职业赛事
            if (!this.info.professional) {
                if (!(this.info.professional === 0)) {
                    return this.$message.error('是否为职业赛事不能为空');
                }
            }
            // 关注基础量
            if (!this.info.concernCount) {
                return this.$message.error('关注基础量不能为空');
            }
            //	 报名时间
            if (this.applyTime.length == 0) {
                return this.$message.error('报名时间不能为空');
            }
            //  比赛时间
            if (this.gameTime.length == 0) {
                return this.$message.error('比赛时间不能为空');
            }
            //  活动图片
            if (!this.info.activityPicUrl) {
                return this.$message.error('活动图片不能为空');
            }
            //  赛事介绍
            if (!this.info.matchIntroduceUrl) {
                return this.$message.error('赛事介绍不能为空');
            }
            //比赛时间要大于报名时间
            var date1 = new Date(this.applyTime[0]);
            var date2 = new Date(this.gameTime[0]);
            if (date2.getTime() - date1.getTime() < 0) {
                return this.$message.error('比赛时间要大于报名时间');
            }
            //判断三级地址地址
            if (this.info.type == '线下赛' && !this.siteData.addressCode[2]) {
                return this.$message.error('活动地址不能为空');
            }
            //判断三级地址地址
            if (this.info.type == '线下赛' && !this.info.addressDetail) {
                return this.$message.error('详细地址不能为空');
            }

            this.info.applyStartTime = this.applyTime[0]; //报名开始时间
            this.info.applyEndTime = this.applyTime[1]; //报名结束时间
            this.info.matchStartTime = this.gameTime[0]; //比赛开始时间
            this.info.matchEndTime = this.gameTime[1]; //比赛结束时间
            //三级都选上时，才赋值
            if (this.siteData.site[2]) {
                this.info.provinceId = this.siteData.addressCode[0]; //省
                this.info.cityId = this.siteData.addressCode[1]; //市
                this.info.areaId = this.siteData.addressCode[2]; //区
                this.info.address = this.siteData.site.join('-'); //区
            }
            //判断添加活动数据的必填项
            // for (let i = 0; i < this.extraList.length; i++) {
            // 	if (this.extraList[i].required && !this.extraList[i].value) {
            // 		return this.$message.error(`添加活动字段中“${this.extraList[i].key}”不能为空`);
            // 	}
            // }
            localStorage.setItem('editData', JSON.stringify({ info: this.info, extraList: this.extraList, sponsor: this.sponsor }));
            //是编辑才带参数
            if (this.$route.params.id) {
                this.$router.push({
                    name: 'groupDetail',
                    params: { groupInfo: this.groupInfo, info: this.info }
                });
            } else {
                //新增不要
                this.$router.push({
                    name: 'groupDetail'
                });
            }
        },
        /** 获取活动详情 */
        async getActivityInfo() {
            let parameter = {
                id: this.ids
            };
            let res = await API.getActivityInfo(parameter);
            this.groupInfo = res.data.groupInfo;
            this.info = res.data.info;
            this.sponsor = res.data.sponsor;
            this.$set(this.applyTime, 0, this.info.applyStartTime); //报名开始时间
            this.$set(this.applyTime, 1, this.info.applyEndTime); //报名结束时间
            this.$set(this.gameTime, 0, this.info.matchStartTime); //比赛开始时间
            this.$set(this.gameTime, 1, this.info.matchEndTime); //比赛结束时间
            this.$set(this.addressCode, 0, this.info.provinceId); //省
            this.$set(this.addressCode, 1, this.info.cityId); //市
            this.$set(this.addressCode, 2, this.info.areaId); //区
            this.extraList = JSON.parse(this.info.customTag);
            this.site = this.info.address.split('-');
        }
    },
    // mounted() {
    // 	// let res = JSON.parse(localStorage.getItem('editData'));
    // 	// this.info = res.info;
    // 	// this.extraList = res.extraList;
    // 	this.getTypeList();
    // },
    activated() {
        this.getTypeList();
        //编辑
        if (this.$route.params.id) {
            this.ids = this.$route.params.id;
            this.getActivityInfo();
        }
        //新增
        if (this.$route.params.name == 'add') {
            this.info = {
                matchName: '', //赛事名称
                activityPicUrl: '', //活动图片地址
                matchIntroduceUrl: '', //赛事介绍图片地址
                type: '', //活动类型
                concernCount: '', //关注基础量
                address: '', //三级联动地址 通过-拼接(省-市-区)
                addressDetail: '', //详细地址
                applyStartTime: '', //报名开始时间
                applyEndTime: '', //报名结束时间
                matchStartTime: '', //比赛开始时间
                matchEndTime: '', //比赛结束时间
                level: '', //活动级别
                siteType: '', //场地类型
                areaId: '', //区编号-ID
                cityId: '', //市编号-ID
                provinceId: '' //省编号-ID
            }; //赛事详情
            this.applyTime = [];
            this.gameTime = [];
            this.addressCode = [26, '', ''];
            this.sponsor = [{ sponsor: '', phone: '' }];
        }
    }
};
</script>

<style  scoped lang="less">
.elasticity {
    display: flex;
    align-items: center;
}
.addIcon {
    margin-left: 20px;
    font-size: 22px;
    cursor: pointer;
}
.aRow {
    display: flex;
    align-items: center;
}
.el-icon-upload {
    font-size: 100px;
    color: #dcdfe6;
}
.imgBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: 100%;
    height: 100%;
}
.demo-ruleForm {
    margin-top: 30px;
}
.compile {
    color: #409eff;
    font-weight: bold;
}
.el-input,
.el-textarea {
    width: 50%;
}
</style>
