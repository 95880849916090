var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加字段",
            visible: _vm._dialogVisible,
            "show-close": false,
            "close-on-click-modal": false,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm._dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                { attrs: { "label-width": "130px" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "类别：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "300px" },
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.value1,
                            callback: function ($$v) {
                              _vm.value1 = $$v
                            },
                            expression: "value1",
                          },
                        },
                        _vm._l(_vm.options, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        attrs: { clearable: "", placeholder: "请输入标题" },
                        model: {
                          value: _vm.value3,
                          callback: function ($$v) {
                            _vm.value3 = $$v
                          },
                          expression: "value3",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.value1 == "select" ||
                  _vm.value1 == "radio" ||
                  _vm.value1 == "checkbox"
                    ? _vm._l(_vm.list, function (item, index) {
                        return _c(
                          "el-form-item",
                          { key: index, attrs: { label: "" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "300px" },
                              attrs: {
                                clearable: "",
                                placeholder: `选项${index + 1}`,
                              },
                              model: {
                                value: item[0],
                                callback: function ($$v) {
                                  _vm.$set(item, 0, $$v)
                                },
                                expression: "item[0]",
                              },
                            }),
                            index < 2
                              ? _c("i", {
                                  staticClass:
                                    "addIcon el-icon-circle-plus-outline",
                                  on: { click: _vm.addition },
                                })
                              : _c("i", {
                                  staticClass: "addIcon el-icon-remove-outline",
                                  on: {
                                    click: function ($event) {
                                      return _vm.delline(index)
                                    },
                                  },
                                }),
                          ],
                          1
                        )
                      })
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm._dialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }