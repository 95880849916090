var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { "separator-class": "el-icon-arrow-right" } },
            [
              _c("el-breadcrumb-item", [_vm._v("赛事编辑 ")]),
              _c("el-breadcrumb-item", [_vm._v("组别详情")]),
              _c("el-breadcrumb-item", [
                _c("span", { staticClass: "compile" }, [_vm._v("报名表单")]),
              ]),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: { "label-width": "130px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名：", required: "" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "", placeholder: "请输入" },
                    model: {
                      value: _vm.value1,
                      callback: function ($$v) {
                        _vm.value1 = $$v
                      },
                      expression: "value1",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "性别：", required: "" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { disabled: "", placeholder: "请选择" },
                      model: {
                        value: _vm.value13,
                        callback: function ($$v) {
                          _vm.value13 = $$v
                        },
                        expression: "value13",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "出生日期：", required: "" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      disabled: "",
                      type: "date",
                      placeholder: "选择日期",
                    },
                    model: {
                      value: _vm.value2,
                      callback: function ($$v) {
                        _vm.value2 = $$v
                      },
                      expression: "value2",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号：", required: "" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "", placeholder: "请输入" },
                    model: {
                      value: _vm.value3,
                      callback: function ($$v) {
                        _vm.value3 = $$v
                      },
                      expression: "value3",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "证件号：", required: "" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "", placeholder: "请输入身份证号" },
                    model: {
                      value: _vm.value4,
                      callback: function ($$v) {
                        _vm.value4 = $$v
                      },
                      expression: "value4",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "政治面貌：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { disabled: "", placeholder: "请选择" },
                      model: {
                        value: _vm.value5,
                        callback: function ($$v) {
                          _vm.value5 = $$v
                        },
                        expression: "value5",
                      },
                    },
                    _vm._l(_vm.options2, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "民族：", required: "" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { disabled: "", placeholder: "请选择" },
                      model: {
                        value: _vm.value6,
                        callback: function ($$v) {
                          _vm.value6 = $$v
                        },
                        expression: "value6",
                      },
                    },
                    _vm._l(_vm.nationList, function (item) {
                      return _c("el-option", {
                        key: item.name,
                        attrs: { label: item.name, value: item.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否体育从事者：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { disabled: "", placeholder: "请选择" },
                      model: {
                        value: _vm.value7,
                        callback: function ($$v) {
                          _vm.value7 = $$v
                        },
                        expression: "value7",
                      },
                    },
                    _vm._l(_vm.options3, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "从事职业：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { disabled: "", placeholder: "请选择" },
                      model: {
                        value: _vm.value8,
                        callback: function ($$v) {
                          _vm.value8 = $$v
                        },
                        expression: "value8",
                      },
                    },
                    _vm._l(_vm.options4, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所在地址：", required: "" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "", placeholder: "请输入" },
                    model: {
                      value: _vm.value1,
                      callback: function ($$v) {
                        _vm.value1 = $$v
                      },
                      expression: "value1",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "详细地址：", required: "" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "", placeholder: "请输入" },
                    model: {
                      value: _vm.value9,
                      callback: function ($$v) {
                        _vm.value9 = $$v
                      },
                      expression: "value9",
                    },
                  }),
                ],
                1
              ),
              _vm._l(_vm.extraList, function (item, index) {
                return _c(
                  "el-form-item",
                  {
                    key: index + "1",
                    attrs: { label: item.key + "：", required: item.required },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "elasticity" },
                      [
                        item.type == "text"
                          ? _c("el-input", {
                              attrs: {
                                disabled: "",
                                placeholder: "请输入内容",
                                clearable: "",
                              },
                              model: {
                                value: item.value[0],
                                callback: function ($$v) {
                                  _vm.$set(item.value, 0, $$v)
                                },
                                expression: "item.value[0]",
                              },
                            })
                          : _vm._e(),
                        item.type == "textarea"
                          ? _c("el-input", {
                              attrs: {
                                disabled: "",
                                type: "textarea",
                                placeholder: "请输入内容",
                                clearable: "",
                              },
                              model: {
                                value: item.value[0],
                                callback: function ($$v) {
                                  _vm.$set(item.value, 0, $$v)
                                },
                                expression: "item.value[0]",
                              },
                            })
                          : _vm._e(),
                        item.type == "uploadImg"
                          ? _c("el-image", [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "error" },
                                  slot: "error",
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-picture-outline",
                                  }),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        item.type == "select"
                          ? _c(
                              "el-select",
                              {
                                attrs: { disabled: "", placeholder: "请选择" },
                                model: {
                                  value: item.value[0],
                                  callback: function ($$v) {
                                    _vm.$set(item.value, 0, $$v)
                                  },
                                  expression: "item.value[0]",
                                },
                              },
                              _vm._l(item.item, function (item) {
                                return _c("el-option", {
                                  key: item[0],
                                  attrs: { label: item[0], value: item[0] },
                                })
                              }),
                              1
                            )
                          : _vm._e(),
                        item.type == "radio"
                          ? _c(
                              "div",
                              _vm._l(item.item, function (items) {
                                return _c(
                                  "el-radio",
                                  {
                                    key: items.value,
                                    attrs: { disabled: "", label: items.value },
                                    model: {
                                      value: item.value[0],
                                      callback: function ($$v) {
                                        _vm.$set(item.value, 0, $$v)
                                      },
                                      expression: "item.value[0]",
                                    },
                                  },
                                  [_vm._v(_vm._s(items[0]))]
                                )
                              }),
                              1
                            )
                          : _vm._e(),
                        item.type == "checkbox"
                          ? _c(
                              "div",
                              _vm._l(item.item, function (items) {
                                return _c(
                                  "el-checkbox",
                                  {
                                    key: items,
                                    attrs: { disabled: "" },
                                    model: {
                                      value: items.status,
                                      callback: function ($$v) {
                                        _vm.$set(items, "status", $$v)
                                      },
                                      expression: "items.status",
                                    },
                                  },
                                  [_vm._v(_vm._s(items[0]))]
                                )
                              }),
                              1
                            )
                          : _vm._e(),
                        _c("i", {
                          staticClass: "addIcon el-icon-remove-outline",
                          on: {
                            click: function ($event) {
                              return _vm.dellineAdd(index)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _vm.stepState
                    ? _c(
                        "el-button",
                        { attrs: { type: "primary" }, on: { click: _vm.back } },
                        [_vm._v("上一步")]
                      )
                    : _vm._e(),
                  _c("el-button", { on: { click: _vm.submit } }, [
                    _vm._v("添加报名字段"),
                  ]),
                  _vm.detailState
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.establish },
                        },
                        [_vm._v("创建报名表")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("DialogAdd", {
        attrs: { dialogVisible: _vm.dialogVisible },
        on: {
          "update:dialogVisible": function ($event) {
            _vm.dialogVisible = $event
          },
          "update:dialog-visible": function ($event) {
            _vm.dialogVisible = $event
          },
          extra: _vm.extra,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }