<template>
	<div>
		<!-- 添加活动字段 -->
		<el-dialog title="添加字段" :visible.sync="_dialogVisible" :show-close="false" :close-on-click-modal="false" width="600px">
			<div>
				<el-form label-width="130px">
					<el-form-item label="类别：">
						<el-select v-model="value1" clearable placeholder="请选择" style="width: 300px">
							<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
						</el-select>
					</el-form-item>
					<!-- <el-form-item label="必填：">
						<el-select v-model="value2" clearable placeholder="请选择" style="width: 300px">
							<el-option v-for="item in must" :key="item.value" :label="item.label" :value="item.value"> </el-option>
						</el-select>
					</el-form-item> -->
					<el-form-item label="标题：">
						<el-input v-model="value3" clearable placeholder="请输入标题" style="width: 300px"></el-input>
					</el-form-item>
					<template v-if="value1 == 'select' || value1 == 'radio' || value1 == 'checkbox'">
						<el-form-item label="" v-for="(item, index) in list" :key="index">
							<el-input v-model="item[0]" style="width: 300px" clearable :placeholder="`选项${index + 1}`"></el-input>
							<!-- 添加 -->
							<i class="addIcon el-icon-circle-plus-outline" v-if="index < 2" @click="addition"></i>
							<!-- 删除 -->
							<i class="addIcon el-icon-remove-outline" v-else @click="delline(index)"></i>
						</el-form-item>
					</template>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="confirm" type="primary">确定</el-button>
				<el-button @click="_dialogVisible = false">取消</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import EnrollDetailVue from '../../Enroll/EnrollDetail.vue';
export default {
	name: 'index',
	data() {
		return {
			list: [[], []],
			options: [
				{
					value: 'text',
					label: '单行文本框'
				},
				{
					value: 'uploadImg',
					label: '上传图片'
				},
				// 	{
				// 	value: 'select',
				// 	label: '下拉选择框'
				// },
				
				// {
				// 	value: 'textarea',
				// 	label: '多行文本框'
				// },
			
				// {
				// 	value: 'radio',
				// 	label: '单选框'
				// },
				// {
				// 	value: 'checkbox',
				// 	label: '多选框'
				// }
			],
			must: [
				{
					value: true,
					label: '是'
				},
				{
					value: false,
					label: '否'
				}
			],
			value1: '',
			value2: false,
			value3: ''
		};
	},
	props: {
		dialogVisible: {
			type: Boolean,
			required: true,
			default: false
		}
	},
	computed: {
		_dialogVisible: {
			get() {
				return this.dialogVisible;
			},
			set(val) {
				this.$emit('update:dialogVisible', val);
			}
		}
	},
	methods: {
		//添加
		addition() {
			this.list.push([]);
		},
		//删除
		delline(index) {
			this.list.splice(index, 1);
		},
		//初始化变量
		initialize() {
			this.value1 = '';
			this.value2 = false;
			this.value3 = '';
			this.list = [[], []];
		},
		//确定添加
		confirm() {
			if (!this.value1) return this.$message.error('请选择类别');
			if (this.value2 === '') return this.$message.error('请选择是否必填');
			if (!this.value3) return this.$message.error('请输入标题');
			if (this.value1 == 'select' || this.value1 == 'radio' || this.value1 == 'checkbox') {
				this.list.map((element) => {
					if (!element) {
						this.$message.error('请填完所有选项');
						throw new Error();
					}
				});
			}
			let valueiTems = [];
			if (this.list.length > 0) {
				for (let i = 0; i < this.list.length; i++) {
					valueiTems[i] = this.list[i][0];
				}
			}
			if(!valueiTems[0]&&!valueiTems[1]){
				valueiTems = []
			}

			let data = {
				type: this.value1,
				required: this.value2,
				key: this.value3,
				item: valueiTems,
				value: [] //其他的值
				// status:false,//多选框的值
			};
			this.$emit('extra', data);
			this.initialize();
			this._dialogVisible = false;
		}
	},
	mounted() {}
};
</script>
<style  scoped lang="less">
.addIcon {
	margin-bottom: 18px;
	margin-left: 20px;
	font-size: 22px;
	cursor: pointer;
}
</style>