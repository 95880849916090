<script src="../../main.js"></script>
<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-people"></i> 新闻管理
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <el-button
                        type="primary"
                        icon="el-icon-lx-roundadd"
                        class="handle-del mr10"
                        @click="addItem"
                        v-if="globalPermission.news.add"
                >新增
                </el-button>
                <el-input v-model="query.titleDesc" placeholder="新闻名" class="handle-input mr10"></el-input>
                <el-select v-model="query.artStatus" placeholder="状态" class="handle-input mr10">
                    <el-option
                            v-for="item in statusOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
<!--                <el-select v-model="query.pcfirst" placeholder="位置" class="handle-input mr10">
                    <el-option
                            v-for="item in pcFirstOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>-->
                <el-select v-model="query.colId" placeholder="栏目" class="handle-input mr10">
                    <el-option
                            v-for="item in colOptions"
                            :key="item.key"
                            :label="item.label"
                            :value="item.key">
                    </el-option>
                </el-select>
                
                <template>
                    <el-date-picker
                            v-model="timeValue"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :picker-options="pickerOptions"
                            class="mr10">
                    </el-date-picker>
                </template>
                <el-button type="primary" icon="el-icon-search"  @click="handleSearch">搜索</el-button>
            </div>
            <el-table
                    :data="tableData"
                    border
                    class="table"
                    ref="multipleTable"
                    header-cell-class-name="table-header"
                    @selection-change="handleSelectionChange"
            >
            <el-table-column prop="id" label="id"></el-table-column>

                <el-table-column prop="title" label="新闻名"></el-table-column>
                <el-table-column prop="artStatus" label="状态">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.artStatus==0" type="warning">初稿</el-tag>
                        <el-tag v-if="scope.row.artStatus==1" type="info">初审通过</el-tag>
                        <el-tag v-if="scope.row.artStatus==2" type="info">复审通过</el-tag>
                        <el-tag v-if="scope.row.artStatus==3" type="success">发布</el-tag>
                        <el-tag v-if="scope.row.artStatus==4" type="danger">审核不通过</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="artType" label="类型">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.artType==1" >视频</el-tag>
                        <el-tag v-if="scope.row.artType==2" >文本</el-tag>
                        <el-tag v-if="scope.row.artType==3" >图文</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="topping" label="热门">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.topping==0" >正常</el-tag>
                        <el-tag v-if="scope.row.topping==1" >热门</el-tag>
                        <el-tag v-if="scope.row.topping==2" >置顶</el-tag>
                        <el-tag v-if="scope.row.topping==3" >栏目封面</el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="coverUrl" label="封面图">
                    <template slot-scope="scope">
                        <el-image
                            v-if="scope.row.coverUrl"
                            :src="getImg(scope.row.coverUrl)"
                            :preview-src-list="[getImg(scope.row.coverUrl)]"
                        />
                        <div v-else>暂无图片</div>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="图片" align="center">
                    <template slot-scope="scope">
                        <el-button
                                type="text"
                                icon="el-icon-lx-attention"
                                @click="handleImage(scope.row.id,scope.row.coverUrl)"
                        >查看/上传</el-button>
                    </template>
                </el-table-column> -->
<!--                <el-table-column prop="baseCollectionCount" label="收藏基数"></el-table-column>-->
<!--                <el-table-column prop="basePariseCount" label="点赞基数"></el-table-column>-->
<!--                <el-table-column prop="baseViewCount" label="浏览基数"></el-table-column>-->
                <el-table-column prop="colName" label="栏目"></el-table-column>
                <el-table-column prop="collectionCount" label="收藏数"></el-table-column>
<!--                <el-table-column prop="pariseCount" label="点赞数"></el-table-column>-->
                <el-table-column prop="addTime" label="创建时间"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button
                                type="text"
                                icon="el-icon-edit"
                                @click="handleEdit(scope.row.id)"
                                v-if="globalPermission.news.edit"
                        >查看编辑
                        </el-button>
                        <el-button
                                type="text"
                                icon="el-icon-edit"
                                @click="handleStatus(scope.row.id,scope.row.artStatus)"
                                v-if="globalPermission.news.one && scope.row.artStatus==0"
                        >初审
                        </el-button>
                        <el-button
                                type="text"
                                icon="el-icon-edit"
                                @click="handleStatus(scope.row.id,scope.row.artStatus)"
                                v-if="globalPermission.news.two && scope.row.artStatus==1"
                        >复审
                        </el-button>
                        <el-button
                                type="text"
                                icon="el-icon-edit"
                                @click="handleStatus(scope.row.id,scope.row.artStatus)"
                                v-if="globalPermission.news.three && scope.row.artStatus==2"
                        >发布
                        </el-button>
                        <el-button
                                type="text"
                                icon="el-icon-delete"
                                class="red"
                                @click="handleDelete(scope.row.id, scope.row)"
                                v-if="globalPermission.news.delete"
                        >删除
                        </el-button>
                        <el-button type="text" @click="checkAppCode(scope.row)"
                            >H5预览</el-button
                        >
                        <el-button type="text" @click="changeTime(scope.row)"
                            >修改发布时间</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                        background
                        layout="total, prev, pager, next"
                        :current-page="query.pageNum"
                        :page-size="query.pageSize"
                        :total="pageTotal"
                        @current-change="handlePageChange"
                ></el-pagination>
            </div>
        </div>

        <!-- 图片弹出框 -->
        <el-dialog title="封面图片" :visible.sync="imageVisible" width="40%">
            <el-table
                    :data="picData"
                    border
                    class="table"
                    ref="multipleTable"
                    header-cell-class-name="table-header"
            >
                <el-table-column prop="imageName" label="图片(点击预览大图)" align="center">
                    <template slot-scope="scope">
                        <el-image
                                class="table-td-thumb"
                                :src="picData[scope.$index]"
                                :preview-src-list="picData"
                        ></el-image>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="120" align="center">
                    <template slot-scope="scope">
                        <el-button
                                type="text"
                                icon="el-icon-delete"
                                class="red"
                                @click="picDelete(scope.$index)"
                                v-if="globalPermission.news.delpic"
                        >删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
               <el-upload
                       ref="uploadPicture"
                       class="upload-demo"
                       action="https://api.sctynews.cn/manager/file/upload"
                       :headers="getToken"
                       :before-upload="beforeUpload"
                       :data="uploadData"
                       :on-success="refreshPic"
                       multiple
               >
                    <el-button size="medium" type="primary" v-if="globalPermission.news.addpic">上传文件</el-button>
                    <div slot="tip" class="el-upload__tip">友情提示：图片操作实时更新</div>
                </el-upload>
            </span>
        </el-dialog>

        <!-- 审核弹出框 -->
        <el-dialog title="审核" :visible.sync="statusVisible" width="30%">
            <el-form ref="form" :model="statusForm" label-width="70px">
                <el-form-item label="状态">
                    <el-radio-group v-model="statusForm.artStatus">
                        <el-radio :label="0">通过</el-radio>
                        <el-radio :label="1">不通过</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="审批意见">
                    <el-input v-model="statusForm.approveStr"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="clean">取 消</el-button>
                <el-button type="primary" @click="saveStatus">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 编辑弹出框 -->
        <el-dialog title="查看/编辑" :visible.sync="editVisible" width="80%">
            <el-form ref="form" :model="form" label-width="170px">
                <el-form-item label="新闻名" >
                    <el-input v-model="form.title"></el-input>
                </el-form-item>
                <el-form-item label="来源" >
                    <el-input v-model="form.source"></el-input>
                </el-form-item>
                <el-form-item label="记者" >
                    <el-input v-model="form.reporter"></el-input>
                </el-form-item>
                <el-form-item label="栏目">
                    <el-select v-model="form.colId" placeholder="栏目" class="handle-input mr10">
                        <el-option
                                v-for="item in colOptions"
                                :key="item.key"
                                :label="item.label"
                                :value="item.key">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="封面类型">
                        <el-radio-group v-model="imgType">
                            <el-radio :label="1">默认</el-radio>
                            <el-radio :label="2">自定义</el-radio>
                        </el-radio-group>
                    </el-form-item>
                <el-form-item label="封面图" v-if="imgType == 1" required>
                    
                    <el-select v-model="form.coverUrl" placeholder="请选择" class="handle-input mr10">
                        <el-option
                                v-for="item in colOptions_cover"
                                :key="item.key"
                                :label="item.label"
                                :value="item.key">
                        </el-option>
                    </el-select>
                    <div v-if="form.coverUrl"><img :src="form.coverUrl" style="width: 110px;height: 75px;" alt=""></div>
                </el-form-item>
                <el-form-item label="封面图：" v-if="imgType == 2" required>
                    <UploadImg v-model="form.coverUrl"></UploadImg>     
                </el-form-item>
                
<!--                <el-form-item label="位置">
                    <el-select v-model="form.pcfirst" placeholder="位置" class="handle-input mr10">
                        <el-option
                                v-for="item in pcFirstOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="栏目封面">
                    <el-radio-group v-model="form.pcCover">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                </el-form-item>-->
                    <el-form-item label="类型">
                        <el-radio-group v-model="form.artType">
                            <el-radio :label="1">视频</el-radio>
                            <el-radio :label="2">文本</el-radio>
                            <el-radio :label="3">图文</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="收藏基数">
                        <el-input v-model="form.baseCollectionCount" type="number" class="handle-input mr10"></el-input>
                    </el-form-item>
<!--                    <el-form-item label="点赞基数">-->
<!--                        <el-input v-model="form.basePariseCount" type="number" class="handle-input mr10"></el-input>-->
<!--                    </el-form-item>-->
                    <el-form-item label="浏览基数">
                        <el-input v-model="form.baseViewCount" type="number" class="handle-input mr10"></el-input>
                    </el-form-item>
                    <el-form-item label="top">
                        <el-radio-group v-model="form.topping">
                            <el-radio :label="0">正常</el-radio>
                            <el-radio :label="1">热门</el-radio>
                            <el-radio :label="2">置顶</el-radio>
                        </el-radio-group>
                    </el-form-item>
<!--                    <el-form-item label="审批意见">-->
<!--                        <el-input v-model="form.statusDesc"></el-input>-->
<!--                    </el-form-item>-->
                    <el-form-item label="摘要">
                        <el-input v-model="form.subTitle"></el-input>
                    </el-form-item>
                    <el-form-item label="正文" v-if="form.artType=='2' || form.artType=='3'">
                        <!-- <quill-editor ref="myTextEditor" v-model="form.content" :options="editorOption3"></quill-editor> -->
                        <TinymceEditor ref="tinyceEditor" v-model="form.content"/>
                        <div style="color:red;">{{saveDraftTimeString}}</div>
                    </el-form-item>
                <el-form-item label="视频" v-if="form.artType=='1'">
                    <video-player class="vjs-custom-skin" :options="playerOptions"></video-player>
                </el-form-item>
                <el-form-item>
                    <el-upload
                            ref="uploadPicture"
                            class="upload-demo"
                            action="https://api.sctynews.cn/manager/file/uploadVideo"
                            :headers="getToken"
                            :before-upload="beforeUpload"
                            :data="uploadData"
                            v-if="form.artType=='1'"
                            :on-success="handleAvatarSuccess"
                            multiple
                    >
                        <el-button size="medium" type="primary" >上传视频</el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="saveDraftTime" type="primary">保存，继续编辑</el-button>
                <el-button type="primary" @click="saveEdit">保存，退出编辑</el-button>
                <el-button @click="clean">取 消</el-button>
            </span>
        </el-dialog>

        <DialogHCode :dialogVisible.sync="minDialogVisible" :imageUrl="codeImageUrl" :title="title"/>
        <DialogTime ref="DialogTime" :dialogVisible.sync="timeDialogVisible" @reloadData="getData()"/>

    </div>
</template>

<script>
    import moment from "moment";
    import {getAllNews, delNews, saveNews, editNews, getDetail, delNewsPic, saveStatus, getGeneralCode} from '../../api/news';
    import {getAllTabs} from "../../api/tabs";
    import 'quill/dist/quill.snow.css' // 富文本编辑器外部引用样式  三种样式三选一引入即可
    //import 'quill/dist/quill.core.css'
    //import 'quill/dist/quill.bubble.css'
    import '@/utils/size-set.css';
    import {quillRedefine} from 'vue-quill-editor-upload'
    import { quillEditor,Quill } from 'vue-quill-editor';
    import {container, ImageExtend, QuillWatch} from 'quill-image-extend-module'
    Quill.register('modules/ImageExtend', ImageExtend);
    let fontSizeStyle = Quill.import('attributors/style/size');
    fontSizeStyle.whitelist = ['10px', '12px', '14px', '16px','18px','20px', '22px','24px', '26px', '28px','30px','32px', '36px','38px', '40px','45px', '50px', false];
    Quill.register(fontSizeStyle, true);
    //引入video样式
    import 'video.js/dist/video-js.css'
    import 'vue-video-player/src/custom-theme.css'
    //引入hls.js
    var queryTabsRequest = {url: "/manager/column/getUserColumns"};
    var queryRequest = {url: "/manager/article/getArticles"};
    var addRequest = {url: "/manager/article/addArticle"};
    var updateRequest = {url: "/manager/article/updateArticle"};
    var delRequest = {url: "/manager/article/delArticleById"};
    var getDetailRequest = {url:"/manager/article/getArticleDetail"};
    var delPicRequest = {url:"/manager/article/delCover"};
    var approveRequest = {url:"/manager/article/approvalArticle"};
    export default {

        name: 'usertable',
        data() {
            return {
                title: '',
                codeImageUrl: '',
                minDialogVisible: false,
                timeDialogVisible: false,
                saveDraftTimeString: '',
                rechargeTimer: undefined,
                query: {
                    artStatus: '',
                    titleDesc: '',
                    colId:'',
                    pcfirst:'',
                    pageNum: 1,
                    pageSize: 10,
                    startTime:'',
                    endTime:''
                },
                delQuery:{
                    articleId:'',
                    index:''
                },
                statusQuery:{
                    id:'',
                    approveStr:'',
                    status
                },
                editorOption2:{},
                editorOption3: {
                    modules: {
                        ImageExtend: {
                            loading: true,
                            name: 'file',
                            action: "https://api.sctynews.cn/manager/file/uploadImg",
                            response: (res) => {
                                return res.data
                            }
                        },
                        toolbar: {
                            container: [
                                ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
                                ["blockquote", "code-block"], //引用，代码块

                                [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
                                [{ list: "ordered" }, { list: "bullet" }], //列表
                                [{ script: "sub" }, { script: "super" }], // 上下标
                                [{ indent: "-1" }, { indent: "+1" }], // 缩进
                                [{ direction: "rtl" }], // 文本方向
                                [{ size: fontSizeStyle.whitelist}], // 字体大小  ["small", false, "large", "huge"]
                                //[{ size:['10px', false, '12px', '14px', '16px', '18px', '20px', '22px', '24px', '26px', '28px', '30px', '33px', '36px', '40px']}],
                                [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题

                                [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
                                [{ align: [] }], //对齐方式
                                ["image"],["clean"] //上传图片、上传视频 //清除字体样式
                            ],
                            handlers: {
                                'image': function () {
                                    QuillWatch.emit(this.quill.id)
                                }
                            }
                        }
                    }
                },
                globalPermission: this.getPermission(),
                getToken:{'auth-token':localStorage.getItem('token')},
                uploadData:null,
                picData :[],
                tableData: [],
                multipleSelection: [],
                delList: [],
                editVisible: false,
                imageVisible:false,
                editRolesVisible: false,
                statusVisible:false,
                formIdShow: false,
                isCurEdit: false,
                pageTotal: 0,
                videoUrl:'',
                form: {},
                statusForm:{},
                idx: -1,
                id: -1,
                status:0,
                statusOptions: [{
                    value: 0,
                    label: '初稿'
                }, {
                    value: 1,
                    label: '初审通过'
                }, {
                    value: 2,
                    label: '复审通过'
                }, {
                    value: 3,
                    label: '发布'
                }, {
                    value: 4,
                    label: '审批不通过'
                }],
                pcFirstOptions: [{
                    value: -1,
                    label: '普通'
                }, {
                    value: 0,
                    label: '帖子位'
                }, {
                    value: 1,
                    label: '轮播图位'
                }, {
                    value: 2,
                    label: '视频位'
                }],
                colOptions:this.getColOptions(),
                imgType: 1,// 1默认封面图片 2自定义封面图片
                colOptions_cover: [{
                    key:"https://ctyly-1307432455.cos.ap-chengdu.myqcloud.com/upload/202408091431030Z9L.jpg",
                    label: '最新播报',
                },{
                    key:"https://ctyly-1307432455.cos.ap-chengdu.myqcloud.com/upload/202408091431336KLX.jpg",
                    label: '快讯',
                },{
                    key:"https://ctyly-1307432455.cos.ap-chengdu.myqcloud.com/upload/202408091427033b3n.jpg",
                    label: '国际',
                },{
                    key:"https://ctyly-1307432455.cos.ap-chengdu.myqcloud.com/upload/202408091427492uP8.jpg",
                    label: '国内',
                },{
                    key:"https://ctyly-1307432455.cos.ap-chengdu.myqcloud.com/upload/202408091432058SFY.jpg",
                    label: '谣言',
                },{
                    key:"https://ctyly-1307432455.cos.ap-chengdu.myqcloud.com/upload/202408091356301AtO.jpg",
                    label: '通知',
                },{
                    key: "https://ctyly-1307432455.cos.ap-chengdu.myqcloud.com/upload/202408091429122BxB.jpg",
                    label: '时政',
                },{
                    key:"https://ctyly-1307432455.cos.ap-chengdu.myqcloud.com/upload/202408091429446oHd.jpg",
                    label: '资讯',
                },{
                    key:"https://ctyly-1307432455.cos.ap-chengdu.myqcloud.com/upload/202408091430143BxC.jpg",
                    label: '关注',
                },{
                    key:"https://ctyly-1307432455.cos.ap-chengdu.myqcloud.com/upload/2024080914303730jb.jpg",
                    label: '提醒',
                }],
                editor: null,   // 富文本编辑器对象
                content: "", // 富文本编辑器默认内容
                editorOption: { //  富文本编辑器配置
                    placeholder: '请输入正文',
                    modules:{
                        toolbar:{
                            container:[
                                ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
                                ["blockquote", "code-block"], //引用，代码块

                                [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
                                [{ list: "ordered" }, { list: "bullet" }], //列表
                                [{ script: "sub" }, { script: "super" }], // 上下标
                                [{ indent: "-1" }, { indent: "+1" }], // 缩进
                                [{ direction: "rtl" }], // 文本方向
                                //[{ size: fontSizeStyle.whitelist}], // 字体大小  ["small", false, "large", "huge"]
                                [{ size:['10px', false, '12px', '14px', '16px', '18px', '20px', '22px', '24px', '26px', '28px', '30px', '33px', '36px', '40px']}],
                                [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题

                                [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
                                [{ align: [] }], //对齐方式
                                ["image"],["clean"] //上传图片、上传视频 //清除字体样式
                            ]
                        }
                    }
                },
                playerOptions: {
                    playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
                    autoplay: false, //如果true,浏览器准备好时开始回放。
                    controls: true, //控制条
                    preload: 'auto', //视频预加载
                    muted: false, //默认情况下将会消除任何音频。
                    loop: false, //导致视频一结束就重新开始。
                    language: 'zh-CN',
                    aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [{
                        type: 'video/mp4',
                        src: ''//你所放置的视频的地址，最好是放在服务器上
                    }],
                    //poster: "http://39.106.117.192:8080/static/indexImg.png", //你的封面地址（覆盖在视频上面的图片）
                    width: document.documentElement.clientWidth,
                    notSupportedMessage: '此视频暂无法播放，请稍后再试' //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                },
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                timeValue:'',
            };
        },
        components:{
            quillEditor,quillRedefine
        },
        beforeDestroy() {
            // 在组件销毁前执行的代码
            console.log('组件即将销毁');
            // 执行清理工作，如取消定时器、清除DOM元素等
            clearInterval(this.rechargeTimer)
        },
        created() {
            queryRequest.data = this.query;
            this.getData();
            this.editorOption2 = quillRedefine(
                {
                    // 图片上传的设置
                    uploadConfig: {
                        action:  'http://140.143.37.174:9092/manager/file/uploadImg',  // 必填参数 图片上传地址
                        // 必选参数  res是一个函数，函数接收的response为上传成功时服务器返回的数据
                        // 你必须把返回的数据中所包含的图片地址 return 回去
                        res: (res) => {
                            return res.data  // 这里切记要return回你的图片地址
                        },
                        name:'file'  //图片上传的参数名，也即是后台接口接受的参数名
                    }
                }
            )
        },
        methods: {
            getImg(img) {
                // scope.row.img.includes(',') ? scope.row.img : scope.row.img
                if (img.includes(',')) {
                    
                    return img.split(',')[0]
                } 
                return img
           },
            // 修改发布时间
            changeTime(item) {
                this.timeDialogVisible = true;
                this.$refs.DialogTime.open(item)

            },
             // 查看App中H5二维码
            async checkAppCode(item) {

                const urlString = `https://wap.sctynews.cn/#/newsDetail/${item.id}`
               const params = { link: encodeURIComponent(urlString)}
            const res = await getGeneralCode(params)
            this.codeImageUrl = res.data
            this.title = `H5二维码(${item.title})`
            this.minDialogVisible = true;
            console.log('res='+JSON.stringify(res));
            },
            // 获取数据
            getData() {
                //获取新闻列表
                getAllNews(queryRequest).then(res => {
                    this.tableData = res.data.list;
                    this.pageTotal = res.data.total;
                });
            },
            getColOptions(){
                const data = [];
                getAllTabs(queryTabsRequest).then(res =>{
                    res.data.forEach(function (tabs) {
                        data.push({
                            label: tabs.colName,
                            key: tabs.id
                        });
                    });
                });
                return data;
            },
            clean(){
                this.editVisible=false;
                this.statusVisible=false;
                // this.handleSearch();
                this.getData();
                clearInterval(this.rechargeTimer)
                this.saveDraftTimeString = ''
                this.imgType = 1
                this.form = {}


            },
            handleAvatarSuccess(res, file) {
                this.playerOptions.sources[0].src=res.data;
                this.form.content =res.data;
            },
            //审核
            handleStatus(id,status){
                this.id= id;
                this.status=status;
                this.statusVisible=true;
            },
            saveStatus(){
                if (this.statusForm.artStatus===0){
                    this.statusQuery.status=this.status+1;
                    //this.statusForm.artStatus=this.status+1;
                } else{
                    this.statusQuery.status=4;
                }
                this.statusQuery.id=this.id;
                this.statusQuery.approveStr=this.statusForm.approveStr;
                approveRequest.data = this.statusQuery;
                saveStatus(approveRequest).then(res=>{
                    if (res.code === 0) {
                        this.$message.success(`审核成功`);
                        this.handleSearch();
                    } else {
                        this.$message.error(res.message);
                    }
                    this.statusForm={};
                    this.statusVisible=false;
                })
            },
            getPermission() {
                return JSON.parse(sessionStorage.getItem("permission"));
            },
            beforeUpload (file) {
                this.uploadData = {articleId:this.id};
                let promise = new Promise((resolve) => {
                    this.$nextTick(function () {
                        resolve(true);
                    });
                });
                return promise; //通过返回一个promis对象解决
            },
            // 打开图片窗口
            handleImage(id,imgs) {
                this.id=id;
                if (imgs ==='' || imgs===null){

                }else{
                    this.picData = imgs.split(',');
                }
                this.imageVisible = true;
            },
            refreshPic(){
                getDetailRequest.id =this.id;
                getDetail(getDetailRequest).then(res=>{
                    this.picData = res.data.coverUrl.split(',');
                });
            },
            // 删除图片
            picDelete(index) {
                // 二次确认删除
                this.$confirm('确认删除此图片？', '提示', {
                    type: 'warning'
                }).then(() => {
                    this.delQuery.articleId=this.id;
                    this.delQuery.index=index;
                    delPicRequest.data=this.delQuery;
                    delNewsPic(delPicRequest).then(res => {
                        if (res.code === 0) {
                            this.$message.success(`删除成功`);
                            this.picData.splice(index, 1);
                            this.handleSearch();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                });
            },
            // 触发搜索按钮
            handleSearch() {
                this.query.startTime=this.timeValue[0];
                this.query.endTime=this.timeValue[1];
                this.$set(this.query, 'pageNum', 1);
                this.getData();
            },
            // 删除操作
            handleDelete(id, row) {
                // 二次确认删除
                this.$confirm('确定要删除吗？', '提示', {
                    type: 'warning'
                }).then(() => {
                    delRequest.id = id;
                    delNews(delRequest).then(res => {
                        if (res.code == 0) {
                            this.$message.success(`删除成功`);
                            this.handleSearch();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                    //this.tableData.splice(index, 1);
                }).catch(() => {
                });
            },
            // 多选操作
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },

            // 新增
            addItem() {
                this.form={};
                this.editVisible = true;
                this.isCurEdit = false;
                // 每两分钟保存一次草稿
                this.rechargeTimer = setInterval(this.saveDraftTime, 120*1000)
            },
             // 每两分钟保存一次草稿
            async saveDraftTime() {
                // console.log('this.form='+JSON.stringify(this.form));

                if (!this.form.title) {
                return
                }
                if (!this.form.colId) {
                    return
                }
                if (!this.form.content) {
                    return
                }
                if (this.isCurEdit) {
                    updateRequest.data = this.form;
                    editNews(updateRequest).then(res => {
                        if (res.code == 0) {
                            // this.$message.success(`保存草稿成功`);
                            const date = new Date()
                this.saveDraftTimeString = moment(date).format("YYYY-MM-DD HH:mm:ss")+' 保存草稿成功';
                            // this.editVisible = false;
                            // this.handleSearch();
                            this.getData();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                } else {
                    addRequest.data = this.form;
                    saveNews(addRequest).then(res => {
                        if (res.code == 0) {
                            this.isCurEdit = true
                            // 注： 新增时不退出编辑保存草稿，再次编辑时需要保存id
                            this.form.id = res.data
                            // this.$message.success(`保存草稿成功`);
                            const date = new Date()
                this.saveDraftTimeString = moment(date).format("YYYY-MM-DD HH:mm:ss")+' 保存草稿成功';
                            // this.editVisible = false;
                            // this.handleSearch();
                            this.getData();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                }
            },
            // 判断是否有默认图片
            isContainDefaultImg(coverUrl) {
                // console.log('coverUrl='+coverUrl);
                let array = []
                this.colOptions_cover.forEach(item => {
                    // console.log('item.key='+item.key);
                    array.push(item.key)
                })
                if (array.includes(coverUrl)) {
                    return true
                }
                return false
                
            },
            // 编辑操作
            handleEdit(id) {
                this.id = id;
                getDetailRequest.id =id;
                getDetail(getDetailRequest).then(res=>{
                   this.form = res.data;
                // 是否有多张图片   
                   if (this.form.coverUrl.includes(',')) {
                    this.form.coverUrl = this.form.coverUrl.split(',')[0]
                   } 
                   // 是否是默认图片
                //    console.log('this.isContainDefaultImg(this.form.coverUrl)='+this.isContainDefaultImg(this.form.coverUrl));
                   if (this.isContainDefaultImg(this.form.coverUrl)) {
                     this.imgType = 1 // 默认封面图片
                   } else {
                    this.imgType = 2 // 自定义封面
                   }
                   if (res.data.artType==1){
                       //视频帖
                       this.playerOptions.sources[0].src=res.data.content;
                   }
                });
                this.editVisible = true;
                this.isCurEdit = true;
                // 每两分钟保存一次草稿
                this.rechargeTimer = setInterval(this.saveDraftTime, 120*1000)
            },
            // 保存编辑
            saveEdit() {
                if (!this.form.coverUrl) {
                    return this.$message({ message: '请选择一张默认封面图', type: 'error' })
                        
                    }
                //this.$set(this.tableData, this.idx, this.form);
                if (this.isCurEdit) {
                    updateRequest.data = this.form;
                    editNews(updateRequest).then(res => {
                        if (res.code == 0) {
                            this.$message.success(`修改成功`);
                            this.editVisible = false;
                            clearInterval(this.rechargeTimer)
                            this.saveDraftTimeString = ''
                            this.imgType = 1
                            this.form = {}
                            // this.handleSearch();
                            this.getData();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                } else {
                    
                    addRequest.data = this.form;
                    saveNews(addRequest).then(res => {
                        if (res.code == 0) {
                            this.$message.success(`新增成功`);
                            this.editVisible = false;
                            clearInterval(this.rechargeTimer)
                            this.saveDraftTimeString = ''
                            this.imgType = 1
                            this.form = {}
                            this.handleSearch();
                            // this.getData();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                }
            },
            // 分页导航
            handlePageChange(val) {
                this.$set(this.query, 'pageNum', val);
                this.getData();
            }
        }
    };
</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 130px;
        display: inline-block;
    }

    .table {
        width: 100%;
        font-size: 14px;
    }

    .red {
        color: #ff0000;
    }

    .mr10 {
        margin-right: 10px;
    }

    .table-td-thumb {
        display: block;
        margin: auto;
        width: 40px;
        height: 40px;
    }

    .show {
        display: block;
    }

    .hide {
        display: none;
    }

    .checkbox-rows {
        width: 130px;
        margin-top: 10px;
    }

    .el-checkbox.is-bordered + .el-checkbox.is-bordered {
        margin-left: 0px;
    }
</style>
