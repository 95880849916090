import request from '../utils/request';

export const getAllLiveNotice = query => {
    return request({
        url: query.url,
        method: 'post',
        data: query.data
    });
};

export const getLiveDetailNotice = query => {
    query.url ="/manager/live/noticeDetail?id="+ query.id;
    return request({
        url: query.url,
        method: 'get',
        data: query.data
    });
};

export const delLiveNotice = query => {
    query.url ="/manager/live/delContestNotice?id="+ query.id;
    return request({
        url: query.url,
        method: 'get',
        params: query.data
    });
};

export const saveLiveNotice= query => {
    return request({
        url: query.url,
        method: 'post',
        data: query.data
    });
};

export const editLiveNotice = query => {
    return request({
        url: query.url,
        method: 'post',
        data: query.data
    });
};
