var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "header" },
    [
      _c(
        "div",
        { staticClass: "collapse-btn", on: { click: _vm.collapseChage } },
        [
          !_vm.collapse
            ? _c("i", { staticClass: "el-icon-s-fold" })
            : _c("i", { staticClass: "el-icon-s-unfold" }),
        ]
      ),
      _c("div", { staticClass: "logo" }, [_vm._v("川体管理系统")]),
      _c("div", { staticClass: "header-right" }, [
        _c(
          "div",
          { staticClass: "header-user-con" },
          [
            _c(
              "div",
              {
                staticClass: "btn-fullscreen",
                on: { click: _vm.handleFullScreen },
              },
              [
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      effect: "dark",
                      content: _vm.fullscreen ? `取消全屏` : `全屏`,
                      placement: "bottom",
                    },
                  },
                  [_c("i", { staticClass: "el-icon-rank" })]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "user-avator" },
              [
                _c("avatar", {
                  attrs: {
                    size: 40,
                    username: _vm.name,
                    color: "rgb(255, 208, 75)",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-dropdown",
              {
                staticClass: "user-name",
                attrs: { trigger: "click" },
                on: { command: _vm.handleCommand },
              },
              [
                _c("span", { staticClass: "el-dropdown-link" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.name) +
                      "\n                    "
                  ),
                  _c("i", { staticClass: "el-icon-caret-bottom" }),
                ]),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "el-dropdown-item",
                      { attrs: { divided: "", command: "password" } },
                      [_vm._v("修改密码")]
                    ),
                    _c(
                      "el-dropdown-item",
                      { attrs: { divided: "", command: "loginout" } },
                      [_vm._v("退出登录")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改密码",
            visible: _vm.updatePasswordVisible,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.updatePasswordVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "updatePasswordForm",
              attrs: {
                model: _vm.updatePasswordForm,
                rules: _vm.updatePasswordRules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "旧密码", prop: "oldPassword" } },
                [
                  _c("el-input", {
                    attrs: { id: "oldPassword" },
                    model: {
                      value: _vm.updatePasswordForm.oldPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.updatePasswordForm, "oldPassword", $$v)
                      },
                      expression: "updatePasswordForm.oldPassword",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "确认旧密码", prop: "oldPasswordConfirm" } },
                [
                  _c("el-input", {
                    attrs: { id: "oldPasswordConfirm" },
                    model: {
                      value: _vm.updatePasswordForm.oldPasswordConfirm,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.updatePasswordForm,
                          "oldPasswordConfirm",
                          $$v
                        )
                      },
                      expression: "updatePasswordForm.oldPasswordConfirm",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "新密码", prop: "newPassword" } },
                [
                  _c("el-input", {
                    attrs: { id: "newPassword" },
                    model: {
                      value: _vm.updatePasswordForm.newPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.updatePasswordForm, "newPassword", $$v)
                      },
                      expression: "updatePasswordForm.newPassword",
                    },
                  }),
                ],
                1
              ),
              _c("el-form-item", [
                _c("div", { staticClass: "intensity" }, [
                  _c("span", { staticClass: "psdText" }, [_vm._v("密码强度")]),
                  _c("span", {
                    staticClass: "line",
                    class: [_vm.level.includes("low") ? "low" : ""],
                  }),
                  _c("span", {
                    staticClass: "line",
                    class: [_vm.level.includes("middle") ? "middle" : ""],
                  }),
                  _c("span", {
                    staticClass: "line",
                    class: [_vm.level.includes("high") ? "high" : ""],
                  }),
                  _c("div", { staticClass: "warningtext" }, [
                    _vm._v(
                      "\n                            密码应由8-16位数字、大小写字母、特殊符号组成,请不要使用容易被猜到的密码\n                        "
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.updatePasswordVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitUpdatePassword("updatePasswordForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }