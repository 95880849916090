var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "cropper",
      staticClass: "vue-cropper",
      on: { mouseover: _vm.scaleImg, mouseout: _vm.cancelScale },
    },
    [
      _vm.imgs
        ? _c("div", { staticClass: "cropper-box" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loading,
                    expression: "!loading",
                  },
                ],
                staticClass: "cropper-box-canvas",
                style: {
                  width: _vm.trueWidth + "px",
                  height: _vm.trueHeight + "px",
                  transform:
                    "scale(" +
                    _vm.scale +
                    "," +
                    _vm.scale +
                    ") " +
                    "translate3d(" +
                    _vm.x / _vm.scale +
                    "px," +
                    _vm.y / _vm.scale +
                    "px," +
                    "0)" +
                    "rotateZ(" +
                    _vm.rotate * 90 +
                    "deg)",
                },
              },
              [
                _c("img", {
                  ref: "cropperImg",
                  attrs: { src: _vm.imgs, alt: "cropper-img" },
                }),
              ]
            ),
          ])
        : _vm._e(),
      _c("div", {
        staticClass: "cropper-drag-box",
        class: {
          "cropper-move": _vm.move && !_vm.crop,
          "cropper-crop": _vm.crop,
          "cropper-modal": _vm.cropping,
        },
        on: { mousedown: _vm.startMove, touchstart: _vm.startMove },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.cropping,
              expression: "cropping",
            },
          ],
          staticClass: "cropper-crop-box",
          style: {
            width: _vm.cropW + "px",
            height: _vm.cropH + "px",
            transform:
              "translate3d(" +
              _vm.cropOffsertX +
              "px," +
              _vm.cropOffsertY +
              "px," +
              "0)",
          },
        },
        [
          _c("span", { staticClass: "cropper-view-box" }, [
            _c("img", {
              style: {
                width: _vm.trueWidth + "px",
                height: _vm.trueHeight + "px",
                transform:
                  "scale(" +
                  _vm.scale +
                  "," +
                  _vm.scale +
                  ") " +
                  "translate3d(" +
                  (_vm.x - _vm.cropOffsertX) / _vm.scale +
                  "px," +
                  (_vm.y - _vm.cropOffsertY) / _vm.scale +
                  "px," +
                  "0)" +
                  "rotateZ(" +
                  _vm.rotate * 90 +
                  "deg)",
              },
              attrs: { src: _vm.imgs, alt: "cropper-img" },
            }),
          ]),
          _c("span", {
            staticClass: "cropper-face cropper-move",
            on: { mousedown: _vm.cropMove, touchstart: _vm.cropMove },
          }),
          _vm.info
            ? _c(
                "span",
                { staticClass: "crop-info", style: { top: _vm.cropInfo.top } },
                [
                  _vm._v(
                    _vm._s(this.cropInfo.width) +
                      " × " +
                      _vm._s(this.cropInfo.height)
                  ),
                ]
              )
            : _vm._e(),
          !_vm.fixedBox
            ? _c("span", [
                _c("span", {
                  staticClass: "crop-line line-w",
                  on: {
                    mousedown: function ($event) {
                      return _vm.changeCropSize($event, false, true, 0, 1)
                    },
                    touchstart: function ($event) {
                      return _vm.changeCropSize($event, false, true, 0, 1)
                    },
                  },
                }),
                _c("span", {
                  staticClass: "crop-line line-a",
                  on: {
                    mousedown: function ($event) {
                      return _vm.changeCropSize($event, true, false, 1, 0)
                    },
                    touchstart: function ($event) {
                      return _vm.changeCropSize($event, true, false, 1, 0)
                    },
                  },
                }),
                _c("span", {
                  staticClass: "crop-line line-s",
                  on: {
                    mousedown: function ($event) {
                      return _vm.changeCropSize($event, false, true, 0, 2)
                    },
                    touchstart: function ($event) {
                      return _vm.changeCropSize($event, false, true, 0, 2)
                    },
                  },
                }),
                _c("span", {
                  staticClass: "crop-line line-d",
                  on: {
                    mousedown: function ($event) {
                      return _vm.changeCropSize($event, true, false, 2, 0)
                    },
                    touchstart: function ($event) {
                      return _vm.changeCropSize($event, true, false, 2, 0)
                    },
                  },
                }),
                _c("span", {
                  staticClass: "crop-point point1",
                  on: {
                    mousedown: function ($event) {
                      return _vm.changeCropSize($event, true, true, 1, 1)
                    },
                    touchstart: function ($event) {
                      return _vm.changeCropSize($event, true, true, 1, 1)
                    },
                  },
                }),
                _c("span", {
                  staticClass: "crop-point point2",
                  on: {
                    mousedown: function ($event) {
                      return _vm.changeCropSize($event, false, true, 0, 1)
                    },
                    touchstart: function ($event) {
                      return _vm.changeCropSize($event, false, true, 0, 1)
                    },
                  },
                }),
                _c("span", {
                  staticClass: "crop-point point3",
                  on: {
                    mousedown: function ($event) {
                      return _vm.changeCropSize($event, true, true, 2, 1)
                    },
                    touchstart: function ($event) {
                      return _vm.changeCropSize($event, true, true, 2, 1)
                    },
                  },
                }),
                _c("span", {
                  staticClass: "crop-point point4",
                  on: {
                    mousedown: function ($event) {
                      return _vm.changeCropSize($event, true, false, 1, 0)
                    },
                    touchstart: function ($event) {
                      return _vm.changeCropSize($event, true, false, 1, 0)
                    },
                  },
                }),
                _c("span", {
                  staticClass: "crop-point point5",
                  on: {
                    mousedown: function ($event) {
                      return _vm.changeCropSize($event, true, false, 2, 0)
                    },
                    touchstart: function ($event) {
                      return _vm.changeCropSize($event, true, false, 2, 0)
                    },
                  },
                }),
                _c("span", {
                  staticClass: "crop-point point6",
                  on: {
                    mousedown: function ($event) {
                      return _vm.changeCropSize($event, true, true, 1, 2)
                    },
                    touchstart: function ($event) {
                      return _vm.changeCropSize($event, true, true, 1, 2)
                    },
                  },
                }),
                _c("span", {
                  staticClass: "crop-point point7",
                  on: {
                    mousedown: function ($event) {
                      return _vm.changeCropSize($event, false, true, 0, 2)
                    },
                    touchstart: function ($event) {
                      return _vm.changeCropSize($event, false, true, 0, 2)
                    },
                  },
                }),
                _c("span", {
                  staticClass: "crop-point point8",
                  on: {
                    mousedown: function ($event) {
                      return _vm.changeCropSize($event, true, true, 2, 2)
                    },
                    touchstart: function ($event) {
                      return _vm.changeCropSize($event, true, true, 2, 2)
                    },
                  },
                }),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }