<template>
  <div>
    <!-- 添加组别 -->
    <el-dialog title="添加组别" :visible.sync="_dialogVisible" :show-close="false" :close-on-click-modal="false">
      <div>
        <el-form ref="ruleForm" label-width="130px" class="demo-ruleForm">
          <el-form-item label="组别名称：" required>
            <el-input v-model="groupInfo.groupName"></el-input>
          </el-form-item>
          <el-form-item label="参赛价格：" required>
            <el-input-number v-model="groupInfo.price" :precision="2" :step="0.1" :min="0" :max="99999999">
            </el-input-number>
          </el-form-item>
          <el-form-item label="参赛人数：" required>
            <el-input-number v-model="groupInfo.stock" :min="0" :max="99999999">
            </el-input-number>
            <!-- <el-input v-model="groupInfo.stock"></el-input> -->
          </el-form-item>
          <el-form-item label="参赛年龄：" required>
            <el-date-picker v-model="ageTime" type="datetimerange" range-separator="至" start-placeholder="最大"
              end-placeholder="最小" value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
            <!-- <el-date-picker v-model="groupInfo.minAge" type="date" value-format="yyyy-MM-dd HH:mm:ss" placeholder="最小年龄">
						</el-date-picker>
						<el-date-picker v-model="groupInfo.maxAge" type="date" value-format="yyyy-MM-dd HH:mm:ss" placeholder="最大年龄">
						</el-date-picker> -->

          </el-form-item>
          <el-form-item label="参赛性别：" required>
            <el-select v-model="groupInfo.gender" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="组别类型：" required>
            <el-select v-model="groupInfo.groupType" placeholder="请选择">
              <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="组别比赛地址：" required>
            <SelectAddress @update="update" :addressCode.sync="addressCode" :site.sync="site" />
          </el-form-item>
          <el-form-item label="详细地址：" required>
            <el-input v-model="groupInfo.addressDetail"></el-input>
          </el-form-item>
          <el-form-item label="比赛时间：" required>
            <el-date-picker v-model="gameTime" type="datetimerange" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="线上缴付：" required>
            <el-radio v-model="groupInfo.payType" :label="0">是</el-radio>
            <el-radio v-model="groupInfo.payType" :label="1">否</el-radio>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirm" type="primary">确定</el-button>
        <el-button @click="cancel">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import SelectAddress from './SelectAddress'; //选择地址
export default {
    name: 'index',
    components: {
        SelectAddress
    },
    data() {
        return {
            site: ['四川', '', ''],
            siteData: { site: ['四川', '', ''], addressCode: [26, '', ''] },
            groupInfo: {
                groupName: '', //组别名称
                price: '', //参赛价格
                stock: '', //参赛人数
                gender: '', //参赛性别
                groupType: '', //组别类型
                address: '', //三级联动地址 通过-拼接(省-市-区)
                provinceId: '', //省编号-ID
                cityId: '', //市编号-ID
                areaId: '', //区编号-ID
                addressDetail: '', //详细地址
                payType: 0, //线上缴付
                matchStartTime: '', //比赛开始时间
                matchEndTime: '', //比赛结束时间
                minAge: '', //最小年龄
                maxAge: '' //最大年龄
            },
            gameTime: [], //比赛时间
            ageTime: [], //参赛年龄
            radio: '',
            value: '',
            value1: '',
            options: [
                {
                    value: 0,
                    label: '女'
                },
                {
                    value: 1,
                    label: '男'
                },
                {
                    value: 2,
                    label: '不限'
                }
            ],
            options2: [
                {
                    value: '个人',
                    label: '个人'
                },
                {
                    value: '团体',
                    label: '团体'
                },
                {
                    value: '其他',
                    label: '其他'
                }
            ],
            addressCode: [26, '', '']
        };
    },
    watch: {
        groupInfoData: {
            immediate: true,
            handler(val) {
                this.groupInfo = JSON.parse(JSON.stringify(val));
                this.$set(this.gameTime, 0, this.groupInfo.matchStartTime); //比赛开始时间
                this.$set(this.gameTime, 1, this.groupInfo.matchEndTime); //比赛结束时间
                this.$set(this.ageTime, 0, this.groupInfo.maxAge); //最大年龄
                this.$set(this.ageTime, 1, this.groupInfo.minAge); //最小年龄
                this.$set(this.addressCode, 0, this.groupInfo.provinceId); //省
                this.$set(this.addressCode, 1, this.groupInfo.cityId); //市
                this.$set(this.addressCode, 2, this.groupInfo.areaId); //区
                // this.groupInfo.payType = JSON.stringify(this.groupInfo.payType);
            
                this.site = val.address.split('-');
               this.site[0] = '四川'
            }
        }
    },
    props: {
        dialogVisible: {
            type: Boolean,
            required: true,
            default: false
        },
        state: {
            type: String,
            default: () => ''
        },
        indexs: {
            type: Number,
            required: true,
            default: 0
        },
        groupInfoData: {
            type: Object,
            required: true,
            default: () => {}
        },
        tableData: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    computed: {
        _dialogVisible: {
            get() {
                return this.dialogVisible;
            },
            set(val) {
                this.$emit('update:dialogVisible', val);
            }
        }
    },
    methods: {
        //初始化数据
        initializeData() {
            this.groupInfo = {
                groupName: '', //组别名称
                price: '', //参赛价格
                stock: '', //参赛人数
                gender: '', //参赛性别
                groupType: '', //组别类型
                address: '', //三级联动地址 通过-拼接(省-市-区)
                provinceId: 26, //省编号-ID
                cityId: '', //市编号-ID
                areaId: '', //区编号-ID
                addressDetail: '', //详细地址
                payType: 0, //线上缴付
                matchStartTime: '', //比赛开始时间
                matchEndTime: '', //比赛结束时间
                minAge: '', //最小年龄
                maxAge: '' //最大年龄
            };
            this.gameTime = []; //比赛时间
            this.ageTime = []; //参赛年龄
            this.siteData = { site: ['四川', '', ''], addressCode: [26, '', ''] };
            this.addressCode = [26, '', ''];
        },
        //取消
        cancel() {
            // this.groupInfo = {};
            // this.gameTime = ['',''];
            // this.ageTime = ['',''];
            // this.addressCode = ['', '', ''];
            this.initializeData;
            this._dialogVisible = false;
            this.$emit('initialize', {});
        },
        //确定
        confirm() {
            //报错处理
            if (this.gameTime == null) this.gameTime = [];
            if (this.ageTime == null) this.ageTime = [];
            // 参赛年龄
            if (this.ageTime.length < 2) {
                return this.$message.error('参赛年龄不能为空');
            }
            this.groupInfo.maxAge = this.ageTime[0];
            this.groupInfo.minAge = this.ageTime[1];
            // 组别名称
            if (this.groupInfo.groupName.length === 0) {
                return this.$message.error('组别名称不能为空');
            }
            // 参赛价格
            // if (!this.groupInfo.price) {
            //     return this.$message.error('参赛价格不能为空');
            // }
            // 参赛人数
            if (!this.groupInfo.stock) {
                return this.$message.error('参赛人数不能为空');
            }

            // 参赛性别
            if (this.groupInfo.gender.length === 0) {
                return this.$message.error('参赛性别不能为空');
            }
            // 组别类型
            if (!this.groupInfo.groupType) {
                return this.$message.error('组别类型不能为空');
            }
            // 组别比赛地址
            if (!this.addressCode[2]) {
                return this.$message.error('组别比赛地址不能为空');
            }
            // 组别比赛地址
            // if (!this.siteData.addressCode[2]) {
            //     return this.$message.error('组别比赛地址不能为空');
            // }
            // 详细地址
            if (!this.groupInfo.addressDetail) {
                return this.$message.error('详细地址不能为空');
            }
            // 比赛时间
            if (this.gameTime.length == 0) {
                return this.$message.error('比赛时间不能为空');
            }
            // 线上缴付
            if (!this.groupInfo.payType) {
                if (!(this.groupInfo.payType === 0)) {
                    return this.$message.error('线上缴付不能为空');
                }
            }
            //地址数据
            this.groupInfo.provinceId = this.siteData.addressCode[0]; //省
            this.groupInfo.cityId = this.siteData.addressCode[1]; //市
            this.groupInfo.areaId = this.siteData.addressCode[2]; //区
            this.groupInfo.address = this.siteData.site.join('-'); //区
            //比赛时间
            this.groupInfo.matchStartTime = this.gameTime[0];
            this.groupInfo.matchEndTime = this.gameTime[1];

            //年龄
            // this.groupInfo.minAge = this.ageTime[0];
            // this.groupInfo.maxAge = this.ageTime[1];
            if (this.state == 'add') {
                for (let i = 0; i < this.tableData.length; i++) {
                    if (this.tableData[i].groupName == this.groupInfo.groupName) {
                        return this.$message.error('组别名称重复');
                    }
                }
                this.$emit('group', this.groupInfo);
            } else {
                for (let i = 0; i < this.tableData.length; i++) {
                    if (this.tableData[i].groupName == this.groupInfo.groupName) {
                        if (i !== this.indexs) {
                            return this.$message.error('组别名称重复');
                        }
                    }
                }
                this.$emit('edit', { groupInfo: this.groupInfo, index: this.indexs });
            }

            this._dialogVisible = false;
            this.initializeData();
        },
        //地址数据
        update(val) {
            this.siteData = val;
        }
    },
    mounted() {}
};
</script>
<style  scoped lang="less">
.elasticity {
    display: flex;
}
.age {
    font-size: 12px;
}
.addIcon {
    margin-bottom: 18px;
    margin-left: 20px;
    font-size: 22px;
    cursor: pointer;
}
.el-input {
    width: 50%;
}
</style>