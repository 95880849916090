<template>
    <div>
        <el-dialog :title="parameter.id ? '编辑banner' : '新增banner'" :visible.sync="_dialogVisible" @close="cancel"
            :close-on-click-modal="false">
            <div>
                <el-form label-width="180px">
                    
                    <el-form-item label="活动banner图：" required>
                        <!-- <UploadImg v-model="parameter.img"></UploadImg> -->
                        <UploadImgCropperNew v-model="parameter.img" max="1" width="280" height="100" />
                        <div>图片建议尺寸(1120X400)单位：px</div>
                    </el-form-item>
                    <el-form-item label="排序：" required>
                        <el-input-number v-model="parameter.num" />
                        <span>（数字越大越靠前）</span>
                    </el-form-item>
                    <!-- 0内链1 外链 -->
					<el-form-item label="类型：" required>
						<el-radio v-model="parameter.linkType" :label="0">内链</el-radio>
						<el-radio v-model="parameter.linkType" :label="1">外链</el-radio>

					</el-form-item>
                    <el-form-item label="关联新闻id" required v-if="parameter.linkType == 0"> 
                        <el-input placeholder="请输入新闻id" v-model="newsId" clearable>
                        </el-input>
                        <!-- <el-select v-model="parameter.title" placeholder="新闻" class="handle-input mr10">
                            <el-option v-for="item in colOptions" :key="item.value" :label="item.label" :value="item.value" @click.native="handleChange(item)">
                            </el-option>
                        </el-select> -->
                        <!-- <el-cascader v-model="parameter.newsId" :options="colOptions" @change="handleChange">
                        </el-cascader> -->
                    </el-form-item>
                    <el-form-item label="外链地址：" required v-if="parameter.linkType == 1">
                        <el-input placeholder="请输入" v-model="parameter.url" clearable>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="标题：" required >
                        <el-input placeholder="请输入" v-model="parameter.title" clearable v-if="parameter.linkType == 1" >
                        </el-input>
                        <div v-else>{{title}}</div>
                    </el-form-item>
                    <!-- 0 上架 1 下架 -->
					<el-form-item label="状态：" required>
						<el-radio v-model="parameter.status" :label="0">上架</el-radio>
						<el-radio v-model="parameter.status" :label="1">下架</el-radio>

					</el-form-item>

                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirm" type="primary">保存</el-button>
                <el-button @click="cancel">取消</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import * as API from "../api/index";
export default {
    name: "index",
    data() {
        return {
            parameter: {
            },
            colOptions: [],
            newsId:'',
            title: null

        };
    },
    props: {
        dialogVisible: {
            type: Boolean,
            required: true,
            default: false,
        },

    },
    computed: {
        _dialogVisible: {
            get() {
                return this.dialogVisible;
            },
            set(val) {
                this.$emit("update:dialogVisible", val);
            },
        },
    },
    watch: {
        newsId: {
            immediate: true,
            handler(val) {
                console.log('this.parameter.newsId='+val);
                if (val) {
                    this.parameter.newsId = val;
                    this.parameter.url = this.parameter.linkType == 0 ? `news_details.html?id=${this.parameter.newsId}` : this.parameter.url
                    this.getArticleDetail(val)
                }
                
            }
        }
    },
    methods: {
        async getArticleDetail(id) {
           console.log('id222');
            let res = await API.getArticleDetail({id})
            if (res.code == 0) {
                if (res.data.title) {
                this.title = res.data.title
                console.log('this.title='+this.title);
              } 
            } else {
                // this.$message({ message: '文章不存在', type: 'error' })
                this.title = '文章不存在'
            }
            
            
        },
        handleChange(item) {
            console.log('item='+JSON.stringify(item));
            this.parameter.title = item.label
            this.parameter.newsId = item.value
            this.parameter.url = this.parameter.linkType == 0 ? `news_details.html?id=${this.parameter.newsId}` : this.parameter.url

        },
        getColOptions() {
            let queryNewsRequest = {
                artStatus: 3,
                pageNum: 1,
                pageSize: 100
            };

            API.queryNewsRequestList(queryNewsRequest).then(res => {
                this.colOptions = []
                res.data.list.forEach((tabs)=> {
                    this.colOptions.push({
                        label: tabs.title,
                        value: tabs.id
                    });
                });
                // console.log('this.colOptions='+JSON.stringify(this.colOptions));
            });
            
        },
        ApplyModel(parameter) {
            if (parameter.id) {
                this.parameter = { ...parameter }
                if (this.parameter.linkType == 0) {
                    const array = parameter.url.split('=')
                    this.parameter = {
                        ...parameter,
                        newsId:array[1]
                    }
                    this.newsId = this.parameter.newsId

                }

            } else {
                
                this.parameter = {
                    linkType: 0,
                    status: 0
                }
            }

            this.getColOptions()
        },

        //编辑
        async applyInfoEdit() {

           

            let res = await API.update(this.parameter);

            // this._dialogVisible = false;
            this.$message({
                message: "编辑成功",
                type: "success",
            });
            this.$emit('reloadData')
            this.cancel()

        },
        //新增
        async applyInfoAdd() {

          // 1banner 2 赛事直播  
          this.parameter.bsType = 1
            await API.add(this.parameter);

            //    this._dialogVisible = false;
            this.$message({
                message: "新增成功",
                type: "success",
            });
            this.$emit('reloadData')
            this.cancel()
        },
        //确定
        confirm() {
            console.log('newsId='+this.parameter.newsId);
            

            if (!this.parameter.img) {
                return this.$message({ message: '请上传banner图', type: 'error' })
            }
            if (!this.parameter.num) {
                return this.$message({ message: '请上输入排序', type: 'error' })
            }
            if (!this.parameter.newsId && this.parameter.linkType == 0) {
                return this.$message({ message: '请选择关联新闻', type: 'error' })
            }
            this.parameter.title = this.title
            if (!this.parameter.title && this.parameter.linkType == 1) {
                return this.$message({ message: '请上输入外部链接标题', type: 'error' })
            }
            


            if (this.parameter.id) {
                this.applyInfoEdit()
            } else {
                this.applyInfoAdd()
            }
        },
        //取消
        cancel() {
            this._dialogVisible = false;
            this.parameter = {}
            this.newsId = ''
            this.title = null
        },
    },
    mounted() { },
};
</script>
<style scoped lang="less">
.el-input-number .el-input {
    width: 100%;
}

.el-image {
    margin-right: 10px;
}
</style>