var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sidebar" },
    [
      _c(
        "el-menu",
        {
          staticClass: "sidebar-el-menu",
          attrs: {
            "default-active": _vm.onRoutes,
            collapse: _vm.collapse,
            "background-color": "#324157",
            "text-color": "#bfcbd9",
            "active-text-color": "#20a0ff",
            "unique-opened": "",
            router: "",
          },
        },
        [
          _vm._l(_vm.items, function (item) {
            return [
              item.subs
                ? [
                    _c(
                      "el-submenu",
                      { key: item.index, attrs: { index: item.index } },
                      [
                        _c("template", { slot: "title" }, [
                          _c("i", { class: item.icon }),
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(item.title))]
                          ),
                        ]),
                        _vm._l(item.subs, function (subItem) {
                          return [
                            subItem.subs
                              ? _c(
                                  "el-submenu",
                                  {
                                    key: subItem.index,
                                    attrs: { index: subItem.index },
                                  },
                                  [
                                    _c("template", { slot: "title" }, [
                                      _vm._v(_vm._s(subItem.title)),
                                    ]),
                                    _vm._l(
                                      subItem.subs,
                                      function (threeItem, i) {
                                        return _c(
                                          "el-menu-item",
                                          {
                                            key: i,
                                            attrs: { index: threeItem.user },
                                          },
                                          [_vm._v(_vm._s(threeItem.title))]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _c(
                                  "el-menu-item",
                                  {
                                    key: subItem.index,
                                    attrs: { index: subItem.index },
                                  },
                                  [_vm._v(_vm._s(subItem.title))]
                                ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]
                : [
                    _c(
                      "el-menu-item",
                      { key: item.index, attrs: { index: item.index } },
                      [
                        _c("i", { class: item.icon }),
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v(_vm._s(item.title))]
                        ),
                      ]
                    ),
                  ],
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }