<script src="../../main.js"></script>
<template>
  <div>
    <!-- 页面名称 -->
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item> <i class="el-icon-data-line"></i> 活动管理 </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <!-- 搜索条件 -->
      <div class="handle-box">
        <el-form ref="form" inline>
          <el-form-item>
            <el-button v-if="globalPermission.activity.add" type="primary" icon="el-icon-lx-roundadd"
              class="handle-del mr10" @click="addItem">新增活动
            </el-button>
          </el-form-item>
          <el-form-item label="活动名称：">
            <el-input clearable v-model="pager.matchName" placeholder="活动名称" class="handle-input mr10"></el-input>
          </el-form-item>
          <el-form-item label="状态：">
            <el-select clearable v-model="pager.applyStatus" placeholder="状态" class="handle-input mr10">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="报名时间：">
            <el-date-picker v-model="timeArr" type="datetimerange" range-separator="至" start-placeholder="开始日期"
              class="mr10" clearable end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
          </el-form-item>
        </el-form>
        <!-- <el-button v-if="globalPermission.activity.add" type="primary" icon="el-icon-lx-roundadd" class="handle-del mr10" @click="addItem">新增活动 </el-button>
				<el-input clearable v-model="pager.matchName" placeholder="活动主题" class="handle-input mr10"></el-input>
				<el-select clearable v-model="pager.applyStatus" placeholder="状态" class="handle-input mr10">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
				</el-select>
				<el-date-picker
					v-model="timeArr"
					type="datetimerange"
					range-separator="至"
					start-placeholder="开始日期"
					class="mr10"
					clearable
					end-placeholder="结束日期"
					value-format="yyyy-MM-dd HH:mm:ss"
				>
				</el-date-picker>
				<el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button> -->
      </div>
      <!-- 表格区域 -->
      <el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header">
        <el-table-column prop="colName" align="center" label="序号" width="60">
          <template slot-scope="scope">
            <span>{{ (pager.pageIndex - 1) * pager.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="matchName" align="center" label="活动名称"> </el-table-column>
        <el-table-column prop="colSort" align="center" label="活动图片">
          <template slot-scope="scope">
            <el-image :src="scope.row.activityPicUrl" style="width: 200px; height: 200px"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="sponsor" align="center" label="举办方" width="200">
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.sponsor" :key="index">
              {{ item.sponsor }}
              {{ item.phone }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="colSort" align="center" label="报名时间">
          <template slot-scope="scope">
            <div>{{ scope.row.applyStartTime }}</div>
            <div>至</div>
            <div>{{ scope.row.applyEndTime }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="colSort" align="center" label="报名状态">
          <template slot-scope="scope">
            <div>{{ hdStatus[scope.row.applyStatus] }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="userCount" align="center" label="报名人数"></el-table-column>
        <el-table-column prop="colSort" align="center" label="比赛时间">
          <template slot-scope="scope">
            <div>{{ scope.row.matchStartTime }}</div>
            <div>至</div>
            <div>{{ scope.row.matchEndTime }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="colSort" align="center" label="比赛状态">
          <template slot-scope="scope">
            <div>{{ shStatus[scope.row.matchStatus] }}</div>
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" icon="el-icon-edit" @click="audit(scope.row.id)"
              v-if="globalPermission.activity.edit && (scope.row.matchStatus == 100 || scope.row.matchStatus == 103)">审核
            </el-button>
            <el-button type="text" icon="el-icon-edit" @click="publish(scope.row.id)"
              v-if="globalPermission.activity.edit && scope.row.matchStatus == 101">发布
            </el-button>
            <el-button type="text" icon="el-icon-edit" @click="applicationForm(scope.row.id)"
              v-if="globalPermission.activity.edit && (scope.row.matchStatus == 100 || scope.row.matchStatus == 103)">
              编辑报名表
            </el-button>
            <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.row.id)"
              v-if="globalPermission.activity.edit && (scope.row.matchStatus == 100 || scope.row.matchStatus == 103)">编辑
            </el-button>
            <!-- <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.row.id)">编辑(测试完删除) </el-button> -->
            <el-button type="text" icon="el-icon-edit" @click="handleDetail(scope.row.id)">详情 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <div class="pagination">
        <el-pagination background layout="total, prev, pager, next" :current-page="pager.pageIndex"
          :page-size="pager.pageSize" :total="pager.total" @current-change="handlePageChange"></el-pagination>
      </div>
    </div>
    <DialogAuditDetail :dialogVisible.sync="dialogVisible" />
    <DialogAudit :dialogVisible.sync="dialogVisible2" :auditId.sync="auditId" @refresh="getActivityList" />
  </div>
</template>

<script>
import DialogAuditDetail from './components/DialogAuditDetail'; //审核详情弹框
import DialogAudit from './components/DialogAudit'; //审核弹框
import bus from '@/components/common/bus'; //bus全局通讯
import * as API from '@/api/activity';

export default {
    components: {
        DialogAuditDetail,
        DialogAudit
    },
    name: 'Activity',
    data() {
        return {
            auditId: '',
            timeArr: [],
            shStatus: {
                100: '待审核',
                101: '待发布',
                103: '审核未通过',
                104: '活动未开始',
                105: '进行中',
                106: '已结束',
                107: '报名阶段',
                108: '活动过期'
            },
            hdStatus: {
                201: '报名未开始',
                202: '报名进行中',
                203: '报名结束'
            },
            dialogVisible: false,
            dialogVisible2: false,
            value1: [],
            value: '',
            options: [
                {
                    value: '201',
                    label: '报名未开始'
                },
                {
                    value: '202',
                    label: '报名进行中'
                },
                {
                    value: '203',
                    label: '报名结束'
                }
            ],
            pager: {
                pageIndex: 1,
                pageSize: 10,
                total: 0,
                startTime: '',
                endTime: '',
                matchName: '',
                applyStatus: ''
            },
            globalPermission: this.getPermission(),
            tableData: []
        };
    },
    methods: {
        //删除标签页
        delline() {
            bus.$emit('deleteTag', ['activityEdit', 'groupDetail', 'registrationForm']);
        },
        getPermission() {
            return JSON.parse(sessionStorage.getItem('permission'));
        },
        //编辑报名表
        applicationForm(id) {
            localStorage.setItem('addEdit', JSON.stringify({ name: 'add', state: 'compile' }));
            this.$router.push({
                name: 'registrationForm',
                params: { id }
            });
        },
        //发布
        async publish(id) {
            let parameter = {
                id: id,
                matchStatus: 107
            };
            let res = await API.ApplyActivity(parameter);
            this.$message({
                message: '发布成功',
                type: 'success'
            });
            this.getActivityList();
        },
        //审核
        audit(id) {
            this.dialogVisible2 = true;
            this.auditId = id;
        },

        //删除
        handleDelete() {
            this.$confirm('此操作将永久删除该内容, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '操作成功!'
                });
            });
        },
        //审核详情弹框
        goDetail() {
            this.dialogVisible = true;
        },

        getPermission() {
            return JSON.parse(sessionStorage.getItem('permission'));
        },
        // 搜索
        handleSearch() {
            this.pager.pageIndex = 1;
            this.getActivityList();
        },
        // 多选操作
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },

        // 新增
        addItem() {
            this.delline();
            localStorage.setItem('addEdit', JSON.stringify({ name: 'add', id: '' }));
            this.$router.push({
                name: 'activityEdit',
                params: { name: 'add' }
            });
        },
        // 编辑操作
        handleEdit(id) {
            this.delline();
            localStorage.setItem('addEdit', JSON.stringify({ name: 'edit', id }));
            this.$router.push({
                name: 'activityEdit',
                params: { id, name: 'Edit' }
            });
        },
        // 详情
        handleDetail(id) {
            localStorage.setItem('addEdit', JSON.stringify({ name: 'edit', state: 'detail' }));
            this.$router.push({
                name: 'activityEdit',
                params: { id, name: 'Edit' }
            });
        },
        // 保存编辑
        saveEdit() {},
        // 分页
        handlePageChange(val) {
          console.log('分页')
            this.pager.pageIndex = val;
            this.getActivityList();
        },

        //获取活动列表
        async getActivityList() {
            // if (this.timeArr == null) this.timeArr = [];
            let parameter = {
                pageNum: this.pager.pageIndex,
                pageSize: this.pager.pageSize,
                applyStatus: this.pager.applyStatus,
                startTime: this.timeArr ? this.timeArr[0] : '',
                endTime: this.timeArr ? this.timeArr[1] : '',
                matchName: this.pager.matchName
            };
            let res = await API.getActivityList(parameter);
            this.pager.total = res.data.total;
            this.tableData = res.data.list;
        }
    },
    activated() {
        this.getActivityList();
    },
    mounted() {
        this.getActivityList();
    }
};
</script>

<style scoped>
.detail {
    color: #67c23a;
    cursor: pointer;
    margin-top: 10px;
}
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 130px;
    display: inline-block;
}

.table {
    width: 100%;
    font-size: 14px;
}

.red {
    color: #ff0000;
}

.mr10 {
    margin-right: 10px;
}

.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}

.show {
    display: block;
}

.hide {
    display: none;
}

.checkbox-rows {
    width: 130px;
    margin-top: 10px;
}

.el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0px;
}
</style>
