var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.params.title,
            visible: _vm._dialogVisible,
            "show-close": false,
            "close-on-click-modal": false,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm._dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("el-date-picker", {
                style: { width: "70%" },
                attrs: {
                  type: "datetime",
                  placeholder: "请选择指定时间",
                  clearable: "",
                  "value-format": "yyyy-MM-ddTHH:mm:ss",
                },
                model: {
                  value: _vm.params.addTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.params, "addTime", $$v)
                  },
                  expression: "params.addTime",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.notarize } },
                [_vm._v("确认")]
              ),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }