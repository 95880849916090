var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm._dialogVisible,
            "show-close": false,
            "close-on-click-modal": false,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm._dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "img" },
            [
              _vm.imageUrl
                ? _c("el-image", {
                    attrs: {
                      src: _vm.imageUrl,
                      "preview-src-list": [_vm.imageUrl],
                    },
                  })
                : _c("div", [_vm._v("暂无图片")]),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [_c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")])],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }