<template>
    <div>
        <!-- 筛选 -->
        
        <!-- 表格 -->
        <el-card shadow="always" class="card" v-loading="loading">
            <div>
                <div class="btn">
                    <el-button type="primary" @click="addTotalActivity"
                        >新增</el-button
                    >
                </div>
                <div class="location">
                    总条数:<span>{{ pager.total }}</span>
                </div>
            </div>
            <el-table :data="tableData" border>
                <el-table-column align="center" prop="id" label="ID" />
                <el-table-column align="center" prop="title" label="名称" />
                <el-table-column align="center" prop="img" label="封面图">
                    <template slot-scope="scope">
                        <el-image
                            v-if="scope.row.img"
                            :src="getImg(scope.row.img)"
                            :preview-src-list="[getImg(scope.row.img)]"
                        />
                        <div v-else>暂无图片</div>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="linkType" align="center" label="类型">
                    <template slot-scope="scope">
                        {{ scope.row.linkType == 0 ? "内链" : "外链" }}
                    </template>
                </el-table-column> -->
                <el-table-column align="center" prop="num" label="排序" />
                <el-table-column prop="status" align="center" label="状态">
                    <template slot-scope="scope">
                        {{ scope.row.status == 1 ? "下架" : "上架" }}
                    </template>
                </el-table-column>
                

                <el-table-column align="center" prop="categoryId" label="操作">
                    <template slot-scope="scope">
                
                        <el-button type="text" @click="particularsEdit(scope.row)"
                            >编辑/查看</el-button
                        >
                        <!-- <el-button
                            type="text"
                            @click="publish(scope.row, 0)"
                            v-if="scope.row.status == 1"
                            >上架</el-button
                        >
                        <el-button
                            type="text"
                            @click="publish(scope.row, 1)"
                            style="color: #f56c6c"
                            v-else
                            >下架</el-button
                        > -->

                    </template>
                </el-table-column>
            </el-table>
            <!-- <div class="paging">
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[10, 15, 30, 50, 100]"
                    :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pager.total"
                >
                </el-pagination>
            </div> -->
        </el-card>
        <DialogAddEdit :dialogVisible.sync="dialogVisible" ref="DialogAddEdit" @reloadData="reloadData" />

    </div>
</template>
<script>
import DialogAddEdit from "./components/DialogAddEdit.vue"; //添加/编辑

import * as API from "./api/index";
export default {
    name: "topic",
    components: {
        DialogAddEdit
    },
    data() {
        return {
            parameter: {},
            loading: false,
            dialogVisible: false,
            checkDialogVisible:false,
            tableData: [],
            currentPage: 1,
            pager: {
                pageIndex: 1,
                pageSize: 10,
                total: 0,
            },
        };
    },

    methods: {
        getImg(img) {
            // scope.row.img.includes(',') ? scope.row.img : scope.row.img
            if (img.includes(',')) {
                
                return img.split(',')[0]
            } 
            return img
        },
       //上下架
       async publish(item, val) {
            console.log('item='+JSON.stringify(item)+'---val='+val);
            let params = {...item}
            params.status = val
            await API.modity(params);
            this.reloadData();
            this.$message({
                message: "操作成功",
                type: "success",
            });
        },
        //刷新列表
        reloadData(){
            this.GetTableData()
        },
       //添加活动
        addTotalActivity(){
            this.dialogVisible = true
            this.$refs.DialogAddEdit.ApplyModel({})

        },
       //编辑活动
       particularsEdit(item){
         this.dialogVisible = true
         this.$refs.DialogAddEdit.ApplyModel(item)

      },
      
     

      
        //获取活动分页列表
        async GetTableData() {
            this.loading = true;
            let parameter = Object.assign(
                {},
                {
                    pageNum: this.pager.pageIndex,
                    pageSize: this.pager.pageSize,
                    bsType: 3,//1banner 2 赛事直播 3深度聚焦
                },
                this.parameter
            );
            let res = await API.list(parameter);
            console.log("获取活动分页列表");
            console.log(JSON.parse(JSON.stringify(res)));
            this.tableData = res.data.list;
            this.pager.total = res.data.total;
            this.loading = false;
        },
        handleSizeChange(val) {
            this.pager.pageSize = val;
            this.GetTableData();
        },
        handleCurrentChange(val) {
            this.pager.pageIndex = val;
            this.GetTableData();
        },
    },
    mounted() {
        this.GetTableData();
    },
};
</script>
<style style scoped lang="less">
.card {
    width: 98%;
    margin: 10px auto;
    font-size: 16px;
}
.location {
    float: right;
    margin-bottom: 6px;
    span {
        color: #409eff;
    }
}
.btn {
    float: left;
    margin-bottom: 6px;
}
.paging {
    float: right;
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>