<template>
  <div>
    <el-dialog title="审核" width="500px" :visible.sync="_dialogVisible" :show-close="false" :close-on-click-modal="false">
      <div>
       <el-radio v-model="radio" label="1">通过</el-radio>
  <el-radio v-model="radio" label="2">不通过</el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirm" type="primary">确定</el-button>
        <el-button @click="cancel">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "index",
  data() {
    return {
      radio:"",
      value: "",
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
      ],
    };
  },
  props: {
    dialogVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    _dialogVisible: {
      get() {
        return this.dialogVisible;
      },
      set(val) {
        this.$emit("update:dialogVisible", val);
      },
    },
  },
  methods: {
    //确定
    confirm() {
      this._dialogVisible = false;
    },
    //取消
    cancel() {
   
      this._dialogVisible = false;
    },
  },
  mounted() {},
};
</script>
<style  scoped lang="less">
.el-input-number .el-input {
  width: 100%;
}
</style>