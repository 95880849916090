import request from '../utils/request';

//三级联动地图数据
export const getRegionList = query => {
    return request({
        url: `/manager/sys-region/getRegionList?parentId=${query.parentId}`,
        method: 'get',
        data: query
    });
};

//获取活动列表
export const getActivityList = query => {
    return request({
        url: `/manager/activity/getActivityList`,
        method: 'post',
        data: query
    });
};

//添加或编辑活动详情
export const addActivity = query => {
    return request({
        url: `/manager/activity/addActivity`,
        method: 'post',
        data: query
    });
};

//获取活动类型列表
export const getTypeList = query => {
    return request({
        url: `/manager/activity/getTypeList`,
        method: 'post',
        data: query
    });
};

//审核活动
export const ApplyActivity = query => {
    return request({
        url: `/manager/activity/ApplyActivity`,
        method: 'post',
        data: query
    });
};

//获取活动详情
export const getActivityInfo = query => {
    return request({
        url: `/manager/activity/getActivityInfo?id=${query.id}`,
        method: 'get',
        data: query
    });
};

//报名列表
export const getUserList = query => {
    return request({
        url: `/manager/activity/getUserList`,
        method: 'post',
        data: query
    });
};

//报名人详情
export const getActivityUserInfo = query => {
    return request({
        url: `/manager/activity/getActivityUserInfo?id=${query.id}`,
        method: 'get',
        data: query
    });
};

//报名用户审核
export const ApplyActivityUser = query => {
    return request({
        url: `/manager/activity/ApplyActivityUser`,
        method: 'post',
        data: query
    });
};