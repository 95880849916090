<script src="../../main.js"></script>
<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-people"></i> 赛事预告
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <el-button
                        type="primary"
                        icon="el-icon-lx-roundadd"
                        class="handle-del mr10"
                        @click="addItem"
                        v-if="globalPermission.notice.add"
                >新增赛事预告
                </el-button>
                <el-input v-model="query.title" placeholder="赛事标题" class="handle-input mr10"></el-input>
                <el-input v-model="query.teamName" placeholder="队名" class="handle-input mr10"></el-input>
                <el-select v-model="query.status" placeholder="状态" class="handle-input mr10">
                    <el-option
                            v-for="item in statusOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
                <template>
                    <el-date-picker
                            v-model="timeValue"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :picker-options="pickerOptions"
                            class="mr10">
                    </el-date-picker>
                </template>
                <el-button type="primary" icon="el-icon-search"  @click="handleSearch">搜索</el-button>
            </div>
            <el-table
                    :data="tableData"
                    border
                    class="table"
                    ref="multipleTable"
                    header-cell-class-name="table-header"
            >
                <el-table-column prop="title" label="赛事标题"></el-table-column>
                <el-table-column prop="teamName1" label="A队名称"></el-table-column>
                <el-table-column prop="team1Score" label="A队得分"></el-table-column>
                <el-table-column label="A队队标" align="center">
                    <template slot-scope="scope">
                        <el-button
                                type="text"
                                icon="el-icon-lx-attention"
                                @click="handleImage(scope.row.id,1,scope.row.teamIcon1)"
                        >查看/上传</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="teamName2" label="B队名称"></el-table-column>
                <el-table-column prop="team2Score" label="B队得分"></el-table-column>
                <el-table-column label="B队队标" align="center">
                    <template slot-scope="scope">
                        <el-button
                                type="text"
                                icon="el-icon-lx-attention"
                                @click="handleImage(scope.row.id,2,scope.row.teamIcon2)"
                        >查看/上传</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="状态">
                    <template slot-scope="scope">
                        <el-tag
                                :type="scope.row.status==='0'?'success':(scope.row.status==='1'?'danger':'')"
                        >{{scope.row.status=='0'?"未开赛":"已开赛"}}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="viedoUrl" label="视频地址"></el-table-column>
                <el-table-column prop="startTime" label="开赛时间"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button
                                type="text"
                                icon="el-icon-edit"
                                @click="handleEdit(scope.row.id, scope.row)"
                                v-if="globalPermission.notice.edit"
                        >编辑
                        </el-button>
                        <el-button
                                type="text"
                                icon="el-icon-delete"
                                class="red"
                                @click="handleDelete(scope.row.id, scope.row)"
                                v-if="globalPermission.notice.del"
                        >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                        background
                        layout="total, prev, pager, next"
                        :current-page="query.pageNum"
                        :page-size="query.pageSize"
                        :total="pageTotal"
                        @current-change="handlePageChange"
                ></el-pagination>
            </div>
        </div>

        <el-dialog title="封面图片" :visible.sync="imageVisible" width="40%">
            <el-table
                    :data="picData"
                    border
                    class="table"
                    ref="multipleTable"
                    header-cell-class-name="table-header"
            >
                <el-table-column prop="imageName" label="图片(点击预览大图)" align="center">
                    <template slot-scope="scope">
                        <el-image
                                class="table-td-thumb"
                                :src="picData[0]"
                                :preview-src-list="picData"
                        ></el-image>
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
               <el-upload
                       ref="uploadPicture"
                       class="upload-demo"
                       action="https://api.sctynews.cn/manager/file/uploadTeamIcon"
                       :headers="getToken"
                       :before-upload="beforeUpload"
                       :data="uploadData"
                       :on-success="refreshPic"
                       multiple
               >
                    <el-button size="medium" type="primary">上传队标</el-button>
                    <div slot="tip" class="el-upload__tip">友情提示：图片操作实时更新</div>
                </el-upload>
            </span>
        </el-dialog>

        <!-- 编辑弹出框 -->
        <el-dialog title="编辑" :visible.sync="editVisible" width="30%">
            <el-form ref="form" :model="form" label-width="70px">
                <el-form-item label="赛事名称">
                    <el-input v-model="form.title"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="form.status" placeholder="状态" class="handle-input mr10">
                        <el-option
                                v-for="item in statusOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="视频地址">
                    <el-input v-model="form.viedoUrl"></el-input>
                </el-form-item>
                <el-form-item label="A队名称">
                    <el-input v-model="form.teamName1"></el-input>
                </el-form-item>
                <el-form-item label="B队名称">
                    <el-input v-model="form.teamName2"></el-input>
                </el-form-item>
                <el-form-item label="A队得分" v-if="form.status===1">
                    <el-input v-model="form.team1Score"></el-input>
                </el-form-item>
                <el-form-item label="B队得分" v-if="form.status===1">
                    <el-input v-model="form.team2Score"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="clean">取 消</el-button>
                <el-button type="primary" @click="saveEdit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {getAllLiveNotice, delLiveNotice, saveLiveNotice, editLiveNotice, getLiveDetailNotice} from '../../api/notice';

    var queryRequest = {url: "/manager/live/getLiveNotice"};
    var addRequest = {url: "/manager/live/addliveNotice"};
    var updateRequest = {url: "/manager/live/updateNotice"};
    var delRequest = {url: "/manager/live/delContestNotice"};
    var getLiveRequest ={url:"/manager/live/noticeDetail"};
    export default {
        name: 'usertable',
        data() {
            return {
                query: {
                    title: '',
                    teamName: '',
                    startTime:'',
                    endTime:'',
                    status:'',
                    pageNum: 1,
                    pageSize: 10
                },
                globalPermission: this.getPermission(),
                getToken:{'auth-token':localStorage.getItem('token')},
                uploadData:null,
                tableData: [],
                multipleSelection: [],
                delList: [],
                icon:0,
                editVisible: false,
                editRolesVisible: false,
                formIdShow: false,
                isCurEdit: false,
                pageTotal: 0,
                form: {},
                picData:[],
                imageVisible:false,
                idx: -1,
                id: -1,
                statusOptions: [{
                    value: 0,
                    label: '未开赛'
                }, {
                    value: 1,
                    label: '已开赛'
                }],
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                timeValue:'',
            };
        },
        created() {
            queryRequest.data = this.query;
            this.getData();
        },
        methods: {
            // 获取数据
            getData() {
                //获取栏目列表
                getAllLiveNotice(queryRequest).then(res => {
                    this.tableData = res.data.list;
                    this.pageTotal = res.data.total;
                });
            },
            clean(){
                this.editVisible=false;
                this.handleSearch();
            },
            getPermission() {
                return JSON.parse(sessionStorage.getItem("permission"));
            },
            // 触发搜索按钮
            handleSearch() {
                this.query.startTime=this.timeValue[0];
                this.query.endTime=this.timeValue[1];
                this.$set(this.query, 'pageNum', 1);
                this.getData();
            },
            // 删除操作
            handleDelete(id, row) {
                // 二次确认删除
                this.$confirm('确定要删除吗？', '提示', {
                    type: 'warning'
                }).then(() => {
                    delRequest.id = id;
                    delLiveNotice(delRequest).then(res => {
                        if (res.code == 0) {
                            this.$message.success(`删除成功`);
                            this.handleSearch();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                    //this.tableData.splice(index, 1);
                }).catch(() => {
                });
            },
            beforeUpload (file) {
                this.uploadData = {noticeId:this.id,type:this.icon};
                let promise = new Promise((resolve) => {
                    this.$nextTick(function () {
                        resolve(true);
                    });
                });
                return promise; //通过返回一个promis对象解决
            },
            refreshPic(){
                this.imageVisible = false;
                // getLiveRequest.id =this.id;
                // getLiveDetailNotice(getLiveRequest).then(res=>{
                //     if (this.icon===1){
                //         this.picData[0] = res.data.teamIcon1;
                //     } else{
                //         this.picData[0] = res.data.teamIcon2;
                //     }
                //
                // });
                 this.handleSearch();
            },
            // 新增
            addItem() {
                this.form={};
                this.editVisible = true;
                this.isCurEdit = false;
            },
            // 编辑操作
            handleEdit(id, row) {
                this.id = id;
                this.form = row;
                this.form.id = this.id;
                this.editVisible = true;
                this.isCurEdit = true;
            },
            // 打开图片窗口
            handleImage(id,icon,url) {
                this.icon=icon;
                this.id=id;
                this.picData[0]=url;
                this.imageVisible = true;
            },
            // 保存编辑
            saveEdit() {
                //this.$set(this.tableData, this.idx, this.form);
                if (this.isCurEdit) {
                    updateRequest.data = this.form;
                    editLiveNotice(updateRequest).then(res => {
                        if (res.code == 0) {
                            this.$message.success(`修改成功`);
                            this.editVisible = false;
                            this.handleSearch();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                } else {
                    addRequest.data = this.form;
                    saveLiveNotice(addRequest).then(res => {
                        if (res.code == 0) {
                            this.$message.success(`新增成功`);
                            this.editVisible = false;
                            this.handleSearch();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                }
            },
            // 分页导航
            handlePageChange(val) {
                this.$set(this.query, 'pageNum', val);
                this.getData();
            }
        }
    };
</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 130px;
        display: inline-block;
    }

    .table {
        width: 100%;
        font-size: 14px;
    }

    .red {
        color: #ff0000;
    }

    .mr10 {
        margin-right: 10px;
    }

    .table-td-thumb {
        display: block;
        margin: auto;
        width: 40px;
        height: 40px;
    }

    .show {
        display: block;
    }

    .hide {
        display: none;
    }

    .checkbox-rows {
        width: 130px;
        margin-top: 10px;
    }

    .el-checkbox.is-bordered + .el-checkbox.is-bordered {
        margin-left: 0px;
    }
</style>
