<script src="../../main.js"></script>
<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item> <i class="el-icon-help"></i> 报名管理 </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
           <el-select
    v-model="activityName"
    filterable
    remote
    reserve-keyword
    placeholder="活动名称"
    :remote-method="remoteMethod"
    clearable
    :loading="loading"
    @change="changeActivity"
    >
    <el-option
      v-for="item in activityList"
      :key="item.matchName"
      :label="item.matchName"
      :value="item.matchName">
    </el-option>
  </el-select>
        <!-- <el-input clearable v-model="activityName" class="gap" placeholder="活动名称" style="width: 200px"></el-input> -->
        <el-select clearable v-model="applyStatus" placeholder="报名状态" class="gap" style="width: 200px">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
        <el-select clearable v-model="payStatus" placeholder="审核状态" class="gap" style="width: 200px">
          <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
      </div>

      <!-- 表格 -->
      <el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header">
        <el-table-column prop="colName" align="center" label="序号" width="60">
          <template slot-scope="scope">
            <span>{{ (pager.pageIndex - 1) * pager.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="activityName" align="center" label="活动名称"></el-table-column>
        <el-table-column prop="name" align="center" label="姓名"></el-table-column>
        <el-table-column prop="phone" align="center" label="电话"></el-table-column>
        <el-table-column prop="idCard" align="center" label="证件号"></el-table-column>
        <el-table-column prop="groupName" align="center" label="参赛组别"></el-table-column>
        <el-table-column prop="createdTime" align="center" label="报名时间"></el-table-column>
        <el-table-column prop="startTime" align="center" label="比赛时间"></el-table-column>
        <el-table-column prop="payType" align="center" label="缴费方式">
          <template slot-scope="scope">
            <div>{{ payType[scope.row.payType] }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="price" align="center" label="缴付金额(元)">
          <template slot-scope="scope">
            <div>{{ scope.row.price}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="payStatus" align="center" label="费用状态">
          <template slot-scope="scope">
            <div>{{ approvalStatus[scope.row.payStatus] }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="colName" align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="examine(scope.row.id)">查看 </el-button>
            <el-button type="text" style="color: #f56c6c" v-if="globalPermission.enroll.add"
              @click="cancel(scope.row.id, scope.row)">取消报名 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--  -->
      <!-- 分页器 -->
      <div class="pagination">
        <!-- <el-button type="primary" class="export">导出</el-button> -->
        <form method="get" target="_blank" action="http://139.155.248.30:8001/manager/activity/reportData"
          @submit="exportExcel">
          <input type="hidden" name="activityName" :value="activityName" />
          <button type="submit" class="export-btn">导出</button>
        </form>
        <el-pagination background layout="total, prev, pager, next" :current-page="pager.pageIndex"
          :page-size="pager.pageSize" :total="pager.total" @current-change="handlePageChange"></el-pagination>
      </div>
    </div>
    <DialogAudit :dialogVisible.sync="dialogVisible" :auditId.sync="auditId" @refresh="getUserList" />
  </div>
</template>

<script>
import * as API from '@/api/activity';
import DialogAudit from './components/DialogAudit'; //审核弹框
export default {
    components: {
        DialogAudit
    },
    name: 'usertable',
    data() {
        return {
            activityList:[],
            loading:false,
            globalPermission: this.getPermission(),
            dialogVisible: false,
            auditId: '',
            payType: {
                1: '微信',
                2: '支付宝'
            },
            approvalStatus: {
                100: '已经报名',
                101: '取消报名(待审批)',
                102: '取消报名(已退款)',
                103: '退款失败',
                104: '取消报名(审核不通过)',
                105: '报名未交费'
            },
            activityName: '',
            applyStatus: '',
            payStatus: '',
            pager: {
                pageIndex: 1,
                pageSize: 15,
                total: 0
            },
            tableData: [],
            value1: '',
            options: [
                {
                    value: '202',
                    label: '报名进行中'
                },
                {
                    value: '203',
                    label: '报名结束'
                }
            ],
            options2: [
                {
                    value: '101',
                    label: '取消报名(待审批) '
                },
                {
                    value: '102',
                    label: '已经退款 '
                },
                {
                    value: '103',
                    label: '退款失败'
                },
                {
                    value: '104',
                    label: '取消报名(审核不通过)'
                },
                {
                    value: '105',
                    label: '未交费'
                }
            ],
            value: ''
        };
    },
    methods: {
        //活动下拉框值发生变化
        changeActivity(val){
            this.getUserList()
        },
           //获取活动列表
        async getActivityList(query) {
            let parameter = {
                pageNum: this.pager.pageIndex,
                pageSize: this.pager.pageSize,
                applyStatus: '',
                startTime: '',
                endTime: '',
                matchName: query
            };
            let res = await API.getActivityList(parameter);
            this.activityList = res.data.list;
            this.loading = false;

        },
           //获取活动列表(第一次自动获取第一个活动)
        async getActivityListAutomatic() {
            let parameter = {
                pageNum: this.pager.pageIndex,
                pageSize: this.pager.pageSize,
                applyStatus: '',
                startTime: '',
                endTime: '',
                matchName: "",
            };
            let res = await API.getActivityList(parameter);
            if(res.data.list.length){
                    this.activityName = res.data.list[0].matchName
            }
            this.getUserList();
        },
        //下拉框远程搜索数据
         async  remoteMethod(query) {
        // if (query !== '') {
          this.loading = true;
                  this.getActivityList(query)
        // } else {
        //   this.activityList = [];
        // }
      },
        //导出
        exportExcel(event) {
            if (!this.activityName) {
                this.$message.warning('请输入活动名称后，在导出');
                event.preventDefault();
                return false;
            }
        },
        //取消报名
        cancel(id) {
            this.auditId = id;
            this.dialogVisible = true;
        },
        //查看
        examine(id) {
            this.$router.push({
                name: 'EnrollDetail',
                query: { id }
            });
        },
        
        //搜索
        handleSearch() {
            this.pager.pageIndex = 1;
            this.getUserList();
        },
        //分页
        handlePageChange(val) {
                this.pager.pageIndex = val;
            this.getUserList();
        },
        getPermission() {
            return JSON.parse(sessionStorage.getItem('permission'));
        },
        //报名列表
        async getUserList() {
            let parameter = {
                activityName: this.activityName, //活动名称
                pageNum: this.pager.pageIndex,
                pageSize: this.pager.pageSize,
                applyStatus: this.applyStatus, // 202->>报名进行中 203->>报名结束
                payStatus: this.payStatus //101:取消报名(待审批) 102：已经退款 103:退款失败 104：取消报名(审核不通过) 105：未交费
            };
            let res = await API.getUserList(parameter);
            this.pager.total = res.data.total;
            this.tableData = res.data.list;
        }
    },
    activated() {
        // this.getActivityListAutomatic()
        // this.getUserList();
        console.log('刷新1')
    },
    mounted() {
        this.getActivityListAutomatic()
        // this.getUserList();
        console.log('刷新2')
    }
};
</script>

<style scoped>
.export-btn {
    color: #ffffff;
    background-color: #409eff;
    border: none;
    padding: 9px 15px;
    font-size: 12px;
    border-radius: 3px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    line-height: 1;
}
.pagination {
    display: flex;
    justify-content: space-between;
}
.gap {
    margin-right: 20px;
    margin-bottom: 20px;
}
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 130px;
    display: inline-block;
}

.table {
    width: 100%;
    font-size: 14px;
}

.red {
    color: #ff0000;
}

.mr10 {
    margin-right: 10px;
}

.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}

.show {
    display: block;
}

.hide {
    display: none;
}

.checkbox-rows {
    width: 130px;
    margin-top: 10px;
}

.el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0px;
}
</style>
