<template>
    <div>
        <el-dialog :title="parameter.id ? '编辑直播' : '新增直播'" :visible.sync="_dialogVisible" @close="cancel"
            :close-on-click-modal="false">
            <div>
                <el-form label-width="180px">
                    <el-form-item label="标题：" >
                        <el-input placeholder="请输入" v-model="parameter.title" clearable >
                        </el-input>
                    </el-form-item>
                    <el-form-item label="封面图：" required>
                        <UploadImg v-model="parameter.img"></UploadImg>
                        <div>图片尺寸(529X109)单位：px</div>
                    </el-form-item>
                    <el-form-item label="排序：" required>
                        <el-input-number v-model="parameter.num" />
                        <span>（数字越大越靠前）</span>
                    </el-form-item>
                    <el-form-item label="地址：" required>
                        <el-input placeholder="请输入" v-model="parameter.url" clearable>
                        </el-input>
                    </el-form-item>
                    
                    <!-- 0 上架 1 下架 -->
					<el-form-item label="状态：" required>
						<el-radio v-model="parameter.status" :label="0">上架</el-radio>
						<el-radio v-model="parameter.status" :label="1">下架</el-radio>

					</el-form-item>

                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirm" type="primary">保存</el-button>
                <el-button @click="cancel">取消</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import * as API from "../api/index";
export default {
    name: "index",
    data() {
        return {
            parameter: {
            },


        };
    },
    props: {
        dialogVisible: {
            type: Boolean,
            required: true,
            default: false,
        },

    },
    computed: {
        _dialogVisible: {
            get() {
                return this.dialogVisible;
            },
            set(val) {
                this.$emit("update:dialogVisible", val);
            },
        },
    },
    watch: {},
    methods: {
        
        ApplyModel(parameter) {
            if (parameter.id) {
                this.parameter = { ...parameter }
                if (this.parameter.linkType == 0) {
                    const array = parameter.url.split('=')
                    this.parameter = {
                        ...parameter,
                        newsId:array[1]
                    }

                }

            } else {
                
                this.parameter = {
                    linkType: 0,
                    status: 0
                }
            }

            this.getColOptions()
        },

        //编辑
        async applyInfoEdit() {

           

            let res = await API.update(this.parameter);

            // this._dialogVisible = false;
            this.$message({
                message: "编辑成功",
                type: "success",
            });
            this.$emit('reloadData')
            this.cancel()

        },
        //新增
        async applyInfoAdd() {

          // 1banner 2 赛事直播  
          this.parameter.bsType = 2
            await API.add(this.parameter);

            //    this._dialogVisible = false;
            this.$message({
                message: "新增成功",
                type: "success",
            });
            this.$emit('reloadData')
            this.cancel()
        },
        //确定
        confirm() {
            console.log('newsId='+this.parameter.newsId);
            

            if (!this.parameter.img) {
                return this.$message({ message: '请上传直播封面图', type: 'error' })
            }
            if (!this.parameter.num) {
                return this.$message({ message: '请上输入排序', type: 'error' })
            }
            
            if (!this.parameter.title && this.parameter.linkType == 1) {
                return this.$message({ message: '请上输入标题', type: 'error' })
            }
            


            if (this.parameter.id) {
                this.applyInfoEdit()
            } else {
                this.applyInfoAdd()
            }
        },
        //取消
        cancel() {
            this._dialogVisible = false;
            this.parameter = {}
        },
    },
    mounted() { },
};
</script>
<style scoped lang="less">
.el-input-number .el-input {
    width: 100%;
}

.el-image {
    margin-right: 10px;
}
</style>