var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tinymce-box" },
    [
      _c("editor", {
        attrs: { init: _vm.init, disabled: _vm.disabled, id: "tinymceId" },
        model: {
          value: _vm.myValue,
          callback: function ($$v) {
            _vm.myValue = $$v
          },
          expression: "myValue",
        },
      }),
      _c("VueImageCropper", {
        attrs: {
          dialogVisible: _vm.dialogVisible,
          imageUrl: _vm.cropperImageUrl,
          pictureSize: _vm.pictureSize,
          imageTotalSize: _vm.cropImageTotalSize,
        },
        on: {
          "update:dialogVisible": function ($event) {
            _vm.dialogVisible = $event
          },
          "update:dialog-visible": function ($event) {
            _vm.dialogVisible = $event
          },
          clickSubmit: _vm.clickSubmit,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑图片",
            visible: _vm.picModeDialogVisible,
            "show-close": false,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.picModeDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.picMode == 1,
                  expression: "picMode == 1",
                },
              ],
              staticStyle: { "margin-bottom": "20px" },
            },
            [
              _vm._v(_vm._s(_vm.oldImageTotalSize) + " "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: this.image_size * this.picRadio > 1000,
                      expression: "this.image_size*this.picRadio > 1000",
                    },
                  ],
                  staticStyle: { color: "red" },
                },
                [_vm._v("（图片大小已超过1M）")]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.picMode == 2,
                  expression: "picMode == 2",
                },
              ],
              staticStyle: { "margin-bottom": "20px" },
            },
            [
              _vm._v(_vm._s(_vm.cropOldImageTotalSize) + " "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: this.image_size * 1 > 1000,
                      expression: "this.image_size*1 > 1000",
                    },
                  ],
                  staticStyle: { color: "red" },
                },
                [_vm._v("（图片大小已超过1M）")]
              ),
            ]
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "20px" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.picMode,
                    callback: function ($$v) {
                      _vm.picMode = $$v
                    },
                    expression: "picMode",
                  },
                },
                [_vm._v("原图")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "2" },
                  model: {
                    value: _vm.picMode,
                    callback: function ($$v) {
                      _vm.picMode = $$v
                    },
                    expression: "picMode",
                  },
                },
                [_vm._v("图片裁剪(图片裁剪会等比压缩)")]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.picMode == 1,
                  expression: "picMode == 1",
                },
              ],
            },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.picRadio,
                    callback: function ($$v) {
                      _vm.picRadio = $$v
                    },
                    expression: "picRadio",
                  },
                },
                [_vm._v("不压缩")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "0.245" },
                  model: {
                    value: _vm.picRadio,
                    callback: function ($$v) {
                      _vm.picRadio = $$v
                    },
                    expression: "picRadio",
                  },
                },
                [_vm._v("jpeg压缩(减少75.5%)")]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.picModeCancel } },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.picModeConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }