var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "crumbs" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", [
                _c("i", { staticClass: "el-icon-lx-people" }),
                _vm._v(" 评论管理\n            "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "div",
            { staticClass: "handle-box" },
            [
              _c("el-input", {
                staticClass: "handle-input mr10",
                attrs: { placeholder: "新闻名称" },
                model: {
                  value: _vm.query.articleTitle,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "articleTitle", $$v)
                  },
                  expression: "query.articleTitle",
                },
              }),
              _c("el-input", {
                staticClass: "handle-input mr10",
                attrs: { placeholder: "评论内容" },
                model: {
                  value: _vm.query.commentDesc,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "commentDesc", $$v)
                  },
                  expression: "query.commentDesc",
                },
              }),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.handleSearch },
                },
                [_vm._v("搜索")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticClass: "table",
              attrs: {
                data: _vm.tableData,
                border: "",
                "header-cell-class-name": "table-header",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "cusNikeName", label: "评论人" },
              }),
              _c("el-table-column", {
                attrs: { prop: "cusHeadImg", label: "评论人头像" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: { src: scope.row.cusHeadImg },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "articleTitle", label: "新闻标题" },
              }),
              _c("el-table-column", {
                attrs: { prop: "colSort", label: "评论内容" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "top-start",
                              width: "200",
                              trigger: "hover",
                              content: scope.row.commentDesc,
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [_vm._v("查看评论内容")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "commentStatus", label: "评论状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.commentStatus == 0
                          ? _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v("待审批"),
                            ])
                          : _vm._e(),
                        scope.row.commentStatus == 1
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("审批通过"),
                            ])
                          : _vm._e(),
                        scope.row.commentStatus == 2
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("审批不通过"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "createdTime", label: "评论时间" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.globalPermission.comment.edit
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", icon: "el-icon-edit" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(
                                      scope.row.id,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("审核状态\n                    ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "total, prev, pager, next",
                  "current-page": _vm.query.pageNum,
                  "page-size": _vm.query.pageSize,
                  total: _vm.pageTotal,
                },
                on: { "current-change": _vm.handlePageChange },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "审核状态", visible: _vm.editVisible, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.editVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "70px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "评论内容" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.commentDesc,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "commentDesc", $$v)
                      },
                      expression: "form.commentDesc",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "审批状态" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.commentStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "commentStatus", $$v)
                        },
                        expression: "form.commentStatus",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("待审批"),
                      ]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("通过")]),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("不通过"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "审批意见" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.approveStr,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "approveStr", $$v)
                      },
                      expression: "form.approveStr",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clean } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveEdit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }