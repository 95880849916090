<template>
  <div>
    <el-dialog
    title="编辑图片"
    :visible.sync="_dialogVisible"
    :before-close="close"
    :width="'832px'"
    :close-on-click-modal="false"
    append-to-body
  >
    <div>
      <div style="margin-bottom: 10px;" v-if="isMore2M">当前图片大小：<span style="color: red;">{{currentImageSize}}</span></div>
      <div style="margin-bottom: 10px;" v-else>当前图片大小：<span style="color: black;">{{currentImageSize}}</span></div>
      <!-- <img :src="option.img" alt=""> -->
      <div style="width: 800px;height: 600px;">
        <vueCropper
          ref="cropper"
          :img="option.img "
          :outputSize="option.size"
          :outputType="option.outputType"
          :info="option.info"
          :canScale="option.canScale"
          :autoCrop="option.autoCrop"
          :autoCropWidth="option.autoCropWidth"
          :autoCropHeight="option.autoCropHeight"
          :fixed="option.fixed"
          :fixedNumber="option.fixedNumber"
          :centerBox="option.centerBox"
          :enlarge="1"
          @cropMoving="cropMoving"
          @changeCropNow="changeCropNow"
        ></vueCropper>
      </div>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        @click="close"
        size="small"
      >取 消</el-button>
      <el-button
        type="primary"
        @click="submit"
        size="small"
      >确 定</el-button>
    </div>
  </el-dialog>

  </div>

</template>
<script>

// import { VueCropper } from 'vue-cropper'
import VueCropper from "./vue-cropper/vue-cropper";


export default {
  components: {
    VueCropper
  },
  name: "VueImageCropper",
  model: {
    //这个事件名可以随意写，它实际上是规定了子组件要更新父组件值需要注册的方法
    event: "update",
  },

  props: {
    dialogVisible: {
        type: Boolean,
        required: true,
        default: false,
    },
    imageUrl: {
      type: String,
      default: "",
    },
    pictureSize: {
      type: Object,
      default: {}
    },
    imageTotalSize: {
      type: Number,
      default: 0
    }
  },
  computed: {
      _dialogVisible: {
          get() {
              return this.dialogVisible;
          },
          set(val) {
              this.$emit("update:dialogVisible", val);
          },
      },

  },
  watch: {

    pictureSize: {
      immediate: false,
      handler (val) {
        let radioWH = 0
        let borderW = 0
        let borderH = 0
        const picScaleWH = val.width / val.height
        const borderScaleWH = 800 / 600
        if (val.width < 800 && val.height < 600) {
          borderW = val.width
          borderH = val.height
          console.log('44444');
        }
        else if (picScaleWH > borderScaleWH) {
          borderW = 800
          radioWH = val.width / borderW
          borderH = val.height / radioWH
          console.log('55555');
        } else {
          borderH = 600
          radioWH = val.height / borderH
          borderW = val.width / radioWH
          console.log('66666');
        }
        this.option.autoCropWidth = borderW
        this.option.autoCropHeight = borderH
        this.cropW = borderW
        this.cropH = borderH
        console.log('this.option.autoCropWidth='+borderW);
        console.log('this.option.autoCropHeight='+borderH);
      }
    },
    imageUrl: {
      immediate: false,
      handler (val) {
        console.log('VueImageCropper---this.imageUrl=' + val);
        if (val) {
          //回显数据
          this.option.img = val;

        } else { // 图片上传失败需要提示用户重新上传图片
          // this.$message({
          //       message: "图片获取失败，请稍后重试！",
          //       type: "error",
          //   });

        }
      },
    },
  },

  mounted () {

  },
  data () {
    return {
      // 裁剪组件的基础配置option
      option: {   //配置文件
        img: '',
        info: true,
        size: 1,
        outputType: 'jpeg',
        canScale: false,//图片是否支持滚轮缩放
        autoCrop: true,
        // 只有自动截图开启 宽度高度才生效
        autoCropWidth: 100,
        autoCropHeight: 100,
        // 是否开启截图宽高固定比例
        fixed: false,
        // 真实的输出宽高
        infoTrue: true,
        // 截图框的宽高比
        // fixedNumber: [1, 1],//[16, 9]
        centerBox: true,    // 截图框是否被限制在图片里面
      },
      cropW: 0,
      cropH: 0,
      currentImageSize: '',
      isMore2M: false, // 当前图片是否超过2M
      // 防止重复提交
      // loading: false,
      // pictureSize: {}, // 图片原始尺寸
    };
  },
  methods: {

    changeCropNow(cropSize) {
      console.log('changeCropNow='+cropSize.cropW+'---'+cropSize.cropH)
      const imageSize =  (cropSize.cropW * cropSize.cropH) / (this.cropW * this.cropH) * this.imageTotalSize
      if (imageSize > 1000) {
        if (imageSize > 1000) {
          this.currentImageSize = (imageSize/1000).toFixed(1) + 'MB' + '(图片大小已经超过1MB)'
          this.isMore2M = true
        } else {
          this.currentImageSize = (imageSize/1000).toFixed(1) + 'MB'
          this.isMore2M = false

        }

      } else {
        this.currentImageSize = (imageSize*1).toFixed(1) + 'KB'
        this.isMore2M = false

      }
    },
   // 截图框移动回调函数
   cropMoving(data) {
      // 截图框的左上角 x，y和右下角坐标x，y
      let cropAxis = [data.axis.x1, data.axis.y1, data.axis.x2, data.axis.y2]
      console.log('cropMoving='+cropAxis)
      const imageSize =   this.imageTotalSize
      if (imageSize > 1000) {
        if (imageSize > 1000) {
          this.currentImageSize = (imageSize/1000).toFixed(1) + 'MB' + '(图片大小已经超过1MB)'
          this.isMore2M = true
        } else {
          this.currentImageSize = (imageSize/1000).toFixed(1) + 'MB'
          this.isMore2M = false

        }

      } else {
        this.currentImageSize = (imageSize*1).toFixed(1) + 'KB'
        this.isMore2M = false

      }
    },

    submit () {
      console.log('cropW=' + JSON.stringify(this.$refs.cropper.cropW));
      console.log('cropH=' + JSON.stringify(this.$refs.cropper.cropH));
      console.log('getImgAxis=' + JSON.stringify(this.$refs.cropper.getImgAxis()));
      console.log('getCropAxis=' + JSON.stringify(this.$refs.cropper.getCropAxis()));

      let radioWH = 0
      let borderW = 0
      let borderH = 0
      const picScaleWH = this.pictureSize.width / this.pictureSize.height
      const borderScaleWH = 800 / 600
      if (this.pictureSize.width < 800 && this.pictureSize.height < 600) {
        borderW = this.pictureSize.width
        borderH = this.pictureSize.height
        console.log('111111');
      } else if (picScaleWH > borderScaleWH) {
        borderW = 800
        radioWH = this.pictureSize.width / borderW
        borderH = this.pictureSize.height / radioWH
        console.log('2222222');
      } else {
        borderH = 600
        radioWH = this.pictureSize.height / borderH
        borderW = this.pictureSize.width / radioWH
        console.log('33333333');
      }


      const coeW = this.pictureSize.width / borderW
      const coeH = this.pictureSize.height / borderH
      const imgAxis = this.$refs.cropper.getImgAxis()
      const cropAxis = this.$refs.cropper.getCropAxis()
      const cropW = this.$refs.cropper.cropW * coeW
      const cropH = this.$refs.cropper.cropH * coeH
      let x = (cropAxis.x1 - imgAxis.x1) * coeW
      let y = (cropAxis.y1 - imgAxis.y1) * coeH
      if (x < 0) {
        x = 0
      }
      if (y < 0) {
        y = 0
      }
      console.log('x=' + x + '---y=' + y);
      const imageUrl = `${this.imageUrl}?imageMogr2/cut/${cropW.toFixed(1)}x${cropH.toFixed(1)}x${x.toFixed(1)}x${y.toFixed(1)}`
      console.log('imageUrl=' + imageUrl);
      console.log('vuecropper--this.imageTotalSize=' + this.imageTotalSize );

      this.$emit("clickSubmit", imageUrl);
        this._dialogVisible = false;
        this.option.img = ''
      // 判断截图图片是否大于2M
      // if ((cropW * cropH) / (this.pictureSize.width * this.pictureSize.height) * this.imageTotalSize < 1000) {
      //   // this.$confirm('当前图片大小已经超过1MB, 是否继续?', '提示', {
      //   //         confirmButtonText: '确定',
      //   //         cancelButtonText: '取消',
      //   //         type: 'warning'
      //   //     }).then(() => {
      //   //       this.$emit("clickSubmit", imageUrl);
      //   //       this._dialogVisible = false;
      //   //       this.option.img = ''
      //   //     });


      // }else {
      //   this.$emit("clickSubmit", imageUrl);
      //   this._dialogVisible = false;
      //   this.option.img = ''
      // }

    },
    /**
     * 关闭弹窗
     * **/
    close () {
      this.option.img = ''
      this._dialogVisible = false;
      this.$emit("clickSubmit", '');

    },

  },
};
</script>

<style lang="less" scoped>
 .el-dialog__wrapper {
    z-index: 6000 !important;
  }

</style>