<template>
	<div>
		<!-- 审核详情弹框 -->
		<el-dialog title="审核详情" :visible.sync="_dialogVisible" :show-close="false" :close-on-click-modal="false" width="600px">
			<div>
				<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header">
					<el-table-column prop="positionName" align="center" label="审核时间"> </el-table-column>
					<el-table-column prop="positionName" align="center" label="审核人员"></el-table-column>
					<el-table-column prop="positionName" align="center" label="审核结果"></el-table-column>
					<el-table-column prop="positionName" align="center" label="反馈详情"></el-table-column>
				</el-table>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="_dialogVisible = false">关闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
export default {
	name: 'index',
	data() {
		return {
			tableData: [
				{
					positionName: '审核通过'
				},
				{
					positionName: '审核通过'
				},
				{
					positionName: '审核通过'
				},
			]
		};
	},
	props: {
		dialogVisible: {
			type: Boolean,
			required: true,
			default: false
		}
	},
	computed: {
		_dialogVisible: {
			get() {
				return this.dialogVisible;
			},
			set(val) {
				this.$emit('update:dialogVisible', val);
			}
		}
	},
	methods: {},
	mounted() {}
};
</script>
<style  scoped lang="less">
.addIcon {
	margin-bottom: 18px;
	margin-left: 20px;
	font-size: 22px;
	cursor: pointer;
}
</style>