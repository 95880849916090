var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c(
          "div",
          { staticClass: "title", staticStyle: { "margin-bottom": "20px" } },
          [_vm._v("活动名称:" + _vm._s(_vm.activityName))]
        ),
        _c(
          "el-form",
          {
            ref: "ruleForm",
            staticClass: "demo-ruleForm",
            attrs: { "label-width": "130px", inline: true },
          },
          [
            _c("div", { staticClass: "title" }, [_vm._v("个人信息")]),
            _c("el-form-item", { attrs: { label: "姓名：" } }, [
              _c("div", [_vm._v(_vm._s(_vm.user.name))]),
            ]),
            _c("el-form-item", { attrs: { label: "性别：" } }, [
              _c("div", [_vm._v(_vm._s(_vm.user.gender == 0 ? "女" : "男"))]),
            ]),
            _c("el-form-item", { attrs: { label: "出生日期：" } }, [
              _c("div", [_vm._v(_vm._s(_vm.user.birthday))]),
            ]),
            _c("el-form-item", { attrs: { label: "手机号：" } }, [
              _c("div", [_vm._v(_vm._s(_vm.user.phone))]),
            ]),
            _c("el-form-item", { attrs: { label: "证件号：" } }, [
              _c("div", [_vm._v(_vm._s(_vm.user.idCard))]),
            ]),
            _c("el-form-item", { attrs: { label: "政治面貌：" } }, [
              _c("div", [_vm._v(_vm._s(_vm.user.politicsStatus))]),
            ]),
            _c("el-form-item", { attrs: { label: "民族：" } }, [
              _c("div", [_vm._v(_vm._s(_vm.user.nation))]),
            ]),
            _c("el-form-item", { attrs: { label: "是否体育从事者：" } }, [
              _c("div", [
                _vm._v(
                  _vm._s(_vm.user.sportsPractitioner == 0 ? "不是" : "是")
                ),
              ]),
            ]),
            _c("el-form-item", { attrs: { label: "从事职业：" } }, [
              _c("div", [_vm._v(_vm._s(_vm.user.occupation))]),
            ]),
            _c("el-form-item", { attrs: { label: "所在地址：" } }, [
              _c("div", [_vm._v(_vm._s(_vm.user.address))]),
            ]),
            _c("el-form-item", { attrs: { label: "详细地址：" } }, [
              _c("div", [_vm._v(_vm._s(_vm.user.addressDetails))]),
            ]),
            _c("el-form-item", { attrs: { label: "紧急联系人：" } }, [
              _c("div", [_vm._v(_vm._s(_vm.user.emergencyContactName))]),
            ]),
            _c("el-form-item", { attrs: { label: "紧急联系电话：" } }, [
              _c("div", [_vm._v(_vm._s(_vm.user.emergencyContactPhone))]),
            ]),
            _c("el-form-item", { attrs: { label: "与紧急人关系：" } }, [
              _c("div", [_vm._v(_vm._s(_vm.user.emergencyContactRelation))]),
            ]),
            _c("div", [_c("el-tag", [_vm._v("个人自定义字段")])], 1),
            _vm._l(_vm.user.items, function (item, index) {
              return _c(
                "el-form-item",
                {
                  key: index + "1",
                  attrs: { label: item.key + "：", required: item.required },
                },
                [
                  item.type == "uploadImg"
                    ? _c("el-image", {
                        staticStyle: { width: "200px", height: "200px" },
                        attrs: { src: item.value[0] },
                      })
                    : _c("div", [_vm._v(_vm._s(item.value[0]))]),
                ],
                1
              )
            }),
            _c("div", { staticClass: "title" }, [_vm._v("组别信息")]),
            _c("el-form-item", { attrs: { label: "组别名称：" } }, [
              _c("div", [_vm._v(_vm._s(_vm.group.groupName))]),
            ]),
            _c("el-form-item", { attrs: { label: "参赛价格：" } }, [
              _c("div", [_vm._v(_vm._s(_vm.group.price))]),
            ]),
            _c("el-form-item", { attrs: { label: "参赛人数：" } }, [
              _c("div", [_vm._v(_vm._s(_vm.group.stock))]),
            ]),
            _c("el-form-item", { attrs: { label: "参赛年龄：" } }, [
              _c("div", [
                _vm._v(
                  _vm._s(_vm.group.minAge) + " 至 " + _vm._s(_vm.group.maxAge)
                ),
              ]),
            ]),
            _c("el-form-item", { attrs: { label: "参赛性别：" } }, [
              _c("div", [_vm._v(_vm._s(_vm.gender[_vm.group.gender]))]),
            ]),
            _c("el-form-item", { attrs: { label: "组别类型：" } }, [
              _c("div", [_vm._v(_vm._s(_vm.group.groupType))]),
            ]),
            _c("el-form-item", { attrs: { label: "组别比赛地址：" } }, [
              _c("div", [_vm._v(_vm._s(_vm.group.address))]),
            ]),
            _c("el-form-item", { attrs: { label: "详细地址：" } }, [
              _c("div", [_vm._v(_vm._s(_vm.group.addressDetail))]),
            ]),
            _c("el-form-item", { attrs: { label: "比赛时间：" } }, [
              _c("div", [
                _vm._v(
                  _vm._s(_vm.group.matchStartTime) +
                    " 至 " +
                    _vm._s(_vm.group.matchEndTime)
                ),
              ]),
            ]),
            _c("el-form-item", { attrs: { label: "线上缴付：" } }, [
              _c("div", [
                _vm._v(
                  _vm._s(_vm.group.payType == 0 ? "线上支付" : "线下支付")
                ),
              ]),
            ]),
            _c("div", [_c("el-tag", [_vm._v("组别自定义字段")])], 1),
            _vm._l(_vm.group.customItems, function (item, index) {
              return _c(
                "el-form-item",
                {
                  key: index + "1",
                  attrs: { label: item.key + "：", required: item.required },
                },
                [
                  item.type == "uploadImg"
                    ? _c("el-image", {
                        staticStyle: { width: "200px", height: "200px" },
                        attrs: { src: item.value[0] },
                      })
                    : _c("div", [_vm._v(_vm._s(item.value[0]))]),
                ],
                1
              )
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }