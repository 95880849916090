<template>
    <div class="header">
        <!-- 折叠按钮 -->
        <div class="collapse-btn" @click="collapseChage">
            <i v-if="!collapse" class="el-icon-s-fold"></i>
            <i v-else class="el-icon-s-unfold"></i>
        </div>
        <div class="logo">川体管理系统</div>
        <div class="header-right">
            <div class="header-user-con">
                <!-- 全屏显示 -->
                <div class="btn-fullscreen" @click="handleFullScreen">
                    <el-tooltip effect="dark" :content="fullscreen?`取消全屏`:`全屏`" placement="bottom">
                        <i class="el-icon-rank"></i>
                    </el-tooltip>
                </div>
                <!-- 用户头像 -->
                <div class="user-avator">
                    <avatar :size="40" :username="name"  color="rgb(255, 208, 75)"></avatar>
                </div>
                <!-- 用户名下拉菜单 -->
                <el-dropdown class="user-name" trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                        {{name}}
                        <i class="el-icon-caret-bottom"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item divided command="password">修改密码</el-dropdown-item>
                        <el-dropdown-item divided command="loginout">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <el-dialog title="修改密码" :visible.sync="updatePasswordVisible" width="40%">
            <el-form ref="updatePasswordForm" :model="updatePasswordForm" :rules="updatePasswordRules"
                     label-width="100px">
                <el-form-item label="旧密码" prop="oldPassword">
                    <el-input v-model="updatePasswordForm.oldPassword" id="oldPassword"></el-input>
                </el-form-item>
                <el-form-item label="确认旧密码" prop="oldPasswordConfirm">
                    <el-input v-model="updatePasswordForm.oldPasswordConfirm" id="oldPasswordConfirm"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="newPassword">
                    <el-input v-model="updatePasswordForm.newPassword" id="newPassword"></el-input>
                </el-form-item>
                <el-form-item>
                        <div class="intensity">
                            <span class="psdText">密码强度</span>
                            <span
                                class="line"
                                :class="[level.includes('low') ? 'low' : '']"
                            ></span>
                            <span
                                class="line"
                                :class="[level.includes('middle') ? 'middle' : '']"
                            ></span>
                            <span
                                class="line"
                                :class="[level.includes('high') ? 'high' : '']"
                            ></span>
                            <div class="warningtext">
                                密码应由8-16位数字、大小写字母、特殊符号组成,请不要使用容易被猜到的密码
                            </div>
                        </div>
                    </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="updatePasswordVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitUpdatePassword('updatePasswordForm')">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
    import { validPassword } from '../../utils/validate'
    import bus from '../common/bus';
    import {fetchDataPut} from '../../api/user';
    import Avatar from 'vue-avatar'
    export default {
        data() {
            var validateSamePass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.updatePasswordForm.oldPassword) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            var validateNewPass = (rule, value, callback) => {
                if (!validPassword(value)) {
                    callback(new Error('请输入8到16位的数字+大小写字母+特殊字符'));
                }  else {
                    callback();
                }
                this.checkPassword(value)

            };
            return {
                level: [],
                collapse: false,
                fullscreen: false,
                name: localStorage.getItem("ms_username"),
                message: 0,
                timerId:1, // 模拟计时器id，唯一性
                timerObj :{}, // 计时器存储器
                updatePasswordVisible: false,
                updatePasswordForm: {},
                updatePasswordRules: {
                    oldPassword: [{required: true, message: '请输入旧密码', trigger: 'blur'},
                        {min: 6, max: 18, message: '长度在 6 到 18 个字符', trigger: 'blur'}
                    ],
                    newPassword: [{required: true, message: '请确认新密码', trigger: 'blur'},
                        {min: 8, max: 18 , validator: validateNewPass,trigger: 'change'}
                    ],
                    oldPasswordConfirm: [
                        {validator: validateSamePass, trigger: 'blur'}
                    ]
                }
            };
        },
        computed: {
        },
        components: {
            Avatar
        },
        created() {
            this.getData();
        },
        methods: {
            // 检测密码强度
                // 校验密码
            checkPassword(value) {
            this.level = []
            
            // 校验是数字
            const regex1 = /^\d+$/
            // 校验字母
            const regex2 = /^[A-Za-z]+$/
            // 校验符号
            const regex3 =
                /^[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~!@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]+$/
            
                if (regex1.test(value)) {
                this.level.push('low')
            } else if (regex2.test(value)) {
                this.level.push('low')
            } else if (regex3.test(value)) {
                this.level.push('low')
            } else if (/^[A-Za-z\d]+$/.test(value)) {
                this.level.push('low')
                this.level.push('middle')
            } else if (
                /^[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、\d]+$/.test(
                value
                )
            ) {
                this.level.push('low')
                this.level.push('middle')
            } else if (
                /^[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、A-Za-z]+$/.test(
                value
                )
            ) {
                this.level.push('low')
                this.level.push('middle')
            } else if (
                /^[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、A-Za-z\d]+$/.test(
                value
                )
            ) {
                this.level.push('low')
                this.level.push('middle')
                this.level.push('high')
            }
            
            },
            getData(){

            },
            // 用户名下拉菜单选择事件
            handleCommand(command) {
                if (command == 'loginout') {
                    localStorage.removeItem('ms_username');
                    localStorage.removeItem('token');
                    localStorage.removeItem('userPermissions');
                    this.$router.push('/login');
                }
                if (command == 'password') {
                    //修改密码
                    this.updatePasswordVisible = true
                }
            },
            // 侧边栏折叠
            collapseChage() {
                this.collapse = !this.collapse;
                bus.$emit('collapse', this.collapse);
            },
            // 全屏事件
            handleFullScreen() {
                let element = document.documentElement;
                if (this.fullscreen) {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    }
                } else {
                    if (element.requestFullscreen) {
                        element.requestFullscreen();
                    } else if (element.webkitRequestFullScreen) {
                        element.webkitRequestFullScreen();
                    } else if (element.mozRequestFullScreen) {
                        element.mozRequestFullScreen();
                    } else if (element.msRequestFullscreen) {
                        // IE11
                        element.msRequestFullscreen();
                    }
                }
                this.fullscreen = !this.fullscreen;
            },
            submitUpdatePassword(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        fetchDataPut({
                            url: "/usercenter/user/change-password",
                            data: this.updatePasswordForm
                        }).then(res => {
                            if (res.code == 0) {
                                this.$message.success('修改成功');
                                this.updatePasswordVisible = false;
                            } else {
                                this.$message.error(res.message);
                            }
                        });
                    } else {
                        this.$message.error(`输入错误，请检查后再提交`);
                        return false;
                    }
                });
            }
        },
        mounted() {
            if (document.body.clientWidth < 1500) {
                this.collapseChage();
            }
        }
    };
</script>
<style scoped>
    .header {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 70px;
        font-size: 22px;
        color: #fff;
    }

    .collapse-btn {
        float: left;
        padding: 0 21px;
        cursor: pointer;
        line-height: 70px;
    }

    .header .logo {
        float: left;
        width: 250px;
        line-height: 70px;
    }

    .header-right {
        float: right;
        padding-right: 50px;
    }

    .header-user-con {
        display: flex;
        height: 70px;
        align-items: center;
    }

    .btn-fullscreen {
        transform: rotate(45deg);
        margin-right: 5px;
        font-size: 24px;
    }

    .btn-bell,
    .btn-fullscreen {
        position: relative;
        width: 30px;
        height: 30px;
        text-align: center;
        border-radius: 15px;
        cursor: pointer;
    }

    .btn-bell-badge {
        position: absolute;
        right: 0;
        top: -2px;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background: #f56c6c;
        color: #fff;
    }

    .btn-bell .el-icon-bell {
        color: #fff;
    }

    .user-name {
        margin-left: 10px;
    }

    .user-avator {
        margin-left: 20px;
    }

    .user-avator img {
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    .el-dropdown-link {
        color: #fff;
        cursor: pointer;
    }

    .el-dropdown-menu__item {
        text-align: center;
    }

    
    .psdText {
        font-size: 14px;
        margin-right: 10px;
    }
    .line {
        display: inline-block;
        width: 48px;
        height: 4px;
        background: #d8d8d8;
        border-radius: 3px;
        margin-right: 8px;
       
    }
    .low {
        background: #f4664a;
    }
    .middle {
       background: #ffb700;
    }
    .high {
        background: #2cbb79;
    }
    .level {
        margin: 0 16px 0 8px;
    }
    .warningtext {
        color: #5a5a5a;
        font-size: 12px;
        margin-top: 5px;
    }
    
</style>
