var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "parcel" },
    [
      _c(
        "el-upload",
        {
          staticClass: "avatar-uploader parentLevel",
          attrs: {
            "before-upload": _vm.beforeAvatarUpload,
            drag: "",
            action: _vm.actionUrl,
            headers: _vm.getToken,
            "on-success": _vm.refreshPic,
            "show-file-list": false,
            multiple: "",
          },
        },
        [_c("i", { staticClass: "el-icon-plus avatar-uploader-icon" })]
      ),
      _vm._l(_vm.slidesList, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "parentLevel" },
          [
            _c("el-image", {
              staticClass: "eleImg",
              attrs: { src: item, "preview-src-list": _vm.slidesList },
              on: {
                mouseenter: function ($event) {
                  return _vm.enter(index)
                },
                error: function ($event) {
                  return _vm.errorFun(index)
                },
              },
            }),
            _vm.maskLayer == index
              ? _c(
                  "div",
                  {
                    staticClass: "shade",
                    on: {
                      mouseleave: function ($event) {
                        return _vm.leave(index)
                      },
                    },
                  },
                  [
                    _vm.slidesList.length > 1
                      ? _c("i", {
                          staticClass: "el-icon-upload2 IconStyle",
                          attrs: { title: "置顶" },
                          on: {
                            click: function ($event) {
                              return _vm.stick(index)
                            },
                          },
                        })
                      : _vm._e(),
                    _c("i", {
                      staticClass: "el-icon-zoom-in IconStyle",
                      attrs: { title: "预览" },
                      on: {
                        click: function ($event) {
                          return _vm.preview(index)
                        },
                      },
                    }),
                    _c("i", {
                      staticClass: "el-icon-delete IconStyle",
                      attrs: { title: "删除" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteIn(index)
                        },
                      },
                    }),
                  ]
                )
              : _vm._e(),
          ],
          1
        )
      }),
      _vm.showViewer
        ? _c("el-image-viewer", {
            attrs: {
              "on-close": _vm.closeViewer,
              "url-list": _vm.slidesList,
              "initial-index": _vm.imageIndex,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }