<template>
    <div>
        <el-dialog :title="parameter.id ? '编辑图片' : '新增图片'" :visible.sync="_dialogVisible" @close="cancel"
            :close-on-click-modal="false">
            <div>
                <el-form label-width="180px">
                    <!-- <el-form-item label="标题：" >
                        <el-input placeholder="请输入" v-model="parameter.title" clearable >
                        </el-input>
                    </el-form-item> -->
                    <el-form-item label="图片：" required>
                        <UploadImg v-model="parameter.url" :max="100" ></UploadImg>
                        <!-- <div>图片尺寸(336X155)单位：px</div> -->
                    </el-form-item>
                    <!-- <el-form-item label="排序：" required>
                        <el-input-number v-model="parameter.num" />
                        <span>（数字越大越靠前）</span>
                    </el-form-item> -->
                    <!-- 0 上架 1 下架 -->
					<!-- <el-form-item label="状态：" required>
						<el-radio v-model="parameter.status" :label="0">上架</el-radio>
						<el-radio v-model="parameter.status" :label="1">下架</el-radio>

					</el-form-item> -->

                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirm" type="primary">保存</el-button>
                <el-button @click="cancel">取消</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import * as API from "../api/index";
export default {
    name: "index",
    data() {
        return {
            parameter: {
            },

        };
    },
    props: {
        dialogVisible: {
            type: Boolean,
            required: true,
            default: false,
        },

    },
    computed: {
        _dialogVisible: {
            get() {
                return this.dialogVisible;
            },
            set(val) {
                this.$emit("update:dialogVisible", val);
            },
        },
    },
   
    methods: {
       
        
        ApplyModel(parameter) {
            this.parameter = { ...parameter } 

        },

        //编辑
        async applyInfoEdit() {

           

            let res = await API.updatePhotos(this.parameter);

            // this._dialogVisible = false;
            this.$message({
                message: "编辑成功",
                type: "success",
            });
            this.$emit('reloadData')
            this.cancel()

        },
        //新增
        async applyInfoAdd() {
            // let params = [{activityId:8,url:'https://ctyly-1307432455.cos.ap-chengdu.myqcloud.com/upload/202408051048498KmN.jpg?imageMogr2/format/jpeg'},{activityId:8,url:'https://ctyly-1307432455.cos.ap-chengdu.myqcloud.com/upload/202408051048498KmN.jpg?imageMogr2/format/jpeg'}]
            let urlArray = []
            this.parameter.url.forEach(item => {
                urlArray.push({activityId:this.parameter.activityId,url:item})
            });
            await API.createPhotos(urlArray);

            this.$message({
                message: "新增成功",
                type: "success",
            });
            this.$emit('reloadData')
            this.cancel()
        },
        //确定
        confirm() {
            console.log('this.parameter.url='+JSON.stringify(this.parameter.url));

            if (!this.parameter.url) {
                return this.$message({ message: '请上传图片', type: 'error' })
            }
           
          


            if (this.parameter.id) {
                this.applyInfoEdit()
            } else {
                this.applyInfoAdd()
            }
        },
        //取消
        cancel() {
            this._dialogVisible = false;
            this.parameter = {}
           
        },
    },
    mounted() { },
};
</script>
<style scoped lang="less">
.el-input-number .el-input {
    width: 100%;
}

.el-image {
    margin-right: 10px;
}
</style>