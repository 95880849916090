var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "crumbs" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", [
                _c("i", { staticClass: "el-icon-lx-service" }),
                _vm._v(" 权限管理\n            "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "div",
            { staticClass: "handle-box" },
            [
              _c(
                "el-button",
                {
                  staticClass: "handle-del mr10",
                  attrs: { type: "primary", icon: "el-icon-lx-roundadd" },
                  on: { click: _vm.addRole },
                },
                [_vm._v("新增角色")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticClass: "table",
              attrs: {
                data: _vm.tableData,
                border: "",
                "header-cell-class-name": "table-header",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "角色编号", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { prop: "roleName", label: "角色名", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "updatedTime",
                  label: "修改时间",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", icon: "el-icon-edit" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(scope.row.id, scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", icon: "el-icon-lx-profile" },
                            on: {
                              click: function ($event) {
                                return _vm.handlePer(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("设置权限")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "red",
                            attrs: { type: "text", icon: "el-icon-delete" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "total, prev, pager, next",
                  "current-page": _vm.query.pageIndex,
                  "page-size": _vm.query.pageSize,
                  total: _vm.pageTotal,
                },
                on: { "current-change": _vm.handlePageChange },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "新增角色", visible: _vm.addVisible, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.addVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "eform", attrs: { model: _vm.form, "label-width": "70px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "角色名" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.roleName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "roleName", $$v)
                      },
                      expression: "form.roleName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.addVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveAdd } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑角色", visible: _vm.editVisible, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.editVisible = $event
            },
            close: _vm.clean,
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "70px" } },
            [
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false",
                    },
                  ],
                  attrs: { label: "编号" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.id,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "id", $$v)
                      },
                      expression: "form.id",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "角色名" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.roleName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "roleName", $$v)
                      },
                      expression: "form.roleName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clean } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveEdit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "权限设置", visible: _vm.perVisible, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.perVisible = $event
            },
          },
        },
        [
          _c("el-tree", {
            ref: "tree",
            attrs: {
              data: _vm.treeData,
              "show-checkbox": "",
              "node-key": "id",
              "highlight-current": "",
              "check-strictly": "",
              props: _vm.defaultProps,
            },
            on: { check: _vm.treeCk },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.perVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.savePer } },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }