import axios from 'axios';
import router from "../router";
import Vue from 'vue';
const Busvue=new Vue();
const service = axios.create({
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    baseURL: process.env.VUE_APP_ApiUrlLog,
    //headers: {'AUTH-TOKEN': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE1NzUzOTAxNDAsInVzZXJuYW1lIjoiYWRtaW4ifQ.Hl7XnUWPHbw1KwpLKb_bEL-VNlWow09ulNRTQ6lvBaA'},
    timeout: 5000
});

service.interceptors.request.use(
    config => {
        if (localStorage.getItem('token')!=null){
            config.headers.common['auth-token'] = localStorage.getItem('token');
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            if (response.data.code === 100403){
                //登录token过期，认证失败
                Busvue.$message({
                    message: '登录超时!请重新登陆!',
                    type: 'error'
                });
                router.replace({
                    path: '/login',
                    query: { redirect: router.currentRoute.fullPath }
                });
            } else{
                return response.data;
            }
        } else {
            return Promise.reject();
        }
    },
    error => {
        return Promise.reject();
    }
);

export default service;
