import request from '../utils/request';

export const getAllTabs = query => {
    return request({
        url: query.url,
        method: 'post',
        data: query.data
    });
};

export const delTabs = query => {
    query.url ="/manager/column/delColumn?id="+ query.id;
    return request({
        url: query.url,
        method: 'get',
        params: query.data
    });
};

export const saveTabs = query => {
    return request({
        url: query.url,
        method: 'post',
        data: query.data
    });
};

export const editTabs = query => {
    return request({
        url: query.url,
        method: 'post',
        data: query.data
    });
};
