var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "crumbs" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", [
                _c("i", { staticClass: "el-icon-lx-people" }),
                _vm._v(" 新闻管理\n                "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "div",
            { staticClass: "handle-box" },
            [
              _vm.globalPermission.news.add
                ? _c(
                    "el-button",
                    {
                      staticClass: "handle-del mr10",
                      attrs: { type: "primary", icon: "el-icon-lx-roundadd" },
                      on: { click: _vm.addItem },
                    },
                    [_vm._v("新增\n                ")]
                  )
                : _vm._e(),
              _c("el-input", {
                staticClass: "handle-input mr10",
                attrs: { placeholder: "新闻名" },
                model: {
                  value: _vm.query.titleDesc,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "titleDesc", $$v)
                  },
                  expression: "query.titleDesc",
                },
              }),
              _c(
                "el-select",
                {
                  staticClass: "handle-input mr10",
                  attrs: { placeholder: "状态" },
                  model: {
                    value: _vm.query.artStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "artStatus", $$v)
                    },
                    expression: "query.artStatus",
                  },
                },
                _vm._l(_vm.statusOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _c(
                "el-select",
                {
                  staticClass: "handle-input mr10",
                  attrs: { placeholder: "栏目" },
                  model: {
                    value: _vm.query.colId,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "colId", $$v)
                    },
                    expression: "query.colId",
                  },
                },
                _vm._l(_vm.colOptions, function (item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { label: item.label, value: item.key },
                  })
                }),
                1
              ),
              [
                _c("el-date-picker", {
                  staticClass: "mr10",
                  attrs: {
                    type: "daterange",
                    align: "right",
                    "unlink-panels": "",
                    "range-separator": "至",
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期",
                    "value-format": "yyyy-MM-dd HH:mm:ss",
                    "picker-options": _vm.pickerOptions,
                  },
                  model: {
                    value: _vm.timeValue,
                    callback: function ($$v) {
                      _vm.timeValue = $$v
                    },
                    expression: "timeValue",
                  },
                }),
              ],
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.handleSearch },
                },
                [_vm._v("搜索")]
              ),
            ],
            2
          ),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticClass: "table",
              attrs: {
                data: _vm.tableData,
                border: "",
                "header-cell-class-name": "table-header",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", { attrs: { prop: "id", label: "id" } }),
              _c("el-table-column", {
                attrs: { prop: "title", label: "新闻名" },
              }),
              _c("el-table-column", {
                attrs: { prop: "artStatus", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.artStatus == 0
                          ? _c("el-tag", { attrs: { type: "warning" } }, [
                              _vm._v("初稿"),
                            ])
                          : _vm._e(),
                        scope.row.artStatus == 1
                          ? _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v("初审通过"),
                            ])
                          : _vm._e(),
                        scope.row.artStatus == 2
                          ? _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v("复审通过"),
                            ])
                          : _vm._e(),
                        scope.row.artStatus == 3
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("发布"),
                            ])
                          : _vm._e(),
                        scope.row.artStatus == 4
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("审核不通过"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "artType", label: "类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.artType == 1
                          ? _c("el-tag", [_vm._v("视频")])
                          : _vm._e(),
                        scope.row.artType == 2
                          ? _c("el-tag", [_vm._v("文本")])
                          : _vm._e(),
                        scope.row.artType == 3
                          ? _c("el-tag", [_vm._v("图文")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "topping", label: "热门" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.topping == 0
                          ? _c("el-tag", [_vm._v("正常")])
                          : _vm._e(),
                        scope.row.topping == 1
                          ? _c("el-tag", [_vm._v("热门")])
                          : _vm._e(),
                        scope.row.topping == 2
                          ? _c("el-tag", [_vm._v("置顶")])
                          : _vm._e(),
                        scope.row.topping == 3
                          ? _c("el-tag", [_vm._v("栏目封面")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", prop: "coverUrl", label: "封面图" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.coverUrl
                          ? _c("el-image", {
                              attrs: {
                                src: _vm.getImg(scope.row.coverUrl),
                                "preview-src-list": [
                                  _vm.getImg(scope.row.coverUrl),
                                ],
                              },
                            })
                          : _c("div", [_vm._v("暂无图片")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "colName", label: "栏目" },
              }),
              _c("el-table-column", {
                attrs: { prop: "collectionCount", label: "收藏数" },
              }),
              _c("el-table-column", {
                attrs: { prop: "addTime", label: "创建时间" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.globalPermission.news.edit
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", icon: "el-icon-edit" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("查看编辑\n                        ")]
                            )
                          : _vm._e(),
                        _vm.globalPermission.news.one &&
                        scope.row.artStatus == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", icon: "el-icon-edit" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleStatus(
                                      scope.row.id,
                                      scope.row.artStatus
                                    )
                                  },
                                },
                              },
                              [_vm._v("初审\n                        ")]
                            )
                          : _vm._e(),
                        _vm.globalPermission.news.two &&
                        scope.row.artStatus == 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", icon: "el-icon-edit" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleStatus(
                                      scope.row.id,
                                      scope.row.artStatus
                                    )
                                  },
                                },
                              },
                              [_vm._v("复审\n                        ")]
                            )
                          : _vm._e(),
                        _vm.globalPermission.news.three &&
                        scope.row.artStatus == 2
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", icon: "el-icon-edit" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleStatus(
                                      scope.row.id,
                                      scope.row.artStatus
                                    )
                                  },
                                },
                              },
                              [_vm._v("发布\n                        ")]
                            )
                          : _vm._e(),
                        _vm.globalPermission.news.delete
                          ? _c(
                              "el-button",
                              {
                                staticClass: "red",
                                attrs: { type: "text", icon: "el-icon-delete" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(
                                      scope.row.id,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("删除\n                        ")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.checkAppCode(scope.row)
                              },
                            },
                          },
                          [_vm._v("H5预览")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.changeTime(scope.row)
                              },
                            },
                          },
                          [_vm._v("修改发布时间")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "total, prev, pager, next",
                  "current-page": _vm.query.pageNum,
                  "page-size": _vm.query.pageSize,
                  total: _vm.pageTotal,
                },
                on: { "current-change": _vm.handlePageChange },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "封面图片", visible: _vm.imageVisible, width: "40%" },
          on: {
            "update:visible": function ($event) {
              _vm.imageVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticClass: "table",
              attrs: {
                data: _vm.picData,
                border: "",
                "header-cell-class-name": "table-header",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "imageName",
                  label: "图片(点击预览大图)",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          staticClass: "table-td-thumb",
                          attrs: {
                            src: _vm.picData[scope.$index],
                            "preview-src-list": _vm.picData,
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "120", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.globalPermission.news.delpic
                          ? _c(
                              "el-button",
                              {
                                staticClass: "red",
                                attrs: { type: "text", icon: "el-icon-delete" },
                                on: {
                                  click: function ($event) {
                                    return _vm.picDelete(scope.$index)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-upload",
                {
                  ref: "uploadPicture",
                  staticClass: "upload-demo",
                  attrs: {
                    action: "https://api.sctynews.cn/manager/file/upload",
                    headers: _vm.getToken,
                    "before-upload": _vm.beforeUpload,
                    data: _vm.uploadData,
                    "on-success": _vm.refreshPic,
                    multiple: "",
                  },
                },
                [
                  _vm.globalPermission.news.addpic
                    ? _c(
                        "el-button",
                        { attrs: { size: "medium", type: "primary" } },
                        [_vm._v("上传文件")]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [_vm._v("友情提示：图片操作实时更新")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "审核", visible: _vm.statusVisible, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.statusVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.statusForm, "label-width": "70px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.statusForm.artStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.statusForm, "artStatus", $$v)
                        },
                        expression: "statusForm.artStatus",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("通过")]),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("不通过"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "审批意见" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.statusForm.approveStr,
                      callback: function ($$v) {
                        _vm.$set(_vm.statusForm, "approveStr", $$v)
                      },
                      expression: "statusForm.approveStr",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clean } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveStatus } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "查看/编辑", visible: _vm.editVisible, width: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.editVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "170px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "新闻名" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "来源" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.source,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "source", $$v)
                      },
                      expression: "form.source",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "记者" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.reporter,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "reporter", $$v)
                      },
                      expression: "form.reporter",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "栏目" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "handle-input mr10",
                      attrs: { placeholder: "栏目" },
                      model: {
                        value: _vm.form.colId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "colId", $$v)
                        },
                        expression: "form.colId",
                      },
                    },
                    _vm._l(_vm.colOptions, function (item) {
                      return _c("el-option", {
                        key: item.key,
                        attrs: { label: item.label, value: item.key },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "封面类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.imgType,
                        callback: function ($$v) {
                          _vm.imgType = $$v
                        },
                        expression: "imgType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("默认")]),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("自定义"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.imgType == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "封面图", required: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "handle-input mr10",
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.form.coverUrl,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "coverUrl", $$v)
                            },
                            expression: "form.coverUrl",
                          },
                        },
                        _vm._l(_vm.colOptions_cover, function (item) {
                          return _c("el-option", {
                            key: item.key,
                            attrs: { label: item.label, value: item.key },
                          })
                        }),
                        1
                      ),
                      _vm.form.coverUrl
                        ? _c("div", [
                            _c("img", {
                              staticStyle: { width: "110px", height: "75px" },
                              attrs: { src: _vm.form.coverUrl, alt: "" },
                            }),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.imgType == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "封面图：", required: "" } },
                    [
                      _c("UploadImg", {
                        model: {
                          value: _vm.form.coverUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "coverUrl", $$v)
                          },
                          expression: "form.coverUrl",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.artType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "artType", $$v)
                        },
                        expression: "form.artType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("视频")]),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("文本")]),
                      _c("el-radio", { attrs: { label: 3 } }, [_vm._v("图文")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收藏基数" } },
                [
                  _c("el-input", {
                    staticClass: "handle-input mr10",
                    attrs: { type: "number" },
                    model: {
                      value: _vm.form.baseCollectionCount,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "baseCollectionCount", $$v)
                      },
                      expression: "form.baseCollectionCount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "浏览基数" } },
                [
                  _c("el-input", {
                    staticClass: "handle-input mr10",
                    attrs: { type: "number" },
                    model: {
                      value: _vm.form.baseViewCount,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "baseViewCount", $$v)
                      },
                      expression: "form.baseViewCount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "top" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.topping,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "topping", $$v)
                        },
                        expression: "form.topping",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("正常")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("热门")]),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("置顶")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "摘要" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.subTitle,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "subTitle", $$v)
                      },
                      expression: "form.subTitle",
                    },
                  }),
                ],
                1
              ),
              _vm.form.artType == "2" || _vm.form.artType == "3"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "正文" } },
                    [
                      _c("TinymceEditor", {
                        ref: "tinyceEditor",
                        model: {
                          value: _vm.form.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "content", $$v)
                          },
                          expression: "form.content",
                        },
                      }),
                      _c("div", { staticStyle: { color: "red" } }, [
                        _vm._v(_vm._s(_vm.saveDraftTimeString)),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.artType == "1"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "视频" } },
                    [
                      _c("video-player", {
                        staticClass: "vjs-custom-skin",
                        attrs: { options: _vm.playerOptions },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                [
                  _vm.form.artType == "1"
                    ? _c(
                        "el-upload",
                        {
                          ref: "uploadPicture",
                          staticClass: "upload-demo",
                          attrs: {
                            action:
                              "https://api.sctynews.cn/manager/file/uploadVideo",
                            headers: _vm.getToken,
                            "before-upload": _vm.beforeUpload,
                            data: _vm.uploadData,
                            "on-success": _vm.handleAvatarSuccess,
                            multiple: "",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "medium", type: "primary" } },
                            [_vm._v("上传视频")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveDraftTime },
                },
                [_vm._v("保存，继续编辑")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveEdit } },
                [_vm._v("保存，退出编辑")]
              ),
              _c("el-button", { on: { click: _vm.clean } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ],
        1
      ),
      _c("DialogHCode", {
        attrs: {
          dialogVisible: _vm.minDialogVisible,
          imageUrl: _vm.codeImageUrl,
          title: _vm.title,
        },
        on: {
          "update:dialogVisible": function ($event) {
            _vm.minDialogVisible = $event
          },
          "update:dialog-visible": function ($event) {
            _vm.minDialogVisible = $event
          },
        },
      }),
      _c("DialogTime", {
        ref: "DialogTime",
        attrs: { dialogVisible: _vm.timeDialogVisible },
        on: {
          "update:dialogVisible": function ($event) {
            _vm.timeDialogVisible = $event
          },
          "update:dialog-visible": function ($event) {
            _vm.timeDialogVisible = $event
          },
          reloadData: function ($event) {
            return _vm.getData()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }