<script src="../../main.js"></script>
<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-people"></i> 骑行管理
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                </el-button>
                <el-select v-model="query.queryType" placeholder="榜单排行" class="handle-input mr10">
                    <el-option
                            v-for="item in statusOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
                <template>
                    <el-date-picker
                            v-model="timeValue"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :picker-options="pickerOptions"
                            class="mr10">
                    </el-date-picker>
                </template>
                <el-button type="primary" icon="el-icon-search"  @click="handleSearch">搜索</el-button>
            </div>
            <el-table
                    :data="tableData"
                    border
                    class="table"
                    ref="multipleTable"
                    header-cell-class-name="table-header"
                    @selection-change="handleSelectionChange"
            >
                <el-table-column type="index" label="排名" width="70"> </el-table-column>
                <el-table-column prop="userName" label="昵称"></el-table-column>
                <el-table-column prop="userSex" label="性别" width="80">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.userSex==0" type="warndangering">女</el-tag>
                        <el-tag v-if="scope.row.userSex==1" type="success">男</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="nmg" label="是否民工" width="80">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.nmg==0" type="warndangering">否</el-tag>
                        <el-tag v-if="scope.row.nmg==1" type="success">是</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="cardNo" label="身份证号"></el-table-column>
                <el-table-column prop="numDay" label="当日公里数" width="100"></el-table-column>
                <el-table-column prop="numWeek" label="本周公里数" width="100"></el-table-column>
                <el-table-column prop="numTotal" label="总公里数" width="100"></el-table-column>
                <el-table-column prop="mobile" label="联系方式" width="100"></el-table-column>
                <el-table-column prop="createTime" label="报名时间"></el-table-column>

            </el-table>
            <div class="pagination">
                <el-pagination
                        background
                        layout="total, prev, pager, next"
                        :current-page="query.pageNum"
                        :page-size="query.pageSize"
                        :total="pageTotal"
                        @current-change="handlePageChange"
                ></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import {getAllMessage,pushMessage} from '../../api/push';
    import {getAllNews} from '../../api/news'
    var queryRequest = {url: "/manager/riding/info/managerRidingList"};
    export default {
        name: 'usertable',
        data() {
            return {
                query: {
                    pageNum: 1,
                    pageSize: 10,
                    startTime:'',
                    endTime:'',
                    queryType:''
                },
                globalPermission: this.getPermission(),
                getToken:{'auth-token':localStorage.getItem('token')},
                tableData: [],
                multipleSelection: [],
                delList: [],
                editVisible: false,
                editRolesVisible: false,
                statusVisible:false,
                formIdShow: false,
                pageTotal: 0,
                form: {},
                statusForm:{},
                idx: -1,
                id: -1,
                status:0,
                statusOptions: [{
                                    value: 1,
                                    label: '总榜排行'
                                }, {
                                    value: 2,
                                    label: '周榜'
                                }, {
                                    value: 3,
                                    label: '日榜'
                                }],
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                timeValue:'',
            };
        },
        created() {
            queryRequest.data = this.query;
            this.getData();
        },
        methods: {
            // 获取数据
            getData() {
                //获取新闻列表
                getAllMessage(queryRequest).then(res => {
                    this.tableData = res.data.list;
                    this.pageTotal = res.data.total;
                });
            },

            clean(){
                this.editVisible=false;
                this.statusVisible=false;
                this.handleSearch();
            },
            handleAvatarSuccess(res, file) {
                this.playerOptions.sources[0].src=res.data;
            },

            getPermission() {
                return JSON.parse(sessionStorage.getItem("permission"));
            },

            // 触发搜索按钮
            handleSearch() {
                this.query.startTime=this.timeValue[0];
                this.query.endTime=this.timeValue[1];
                this.$set(this.query, 'pageNum', 1);
                this.getData();
            },
            // 多选操作
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },

            // 分页导航
            handlePageChange(val) {
                this.$set(this.query, 'pageNum', val);
                this.getData();
            }
        }
    };
</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 130px;
        display: inline-block;
    }

    .table {
        width: 100%;
        font-size: 14px;
    }

    .red {
        color: #ff0000;
    }

    .mr10 {
        margin-right: 10px;
    }

    .table-td-thumb {
        display: block;
        margin: auto;
        width: 40px;
        height: 40px;
    }

    .show {
        display: block;
    }

    .hide {
        display: none;
    }

    .checkbox-rows {
        width: 130px;
        margin-top: 10px;
    }

    .el-checkbox.is-bordered + .el-checkbox.is-bordered {
        margin-left: 0px;
    }
</style>
