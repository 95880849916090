import request from '../utils/request';

export const loginPost = query => {
    return request({
        url: query.url,
        method: 'post',
        data: query.data
    });
};

export const lastLogin = query => {
    return request({
        url: query.url,
        method: 'get',
        data: query.data
    });
};

export const loginTimes = query => {
    return request({
        url: query.url,
        method: 'get',
        data: query.data
    });
};

export const getCounts = query => {
    return request({
        url: query.url,
        method: 'get',
        data: query.data
    });
};

export const getProjectAmount = query => {
    return request({
        url: query.url,
        method: 'get',
        data: query.data
    });
};


