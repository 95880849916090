var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "审核详情",
            visible: _vm._dialogVisible,
            "show-close": false,
            "close-on-click-modal": false,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm._dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticClass: "table",
                  attrs: {
                    data: _vm.tableData,
                    border: "",
                    "header-cell-class-name": "table-header",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "positionName",
                      align: "center",
                      label: "审核时间",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "positionName",
                      align: "center",
                      label: "审核人员",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "positionName",
                      align: "center",
                      label: "审核结果",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "positionName",
                      align: "center",
                      label: "反馈详情",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm._dialogVisible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }