import request from '../utils/request';

export const getAllMessage = query => {
    return request({
        url: query.url,
        method: 'post',
        data: query.data
    });
};

export const pushMessage = query => {
    return request({
        url: query.url,
        method: 'post',
        data: query.data
    });
};

