<script src="../../main.js"></script>
<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-s-finance"></i> 消息推送
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <el-button
                        type="primary"
                        icon="el-icon-lx-roundadd"
                        class="handle-del mr10"
                        @click="addItem"
                        v-if="globalPermission.push.add"
                >推送
                </el-button>
                <el-input v-model="query.pushTitle" placeholder="标题" class="handle-input mr10"></el-input>
                <el-select v-model="query.pushStatus" placeholder="状态" class="handle-input mr10">
                    <el-option
                            v-for="item in statusOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
                <template>
                    <el-date-picker
                            v-model="timeValue"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :picker-options="pickerOptions"
                            class="mr10">
                    </el-date-picker>
                </template>
                <el-button type="primary" icon="el-icon-search"  @click="handleSearch">搜索</el-button>
            </div>
            <el-table
                    :data="tableData"
                    border
                    class="table"
                    ref="multipleTable"
                    header-cell-class-name="table-header"
                    @selection-change="handleSelectionChange"
            >
                <el-table-column prop="pushTitle" label="推送标题"></el-table-column>
                <el-table-column prop="pushMsg" label="消息内容"></el-table-column>
                <el-table-column prop="articleTitle" label="关联新闻标题"></el-table-column>
                <el-table-column prop="articleId" label="关联新闻ID"></el-table-column>
                <el-table-column prop="pushStatus" label="状态">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.pushStatus==0" type="warndangering">推送失败</el-tag>
                        <el-tag v-if="scope.row.pushStatus==1" type="success">推送成功</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="createdTime" label="创建时间"></el-table-column>

            </el-table>
            <div class="pagination">
                <el-pagination
                        background
                        layout="total, prev, pager, next"
                        :current-page="query.pageNum"
                        :page-size="query.pageSize"
                        :total="pageTotal"
                        @current-change="handlePageChange"
                ></el-pagination>
            </div>
        </div>

        <!-- 编辑弹出框 -->
        <el-dialog title="推送" :visible.sync="editVisible" width="30%">
            <el-form ref="form" :model="form" label-width="70px">
                <el-form-item label="新闻">
                    <el-select v-model="form.articleId" placeholder="新闻" class="handle-input mr10">
                        <el-option
                                v-for="item in colOptions"
                                :key="item.label"
                                :label="item.label"
                                :value="item.key">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="推送标题">
                    <el-input v-model="form.pushTitle"></el-input>
                </el-form-item>
                <el-form-item label="消息内容">
                    <el-input v-model="form.pushMsg"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="clean">取 消</el-button>
                <el-button type="primary" @click="saveEdit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {getAllMessage,pushMessage} from '../../api/push';
    import {getAllNews} from '../../api/news'
    var pushRequest = {url: "/manager/push-message/pushMsg"};
    var queryRequest = {url: "/manager/push-message/getPushMsgList"};
    var queryNewsRequest = {url:"/manager/article/getArticles"};
    export default {
        name: 'usertable',
        data() {
            return {
                query: {
                    pushStatus: '',
                    pushTitle: '',
                    pageNum: 1,
                    pageSize: 10,
                    startTime:'',
                    endTime:''
                },
                delQuery:{
                    articleId:'',
                    index:''
                },
                statusQuery:{
                    id:'',
                    approveStr:'',
                    status
                },
                colOptions:this.getColOptions(),
                globalPermission: this.getPermission(),
                getToken:{'auth-token':localStorage.getItem('token')},
                tableData: [],
                multipleSelection: [],
                delList: [],
                editVisible: false,
                editRolesVisible: false,
                statusVisible:false,
                formIdShow: false,
                pageTotal: 0,
                form: {},
                statusForm:{},
                idx: -1,
                id: -1,
                status:0,
                statusOptions: [{
                    value: 0,
                    label: '推送失败'
                }, {
                    value: 1,
                    label: '推送成功'
                }],
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                timeValue:'',
            };
        },
        created() {
            queryRequest.data = this.query;
            this.getData();
        },
        methods: {
            // 获取数据
            getData() {
                //获取新闻列表
                getAllMessage(queryRequest).then(res => {
                    this.tableData = res.data.list;
                    this.pageTotal = res.data.total;
                });
            },

            clean(){
                this.editVisible=false;
                this.statusVisible=false;
                this.handleSearch();
            },
            handleAvatarSuccess(res, file) {
                this.playerOptions.sources[0].src=res.data;
            },

            getPermission() {
                return JSON.parse(sessionStorage.getItem("permission"));
            },

            // 触发搜索按钮
            handleSearch() {
                this.query.startTime=this.timeValue[0];
                this.query.endTime=this.timeValue[1];
                this.$set(this.query, 'pageNum', 1);
                this.getData();
            },
            getColOptions(){
                const data = [];
                queryNewsRequest.data ={
                    pageNum: 1,
                    pageSize: 100
                };
                getAllNews(queryNewsRequest).then(res =>{
                    res.data.list.forEach(function (tabs) {
                        data.push({
                            label: tabs.title,
                            key: tabs.id
                        });
                    });
                });
                return data;
            },
            // 多选操作
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },

            // 新增
            addItem() {
                this.form={};
                this.editVisible = true;
            },

            // 保存编辑
            saveEdit() {
                pushRequest.data = this.form;
                pushMessage(pushRequest).then(res => {
                    if (res.code === 0) {
                        this.$message.success(`推送成功`);
                        this.editVisible = false;
                        this.handleSearch();
                    } else {
                        this.$message.error(res.message);
                    }
                });
            },
            // 分页导航
            handlePageChange(val) {
                this.$set(this.query, 'pageNum', val);
                this.getData();
            }
        }
    };
</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 130px;
        display: inline-block;
    }

    .table {
        width: 100%;
        font-size: 14px;
    }

    .red {
        color: #ff0000;
    }

    .mr10 {
        margin-right: 10px;
    }

    .table-td-thumb {
        display: block;
        margin: auto;
        width: 40px;
        height: 40px;
    }

    .show {
        display: block;
    }

    .hide {
        display: none;
    }

    .checkbox-rows {
        width: 130px;
        margin-top: 10px;
    }

    .el-checkbox.is-bordered + .el-checkbox.is-bordered {
        margin-left: 0px;
    }
</style>
