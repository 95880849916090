var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "审核",
            width: "500px",
            visible: _vm._dialogVisible,
            "show-close": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm._dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.radio,
                    callback: function ($$v) {
                      _vm.radio = $$v
                    },
                    expression: "radio",
                  },
                },
                [_vm._v("通过")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "2" },
                  model: {
                    value: _vm.radio,
                    callback: function ($$v) {
                      _vm.radio = $$v
                    },
                    expression: "radio",
                  },
                },
                [_vm._v("不通过")]
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                [_vm._v("确定")]
              ),
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }