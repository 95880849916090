var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "crumbs" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", [
                _c("i", { staticClass: "el-icon-lx-people" }),
                _vm._v(" 赛事预告\n            "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "div",
            { staticClass: "handle-box" },
            [
              _vm.globalPermission.notice.add
                ? _c(
                    "el-button",
                    {
                      staticClass: "handle-del mr10",
                      attrs: { type: "primary", icon: "el-icon-lx-roundadd" },
                      on: { click: _vm.addItem },
                    },
                    [_vm._v("新增赛事预告\n            ")]
                  )
                : _vm._e(),
              _c("el-input", {
                staticClass: "handle-input mr10",
                attrs: { placeholder: "赛事标题" },
                model: {
                  value: _vm.query.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "title", $$v)
                  },
                  expression: "query.title",
                },
              }),
              _c("el-input", {
                staticClass: "handle-input mr10",
                attrs: { placeholder: "队名" },
                model: {
                  value: _vm.query.teamName,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "teamName", $$v)
                  },
                  expression: "query.teamName",
                },
              }),
              _c(
                "el-select",
                {
                  staticClass: "handle-input mr10",
                  attrs: { placeholder: "状态" },
                  model: {
                    value: _vm.query.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "status", $$v)
                    },
                    expression: "query.status",
                  },
                },
                _vm._l(_vm.statusOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              [
                _c("el-date-picker", {
                  staticClass: "mr10",
                  attrs: {
                    type: "daterange",
                    align: "right",
                    "unlink-panels": "",
                    "range-separator": "至",
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期",
                    "value-format": "yyyy-MM-dd HH:mm:ss",
                    "picker-options": _vm.pickerOptions,
                  },
                  model: {
                    value: _vm.timeValue,
                    callback: function ($$v) {
                      _vm.timeValue = $$v
                    },
                    expression: "timeValue",
                  },
                }),
              ],
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.handleSearch },
                },
                [_vm._v("搜索")]
              ),
            ],
            2
          ),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticClass: "table",
              attrs: {
                data: _vm.tableData,
                border: "",
                "header-cell-class-name": "table-header",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "title", label: "赛事标题" },
              }),
              _c("el-table-column", {
                attrs: { prop: "teamName1", label: "A队名称" },
              }),
              _c("el-table-column", {
                attrs: { prop: "team1Score", label: "A队得分" },
              }),
              _c("el-table-column", {
                attrs: { label: "A队队标", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              icon: "el-icon-lx-attention",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleImage(
                                  scope.row.id,
                                  1,
                                  scope.row.teamIcon1
                                )
                              },
                            },
                          },
                          [_vm._v("查看/上传")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "teamName2", label: "B队名称" },
              }),
              _c("el-table-column", {
                attrs: { prop: "team2Score", label: "B队得分" },
              }),
              _c("el-table-column", {
                attrs: { label: "B队队标", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              icon: "el-icon-lx-attention",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleImage(
                                  scope.row.id,
                                  2,
                                  scope.row.teamIcon2
                                )
                              },
                            },
                          },
                          [_vm._v("查看/上传")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "status", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              type:
                                scope.row.status === "0"
                                  ? "success"
                                  : scope.row.status === "1"
                                  ? "danger"
                                  : "",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.status == "0" ? "未开赛" : "已开赛"
                              ) + "\n                    "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "viedoUrl", label: "视频地址" },
              }),
              _c("el-table-column", {
                attrs: { prop: "startTime", label: "开赛时间" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.globalPermission.notice.edit
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", icon: "el-icon-edit" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(
                                      scope.row.id,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("编辑\n                    ")]
                            )
                          : _vm._e(),
                        _vm.globalPermission.notice.del
                          ? _c(
                              "el-button",
                              {
                                staticClass: "red",
                                attrs: { type: "text", icon: "el-icon-delete" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(
                                      scope.row.id,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("删除\n                    ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "total, prev, pager, next",
                  "current-page": _vm.query.pageNum,
                  "page-size": _vm.query.pageSize,
                  total: _vm.pageTotal,
                },
                on: { "current-change": _vm.handlePageChange },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "封面图片", visible: _vm.imageVisible, width: "40%" },
          on: {
            "update:visible": function ($event) {
              _vm.imageVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticClass: "table",
              attrs: {
                data: _vm.picData,
                border: "",
                "header-cell-class-name": "table-header",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "imageName",
                  label: "图片(点击预览大图)",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          staticClass: "table-td-thumb",
                          attrs: {
                            src: _vm.picData[0],
                            "preview-src-list": _vm.picData,
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-upload",
                {
                  ref: "uploadPicture",
                  staticClass: "upload-demo",
                  attrs: {
                    action:
                      "https://api.sctynews.cn/manager/file/uploadTeamIcon",
                    headers: _vm.getToken,
                    "before-upload": _vm.beforeUpload,
                    data: _vm.uploadData,
                    "on-success": _vm.refreshPic,
                    multiple: "",
                  },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "medium", type: "primary" } },
                    [_vm._v("上传队标")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [_vm._v("友情提示：图片操作实时更新")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑", visible: _vm.editVisible, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.editVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "70px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "赛事名称" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "handle-input mr10",
                      attrs: { placeholder: "状态" },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    _vm._l(_vm.statusOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "视频地址" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.viedoUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "viedoUrl", $$v)
                      },
                      expression: "form.viedoUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "A队名称" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.teamName1,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "teamName1", $$v)
                      },
                      expression: "form.teamName1",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "B队名称" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.teamName2,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "teamName2", $$v)
                      },
                      expression: "form.teamName2",
                    },
                  }),
                ],
                1
              ),
              _vm.form.status === 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "A队得分" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.team1Score,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "team1Score", $$v)
                          },
                          expression: "form.team1Score",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.status === 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "B队得分" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.team2Score,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "team2Score", $$v)
                          },
                          expression: "form.team2Score",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clean } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveEdit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }