var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "兼报",
            visible: _vm._dialogVisible,
            "show-close": false,
            "close-on-click-modal": false,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm._dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.enrolls,
                    callback: function ($$v) {
                      _vm.enrolls = $$v
                    },
                    expression: "enrolls",
                  },
                },
                _vm._l(_vm.tableData, function (item, index) {
                  return _c("el-checkbox", {
                    key: index,
                    attrs: { label: item.groupName },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                [_vm._v("确定")]
              ),
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }