import request from '../utils/request';

export const getAllLive = query => {
    return request({
        url: query.url,
        method: 'post',
        data: query.data
    });
};

export const getLiveDetail = query => {
    query.url ="/manager/live/liveDetail?id="+ query.id;
    return request({
        url: query.url,
        method: 'get',
        data: query.data
    });
};

export const delLive = query => {
    query.url ="/manager/live/delLive?id="+ query.id;
    return request({
        url: query.url,
        method: 'get',
        params: query.data
    });
};

export const saveLive= query => {
    return request({
        url: query.url,
        method: 'post',
        data: query.data
    });
};

export const editLive = query => {
    return request({
        url: query.url,
        method: 'post',
        data: query.data
    });
};
