<template>
	<div>
		<!-- 省 disabled -->
		<el-select v-model="addressCode[0]"  disabled  placeholder="请选择" @change="handleSelect1">
			<el-option v-for="item in addressData[0]" :key="item.id" :label="item.regionName" :value="item.id"> </el-option>
		</el-select>
		<!-- 市 -->
		<el-select v-model="addressCode[1]" placeholder="请选择" @change="handleSelect2" v-if="addressCode[0]">
			<el-option v-for="item in addressData[1]" :key="item.id" :label="item.regionName" :value="item.id"> </el-option>
		</el-select>
		<!-- 区 -->
		<el-select v-model="addressCode[2]" placeholder="请选择" @change="handleSelect3" v-if="addressCode[1]">
			<el-option v-for="item in addressData[2]" :key="item.id" :label="item.regionName" :value="item.id"> </el-option>
		</el-select>
	</div>
</template>
<script>
import * as API from '@/api/activity';
export default {
	name: 'index',
	props: {
		addressCode: {
			type: Array,
			default: () => [26, '', '']
		},
		site: {
			type: Array,
			default: () => ['四川', '', '']
		},
	},
	watch: {
		addressCode: {
			immediate: true,
			handler() {
				console.log('回显')
					this.echo();
			}
		}
	},
	data() {
		return {
			// addressCode: ['', '', ''],
			// site: ['', '', ''],
			addressData: [[], [], []],
			parentId: '',
			value: ''
		};
	},
	methods: {
		handleSelect1(val) {
			this.addressData[0].find((item) => {
				if (item.id == val) {
					this.site[0] = item.regionName;
				}
			});
			this.parentId = val;
			this.getRegionList(1);
			this.addressCode[1] = '';
			this.addressCode[2] = '';
			this.site[1] = '';
			this.site[2] = '';
		},
		handleSelect2(val) {
			this.addressData[1].find((item) => {
				if (item.id == val) {
					this.site[1] = item.regionName;
				}
			});
			this.parentId = val;
			this.getRegionList(2);
			this.addressCode[2] = '';
			this.site[2] = '';
		},
		handleSelect3(val) {
			this.addressData[2].find((item) => {
				if (item.id == val) {
					this.site[2] = item.regionName;
				}
			});
			this.parentId = val;
			this.getRegionList(3);
		},
		//地区数据接口
		async getRegionList(index) {
			let parameter = { parentId: this.parentId };
			let res = await API.getRegionList(parameter);
			this.$set(this.addressData, index, res.data);
			console.log('地区数据接口')
			console.log(this.addressData)
			this.$emit('update', { addressCode: this.addressCode, site: this.site });
		
		},
		//回显数据
		async echo() {
			let res = await API.getRegionList({ parentId: '' });
			this.$set(this.addressData, 0, res.data);
			if (this.addressCode[0]) {
				let res = await API.getRegionList({ parentId: this.addressCode[0] });
				this.$set(this.addressData, 1, res.data);
			}
			if (this.addressCode[1]) {
				let res = await API.getRegionList({ parentId: this.addressCode[1] });
				this.$set(this.addressData, 2, res.data);
			}
			this.$emit('update', { addressCode: this.addressCode, site: this.site });
		}
	},
	mounted() {
		this.echo();
		// this.handleSelect1(26)
	}
};
</script>
<style  scoped lang="less">
</style>