<script src="../../main.js"></script>
<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-picture-outline"></i> 轮播图
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <el-button
                        type="primary"
                        icon="el-icon-lx-roundadd"
                        class="handle-del mr10"
                        @click="addItem"
                        v-if="globalPermission.banner.add"
                >新增
                </el-button>
                <el-input v-model="query.title" placeholder="标题" class="handle-input mr10"></el-input>
                <el-button type="primary" icon="el-icon-search"  @click="handleSearch">搜索</el-button>
            </div>
            <el-table
                    :data="tableData"
                    border
                    class="table"
                    ref="multipleTable"
                    header-cell-class-name="table-header"
                    @selection-change="handleSelectionChange"
            >
                <el-table-column prop="title" label="新闻标题"></el-table-column>
                <el-table-column prop="toUrl" label="对应新闻ID"></el-table-column>
                <el-table-column prop="createdTime" label="创建时间"></el-table-column>
                <el-table-column label="轮播图片" align="center">
                    <template slot-scope="scope">
                        <el-button
                                type="text"
                                icon="el-icon-lx-attention"
                                @click="handleImage(scope.row.id,scope.row.imgUrl)"
                        >查看/上传</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button
                                type="text"
                                icon="el-icon-edit"
                                @click="handleEdit(scope.row.id, scope.row)"
                                v-if="globalPermission.banner.edit"
                        >编辑
                        </el-button>
                        <el-button
                                type="text"
                                icon="el-icon-delete"
                                class="red"
                                @click="handleDelete(scope.row.id, scope.row)"
                                v-if="globalPermission.banner.del"
                        >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                        background
                        layout="total, prev, pager, next"
                        :current-page="query.pageNum"
                        :page-size="query.pageSize"
                        :total="pageTotal"
                        @current-change="handlePageChange"
                ></el-pagination>
            </div>
        </div>

        <el-dialog title="图片" :visible.sync="imageVisible" width="40%">
            <el-table
                    :data="picData"
                    border
                    class="table"
                    ref="multipleTable"
                    header-cell-class-name="table-header"
            >
                <el-table-column prop="imageName" label="图片(点击预览大图)" align="center">
                    <template slot-scope="scope">
                        <el-image
                                class="table-td-thumb"
                                :src="picData[0]"
                                :preview-src-list="picData"
                        ></el-image>
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
               <el-upload
                       ref="uploadPicture"
                       class="upload-demo"
                       action="https://api.sctynews.cn/manager/file/uploadLoopImg"
                       :headers="getToken"
                       :before-upload="beforeUpload"
                       :data="uploadData"
                       :on-success="refreshPic"
                       multiple
               >
                    <el-button size="medium" type="primary">上传封面图</el-button>
                    <div slot="tip" class="el-upload__tip">友情提示：图片操作实时更新</div>
                </el-upload>
            </span>
        </el-dialog>

        <!-- 编辑弹出框 -->
        <el-dialog title="新增/编辑" :visible.sync="editVisible" width="30%">
            <el-form ref="form" :model="form" label-width="70px">
                <el-form-item label="关联新闻">
                    <el-select v-model="form.toUrl" placeholder="新闻" class="handle-input mr10">
                        <el-option
                                v-for="item in colOptions"
                                :key="item.key"
                                :label="item.label"
                                :value="item.key">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="clean">取 消</el-button>
                <el-button type="primary" @click="saveEdit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {getAll,editBanner,delBanner,saveBanner,getBannerDetail} from '../../api/banner';
    import {getAllNews} from '../../api/news'
    var addRequest = {url: "/manager/loop-img/addLoopImg"};
    var queryRequest = {url: "/manager/loop-img/getLoopImg"};
    var editRequest = {url:"/manager/loop-img/updateLoopImg"};
    var delRequest={url:"/manager/loop-img/delLoopImg"};
    var queryNewsRequest = {url:"/manager/article/getArticles"};
    export default {
        name: 'usertable',
        data() {
            return {
                query: {
                    title: '',
                    pageNum: 1,
                    pageSize: 10,
                },
                delQuery:{
                    articleId:'',
                    index:''
                },
                statusQuery:{
                    id:'',
                    approveStr:'',
                    status
                },
                uploadData:null,
                picData :[],
                colOptions:this.getColOptions(),
                globalPermission: this.getPermission(),
                getToken:{'auth-token':localStorage.getItem('token')},
                tableData: [],
                imageVisible:false,
                multipleSelection: [],
                delList: [],
                editVisible: false,
                editRolesVisible: false,
                statusVisible:false,
                formIdShow: false,
                pageTotal: 0,
                form: {},
                statusForm:{},
                idx: -1,
                id: -1,
                isCurEdit:false,
            };
        },
        created() {
            queryRequest.data = this.query;
            this.getData();
        },
        methods: {
            // 获取数据
            getData() {
                //获取新闻列表
                getAll(queryRequest).then(res => {
                    this.tableData = res.data.list;
                    this.pageTotal = res.data.total;
                });
            },

            clean(){
                this.editVisible=false;
                this.statusVisible=false;
                this.handleSearch();
            },
            getPermission() {
                return JSON.parse(sessionStorage.getItem("permission"));
            },

            // 触发搜索按钮
            handleSearch() {
                this.$set(this.query, 'pageNum', 1);
                this.getData();
            },
            // 编辑操作
            handleEdit(id, row) {
                this.id = id;
                this.form = row;
                this.form.id = this.id;
                this.editVisible = true;
                this.isCurEdit = true;
            },
            // 打开图片窗口
            handleImage(id,url) {
                this.id=id;
                this.picData[0]=url;
                this.imageVisible = true;
            },
            refreshPic(response){
                this.imageVisible = false;
                this.handleSearch();
            },
            beforeUpload (file) {
                this.uploadData = {id:this.id};
                let promise = new Promise((resolve) => {
                    this.$nextTick(function () {
                        resolve(true);
                    });
                });
                return promise; //通过返回一个promis对象解决
            },
            getColOptions(){
                const data = [];
                queryNewsRequest.data ={
                    artStatus:3,
                    pageNum: 1,
                    pageSize: 100
                };
                getAllNews(queryNewsRequest).then(res =>{
                    res.data.list.forEach(function (tabs) {
                        data.push({
                            label: tabs.title,
                            key: tabs.id
                        });
                    });
                });
                return data;
            },
            // 多选操作
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },

            // 新增
            addItem() {
                this.form={};
                this.isCurEdit = false;
                this.editVisible = true;
            },

            // 保存编辑
            saveEdit() {
                if (this.isCurEdit) {
                    editRequest.data = this.form;
                    editBanner(editRequest).then(res => {
                        if (res.code == 0) {
                            this.$message.success(`修改成功`);
                            this.editVisible = false;
                            this.handleSearch();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                } else {
                    addRequest.data = this.form;
                    saveBanner(addRequest).then(res => {
                        if (res.code == 0) {
                            this.$message.success(`新增成功`);
                            this.editVisible = false;
                            this.handleSearch();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                }
            },
            // 删除操作
            handleDelete(id, row) {
                // 二次确认删除
                this.$confirm('确定要删除吗？', '提示', {
                    type: 'warning'
                }).then(() => {
                    delRequest.data = {id:id};
                    delBanner(delRequest).then(res => {
                        if (res.code == 0) {
                            this.$message.success(`删除成功`);
                            this.handleSearch();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                    //this.tableData.splice(index, 1);
                }).catch(() => {
                });
            },
            // 分页导航
            handlePageChange(val) {
                this.$set(this.query, 'pageNum', val);
                this.getData();
            }
        }
    };
</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 130px;
        display: inline-block;
    }

    .table {
        width: 100%;
        font-size: 14px;
    }

    .red {
        color: #ff0000;
    }

    .mr10 {
        margin-right: 10px;
    }

    .table-td-thumb {
        display: block;
        margin: auto;
        width: 40px;
        height: 40px;
    }

    .show {
        display: block;
    }

    .hide {
        display: none;
    }

    .checkbox-rows {
        width: 130px;
        margin-top: 10px;
    }

    .el-checkbox.is-bordered + .el-checkbox.is-bordered {
        margin-left: 0px;
    }
</style>
