import request from '../utils/request';

export const getAll = query => {
    return request({
        url: query.url,
        method: 'post',
        data: query.data
    });
};

export const getBannerDetail = query => {
    return request({
        url: query.url,
        method: 'post',
        data: query.data
    });
};

export const delBanner = query => {
    return request({
        url: query.url,
        method: 'post',
        params: query.data
    });
};

export const saveBanner= query => {
    return request({
        url: query.url,
        method: 'post',
        data: query.data
    });
};

export const editBanner = query => {
    return request({
        url: query.url,
        method: 'post',
        data: query.data
    });
};
