import request from '../utils/request';

export const getAllRole = query => {
    return request({
        url: query.url,
        method: 'post',
        data: query.data
    });
};

export const addRole = query => {
    return request({
        url: query.url,
        method: 'post',
        data: query.data
    });
};

export const editRole = query => {
    return request({
        url: query.url,
        method: 'put',
        data: query.data
    });
};

export const delRole = query => {
    query.url ="/usercenter/role/"+ query.id;
    return request({
        url: query.url,
        method: 'DELETE',
        data: query.data
    });
};

export const getAllPermission = query => {
    return request({
        url: query.url,
        method: 'get',
        data: query.data
    });
};

export const getRolePermission = query => {
    query.url='/usercenter/role-permission/'+query.id;
    return request({
        url: query.url,
        method: 'get'
    });
};

export const saveRolePermission = query => {
    return request({
        url: query.url,
        method: 'post',
        data: query.data
    });
};

