var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.parameter.id ? "编辑图片" : "新增图片",
            visible: _vm._dialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm._dialogVisible = $event
            },
            close: _vm.cancel,
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                { attrs: { "label-width": "180px" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "图片：", required: "" } },
                    [
                      _c("UploadImg", {
                        attrs: { max: 100 },
                        model: {
                          value: _vm.parameter.url,
                          callback: function ($$v) {
                            _vm.$set(_vm.parameter, "url", $$v)
                          },
                          expression: "parameter.url",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                [_vm._v("保存")]
              ),
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }