var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "crumbs" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", [
                _c("i", { staticClass: "el-icon-data-line" }),
                _vm._v(" 活动管理 "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "div",
            { staticClass: "handle-box" },
            [
              _c(
                "el-form",
                { ref: "form", attrs: { inline: "" } },
                [
                  _c(
                    "el-form-item",
                    [
                      _vm.globalPermission.activity.add
                        ? _c(
                            "el-button",
                            {
                              staticClass: "handle-del mr10",
                              attrs: {
                                type: "primary",
                                icon: "el-icon-lx-roundadd",
                              },
                              on: { click: _vm.addItem },
                            },
                            [_vm._v("新增活动\n            ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动名称：" } },
                    [
                      _c("el-input", {
                        staticClass: "handle-input mr10",
                        attrs: { clearable: "", placeholder: "活动名称" },
                        model: {
                          value: _vm.pager.matchName,
                          callback: function ($$v) {
                            _vm.$set(_vm.pager, "matchName", $$v)
                          },
                          expression: "pager.matchName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "handle-input mr10",
                          attrs: { clearable: "", placeholder: "状态" },
                          model: {
                            value: _vm.pager.applyStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.pager, "applyStatus", $$v)
                            },
                            expression: "pager.applyStatus",
                          },
                        },
                        _vm._l(_vm.options, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "报名时间：" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "mr10",
                        attrs: {
                          type: "datetimerange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          clearable: "",
                          "end-placeholder": "结束日期",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        model: {
                          value: _vm.timeArr,
                          callback: function ($$v) {
                            _vm.timeArr = $$v
                          },
                          expression: "timeArr",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", icon: "el-icon-search" },
                          on: { click: _vm.handleSearch },
                        },
                        [_vm._v("搜索")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticClass: "table",
              attrs: {
                data: _vm.tableData,
                border: "",
                "header-cell-class-name": "table-header",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "colName",
                  align: "center",
                  label: "序号",
                  width: "60",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.pager.pageIndex - 1) * _vm.pager.pageSize +
                                scope.$index +
                                1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "matchName",
                  align: "center",
                  label: "活动名称",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "colSort", align: "center", label: "活动图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          staticStyle: { width: "200px", height: "200px" },
                          attrs: { src: scope.row.activityPicUrl },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "sponsor",
                  align: "center",
                  label: "举办方",
                  width: "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(scope.row.sponsor, function (item, index) {
                        return _c("div", { key: index }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.sponsor) +
                              "\n              " +
                              _vm._s(item.phone) +
                              "\n            "
                          ),
                        ])
                      })
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "colSort", align: "center", label: "报名时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.applyStartTime))]),
                        _c("div", [_vm._v("至")]),
                        _c("div", [_vm._v(_vm._s(scope.row.applyEndTime))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "colSort", align: "center", label: "报名状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(_vm._s(_vm.hdStatus[scope.row.applyStatus])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "userCount",
                  align: "center",
                  label: "报名人数",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "colSort", align: "center", label: "比赛时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.matchStartTime))]),
                        _c("div", [_vm._v("至")]),
                        _c("div", [_vm._v(_vm._s(scope.row.matchEndTime))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "colSort", align: "center", label: "比赛状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(_vm._s(_vm.shStatus[scope.row.matchStatus])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.globalPermission.activity.edit &&
                        (scope.row.matchStatus == 100 ||
                          scope.row.matchStatus == 103)
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", icon: "el-icon-edit" },
                                on: {
                                  click: function ($event) {
                                    return _vm.audit(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("审核\n            ")]
                            )
                          : _vm._e(),
                        _vm.globalPermission.activity.edit &&
                        scope.row.matchStatus == 101
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", icon: "el-icon-edit" },
                                on: {
                                  click: function ($event) {
                                    return _vm.publish(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("发布\n            ")]
                            )
                          : _vm._e(),
                        _vm.globalPermission.activity.edit &&
                        (scope.row.matchStatus == 100 ||
                          scope.row.matchStatus == 103)
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", icon: "el-icon-edit" },
                                on: {
                                  click: function ($event) {
                                    return _vm.applicationForm(scope.row.id)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              编辑报名表\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.globalPermission.activity.edit &&
                        (scope.row.matchStatus == 100 ||
                          scope.row.matchStatus == 103)
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", icon: "el-icon-edit" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v("编辑\n            ")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", icon: "el-icon-edit" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDetail(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("详情 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "total, prev, pager, next",
                  "current-page": _vm.pager.pageIndex,
                  "page-size": _vm.pager.pageSize,
                  total: _vm.pager.total,
                },
                on: { "current-change": _vm.handlePageChange },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("DialogAuditDetail", {
        attrs: { dialogVisible: _vm.dialogVisible },
        on: {
          "update:dialogVisible": function ($event) {
            _vm.dialogVisible = $event
          },
          "update:dialog-visible": function ($event) {
            _vm.dialogVisible = $event
          },
        },
      }),
      _c("DialogAudit", {
        attrs: { dialogVisible: _vm.dialogVisible2, auditId: _vm.auditId },
        on: {
          "update:dialogVisible": function ($event) {
            _vm.dialogVisible2 = $event
          },
          "update:dialog-visible": function ($event) {
            _vm.dialogVisible2 = $event
          },
          "update:auditId": function ($event) {
            _vm.auditId = $event
          },
          "update:audit-id": function ($event) {
            _vm.auditId = $event
          },
          refresh: _vm.getActivityList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }