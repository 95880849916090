var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm._dialogVisible,
            "show-close": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm._dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                { attrs: { "label-width": "130px" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "奖章/证书：", required: "" } },
                    [
                      _c("UploadImg", {
                        model: {
                          value: _vm.icon,
                          callback: function ($$v) {
                            _vm.icon = $$v
                          },
                          expression: "icon",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "关联赛事：", required: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            remote: "",
                            "reserve-keyword": "",
                            placeholder: "活动名称",
                            "remote-method": _vm.remoteMethod,
                            clearable: "",
                            loading: _vm.loading,
                          },
                          model: {
                            value: _vm.matchId,
                            callback: function ($$v) {
                              _vm.matchId = $$v
                            },
                            expression: "matchId",
                          },
                        },
                        _vm._l(_vm.activityList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.matchName, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "奖章名称：", required: "" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入" },
                        model: {
                          value: _vm.medalName,
                          callback: function ($$v) {
                            _vm.medalName = $$v
                          },
                          expression: "medalName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                [_vm._v("确定")]
              ),
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }