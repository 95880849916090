<template>
	<div>
		<!-- 活动预览弹框 -->
		<el-dialog :title="params.title" :visible.sync="_dialogVisible" :show-close="false" :close-on-click-modal="false" width="600px">
			<div>
				<!-- <el-date-picker v-model="params.addTime" value-format="yyyy-MM-dd" type="datetime" placeholder="选择日期"> </el-date-picker> -->
				<!-- <el-date-picker v-model="params.addTime" type="date" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期"></el-date-picker> -->
				<el-date-picker type="datetime" v-model="params.addTime"
      :style="{width: '70%'}" placeholder="请选择指定时间" clearable
        value-format="yyyy-MM-ddTHH:mm:ss"></el-date-picker>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="notarize" type="primary">确认</el-button>
				<el-button @click="close">关闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>

import {editNews_changeTime} from '../../api/news.js';
var updateRequest = {url: "/manager/article/updateCreateArticle"};

export default {
	name: 'DialogTime',
	data() {
		return {
			params: {},
			addTime: ''
		};
	},
	props: {
		dialogVisible: {
			type: Boolean,
			required: true,
			default: false
		},
		imageUrl: {
			type: String,
			default: ''
		},
		title: {
			type: String,
			default: ''
		}
	},
	computed: {
		_dialogVisible: {
			get() {
				return this.dialogVisible;
			},
			set(val) {
				this.$emit('update:dialogVisible', val);
			}
		}
	},
	methods: {
		getDefaultDateTime() {
      const now = new Date();
      now.setHours(now.getHours() + 8); // 加上8小时
      return now;
    },
		open(item) {
			this.params = {...item}

		},
		//关闭
		close() {
			// this.imageUrl = '';
			this._dialogVisible = false;
		},

		//确认
		notarize() {
			// 								createTime: this.params.addTime


			const froms = {
				id: this.params.id,
				addTime: this.params.addTime,
			}
			updateRequest.data = froms;
			editNews_changeTime(updateRequest).then(res => {
                        if (res.code == 0) {
                            this.$message.success(`修改成功`);
							this.$emit('reloadData')
							this.close();
                            
                        } else {
                            this.$message.error(res.message);
                        }
                    });
			// this.close();

		}
	},
	mounted() {}
};
</script>
<style  scoped lang="less">
.top-tip {
	color: red;
}
.img {
	display: flex;
	align-items: center;
		justify-content: center;

}

</style>