<template>
  <div class="tinymce-box">
    <editor
      v-model="myValue"
      :init="init"
      :disabled="disabled"
      id="tinymceId"
    >
    </editor>
    <!-- 编辑图片 -->
    <VueImageCropper
      :dialogVisible.sync="dialogVisible"
      :imageUrl="cropperImageUrl"
      :pictureSize="pictureSize"
      :imageTotalSize="cropImageTotalSize"
      @clickSubmit="clickSubmit"
    />
    <el-dialog
      title="编辑图片"
      :visible.sync="picModeDialogVisible"
      :show-close="false"
      :close-on-click-modal="false"
      append-to-body
    >
      <div
        style="margin-bottom: 20px;"
        v-show="picMode == 1"
      >{{ oldImageTotalSize }} <span
          style="color:red;"
          v-show="this.image_size*this.picRadio > 1000"
        >（图片大小已超过1M）</span></div>
      <div
        style="margin-bottom: 20px;"
        v-show="picMode == 2"
      >{{ cropOldImageTotalSize }} <span
          style="color:red;"
          v-show="this.image_size*1 > 1000"
        >（图片大小已超过1M）</span></div>
      <div style="margin-bottom: 20px;">
        <el-radio
          v-model="picMode"
          label="1"
        >原图</el-radio>
        <el-radio
          v-model="picMode"
          label="2"
        >图片裁剪(图片裁剪会等比压缩)</el-radio>
      </div>
      <div v-show="picMode == 1">
        <el-radio
          v-model="picRadio"
          label="1"
        >不压缩</el-radio>
        <el-radio
          v-model="picRadio"
          label="0.245"
        >jpeg压缩(减少75.5%)</el-radio>
        <!-- <el-radio v-model="picRadio" label="0.138">webp压缩(减少86.2%)</el-radio> -->
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          @click="picModeCancel"
          size="small"
        >取 消</el-button>
        <el-button
          type="primary"
          @click="picModeConfirm"
          size="small"
        >确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

  <script>
// 文档 http://tinymce.ax-z.cn/
// 引入组件
import tinymce from 'tinymce/tinymce' // tinymce默认hidden，不引入不显示
import Editor from '@tinymce/tinymce-vue'

// 引入富文本编辑器主题的js和css
import 'tinymce/skins/content/default/content.css'
import 'tinymce/themes/silver/theme.min.js'
import 'tinymce/icons/default/icons' // 解决了icons.js 报错Unexpected token '<'

// 编辑器插件plugins
// 更多插件参考：https://www.tiny.cloud/docs/plugins/
import 'tinymce/plugins/image'// 插入上传图片插件
import 'tinymce/plugins/media'// 插入视频插件
import 'tinymce/plugins/table'// 插入表格插件
import 'tinymce/plugins/lists'// 列表插件
import 'tinymce/plugins/wordcount'// 字数统计插件
import 'tinymce/plugins/link'
import 'tinymce/plugins/code'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/help'
import 'tinymce/plugins/letterspacing'
import 'tinymce/plugins/indent2em'
import 'tinymce/plugins/doubleindent'
import 'tinymce/plugins/sectionindent'


let loadingInstance
import {
  Message,
  Loading
} from 'element-ui';
// import axios from '@/utils/axios/index'
import axios from 'axios';
import TcVod from "vod-js-sdk-v6";

export default {
  components: {
    Editor
  },
  name: 'TinymceEditor',
  props: {
    // 默认的富文本内容
    value: {
      type: String,
      default: ''
    },
    // 基本路径，默认为空根目录，如果你的项目发布后的地址为目录形式，
    // 即abc.com/tinymce，baseUrl需要配置成tinymce，不然发布后资源会找不到
    baseUrl: {
      type: String,
      default: window.location.origin ? window.location.origin : ''
    },
    // 禁用
    disabled: {
      type: Boolean,
      default: false
    },
    plugins: {
      type: [String, Array],
      default: 'link lists image code table wordcount media preview fullscreen help indent2em letterspacing doubleindent sectionindent'
    },
    toolbar: {
      type: [String, Array],
      default: 'bold italic underline strikethrough | fontsizeselect | formatselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent blockquote | undo redo | link unlink code lists table image media | removeformat | lineheight letterspacing indent2em | doubleindent sectionindent | preview fullscreen '
    },
    tinymceId: {
      type: String,
      default: function() {
        return ''
      }
    },
  },
  data () {
    return {
      init: {
        selector: "#tinymce",
        language_url: `${this.baseUrl}/tinymce/langs/zh_CN.js`,
        language: 'zh_CN',
        skin_url: `${this.baseUrl}/tinymce/skins/ui/oxide`,
        skin_url: 'tinymce/skins/ui/oxide', // 暗色系
        convert_urls: false,
        height: 700,
        auto_focus: true,
        // content_style: "p {font-size:24px; color: #843FA1;}",

        // setup: function(editor) {
        //    editor.on('init', function(e) {
        //       this.getBody().style.fontSize = '24px';
        //       this.getBody().style.color = '#843FA1';
        // })
        // },
        setup: function(editor) {
          // init
          editor.on('init', function() {
            setTimeout(() => {
              const dom = this.dom;
              const blocks = this.selection.getSelectedBlocks();
              dom.setStyle(blocks[0], 'padding-left', '4px');
              dom.setStyle(blocks[0], 'padding-right', '4px');
              dom.setStyle(blocks[0], 'color', '#595959');
              dom.setStyle(blocks[0], 'font-size', '15px');
              dom.setStyle(blocks[0], 'line-height', '1.8em');
            }, 500);
            // this.execCommand('Bold');
            // this.execCommand('fontsize','24px');
            // this.execCommand('mceSetAttribute',false,{name:'color',value:'#843FA1'})
            console.log('deitor-setup-init');
          });
        },

        powerpaste_word_import: 'merge', // 是否保留word粘贴样式  clean | merge

        paste_data_images: true, // 设置为“true”将允许粘贴图像，而将其设置为“false”将不允许粘贴图像。

        // content_style: "p {margin: 5px 0;}",

        // content_css（为编辑区指定css文件）,加上就不显示字数统计了
        //   content_css: `${this.baseUrl}tinymce/skins/content/default/content.css`,
        //   content_style: "img {max-width:100%;}", //直接自定义可编辑区域的css样式
        // content_css: '/tinycontent.css',  //以css文件方式自定义可编辑区域的css样式，css文件需自己创建并引入
        // （指定需加载的插件）
        plugins: this.plugins,
        toolbar: this.toolbar, // （自定义工具栏）
        toolbar_mode: "wrap",
        statusbar: true, // 底部的状态栏
        menubar: 'file edit insert view format table tools help', // （1级菜单）最上方的菜单
        branding: false, // （隐藏右下角技术支持）水印“Powered by TinyMCE”
        fontsize_formats: '8px 10px 12px 14px 15px 16px 18px 20px 22px 24px 28px 36px 48px 72px',//自定义字体大小
        font_formats:
          "微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;",
        lineheight_formats: '1em 1.1em 1.2em 1.3em 1.4em 1.5em 1.6em 1.7em 1.8em 1.9em 2em',
        // 此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
        // 如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
        images_upload_handler: (blobInfo, success, failure) => {
          // const img = 'data:image/jpeg;base64,' + blobInfo.base64()
          // success(img)
          // console.log(failure)
          console.log('success=' + (typeof success));
          this.success = success
          const image_size = blobInfo.blob().size / 1000; // kb
          this.image_size = image_size
          console.log('image_size=' + image_size);
          // this.picModeDialogVisible = true
          // failure('图片上传出错，请稍后重试')
          // return
          const param = new FormData(); // 创建form对象
          param.append("file", blobInfo.blob()); // 通过append向form对象添加数据
          axios.post(process.env.VUE_APP_ApiUrlLog + 'third/files/upload', param).then((res) => {
            this.picModeDialogVisible = true
            this.imageUrl = res.data.data
            console.log('this.imageUrl='+JSON.stringify(this.imageUrl));
            setTimeout(() => {
              this.getNaturalSize(this.imageUrl)
            }, 500)

          }).catch((e) => {
            failure('图片上传出错，请稍后重试')
          });
        },
        file_picker_callback: function(callback, value, meta) {
          if (meta.filetype === "media") {
            // 模拟上传本地视频
            let input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", ".mp4");
            input.onchange = function() {
              let file = this.files[0];
              //获取上传签名的函数
              const getSignature = async () => {
                let config = {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
                }
                console.log('获取上传签名的函数')
                loadingInstance = Loading.service({
                  text: '视频上传中',
                });
                // 添加请求头
                let res = await axios.get(process.env.VUE_APP_ApiUrlLog + 'third/files/sign', "", config)
                return res.data.data;
              };
              const tcVod = new TcVod({
                getSignature: getSignature, // 获取上传签名的函数
              });

              // 获取通过elementui上传到本地的文件  因为参数类型必须为file 不能直接以对象的形式传输
              const mediaFile = file;
              const uploader = tcVod.upload({
                mediaFile: mediaFile,
              });
              // 监听上传进度
              uploader.on("media_progress", (info) => {
                this.progress = parseInt(info.percent * 100);
              });
              // 上传结束时，将url存到本地
              uploader
                .done()
                .then((doneResult) => {
                  callback(doneResult.video.url);
                  //关闭加载
                  loadingInstance.close();

                })
                .catch(() => {
                  //关闭加载
                  loadingInstance.close();
                  Message({
                    message: "视频上传出错，请稍后重试",
                    type: "warning"
                  });
                });

            };
            input.click();
          }
        },
      },
      myValue: this.value,
      imageUrl: '', // 原始图片
      image_size: 0, // 图片大小
      cropperImageUrl: '', // 传值给截图弹窗里面图片
      dialogVisible: false,//截图插件的弹窗
      picModeDialogVisible: false,//图片上传模式弹窗
      picMode: '1',
      picRadio: '0.245',
      success: function() { },
      pictureSize: {}, // 图片原始尺寸

    }
  },
  mounted () {
    console.log('mounted----tinymce')
    tinymce.init({})

  },
  activated () {
    console.log('activated----tinymce')
    // tinymce.init({})
  },
  computed: {
    oldImageTotalSize () {
      if (this.image_size * this.picRadio < 1000) {
        return '图片大小：' + (this.image_size * this.picRadio).toFixed(1) + 'KB'
      } else {
        return '图片大小：' + (this.image_size * this.picRadio / 1000).toFixed(1) + 'MB'
      }
    },
    cropOldImageTotalSize () {
      if (this.image_size < 1000) {
        return '图片大小：' + (this.image_size * 1).toFixed(1) + 'KB'
      } else {
        return '图片大小：' + (this.image_size / 1000).toFixed(1) + 'MB'
      }
    },
    cropImageTotalSize () {
      return this.image_size
    }
  },
  methods: {
    // 1.首先获取到图片的原始宽高
    getNaturalSize (url) {
      var natureSize = {};
      if (window.naturalWidth && window.naturalHeight) {
        natureSize.width = url.naturalWidth;
        natureSize.height = url.naturalHeight;
        this.pictureSize = natureSize
      } else {
        var img = new Image();
        img.src = url;
        img.onload = () => {
          natureSize.width = img.width;
          natureSize.height = img.height;
          // console.log('imgurl=' + url);
          console.log('imgWidth=' + JSON.stringify(img.width));
          console.log('imgHeight=' + JSON.stringify(img.height));
          this.pictureSize = natureSize
        };
      }
    },
    // 添加相关的事件，可用的事件参照文档=> https://github.com/tinymce/tinymce-vue => All available events
    // 需要什么事件可以自己增加
    onClick (e) {
      this.$emit('onClick', e, tinymce)
    },
    // 可以添加一些自己的自定义事件，如清空内容
    clear () {
      console.log('editor---clear--' + this.tinymceId);
      this.myValue = ''
      // this.$refs.editor.destroy()
      const instance = tinymce.get('tinymceId');
      console.log('instance='+JSON.stringify(instance));
      // instance.init(this.init)

    },
    picModeConfirm () {
      // 原图上传
      if (this.picMode === '1') {
        // this.$emit("cropperSubmit", this.imageUrl);
        if (this.picRadio == 0.245) { // jpg压缩
          this.imageUrl = this.imageUrl + '?imageMogr2/format/jpeg'
        }
        // else if (this.picRadio == 0.138) { // webp压缩
        //   this.imageUrl = this.imageUrl + '?imageMogr2/format/webp'
        // }
        this.success(this.imageUrl)
      } else { // 截图上传
        this.cropperImageUrl = this.imageUrl
        this.dialogVisible = true
      }
      // 重置
      this.picMode = '1'
      this.picModeDialogVisible = false
      this.picRadio = '0.245'
    },
    picModeCancel () {
      this.success('')
      this.picMode = '1'
      this.picModeDialogVisible = false
      this.picRadio = '0.245'
    },
    // 截图回调
    clickSubmit (imgUrl) {
      console.log('clickSubmit===' + imgUrl);
      this.success(imgUrl)
      console.log('this.success=' + (typeof this.success));
    },
  },
  watch: {
    value (newValue) {
      this.myValue = newValue
    },
    myValue (newValue) {
      this.$emit('input', newValue)
    },

  }
}

  </script>
  <style lang="less" scoped>
.el-dialog__wrapper {
  z-index: 5000 !important;
}
// ::v-deep .el-message-box__wrapper {
// z-index: 6000 !important;
// to fix el-message弹框被el-dialog覆盖的问题。
// .el-message-box__wrapper {
//   z-index: 99999999 !important;
// }
</style>


