var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "crumbs" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", [
                _c("i", { staticClass: "el-icon-lx-people" }),
                _vm._v(" 用户管理\n            "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "div",
            { staticClass: "handle-box" },
            [
              _vm.globalPermission.user.add
                ? _c(
                    "el-button",
                    {
                      staticClass: "handle-del mr10",
                      attrs: { type: "primary", icon: "el-icon-lx-roundadd" },
                      on: { click: _vm.addItem },
                    },
                    [_vm._v("新增用户\n            ")]
                  )
                : _vm._e(),
              _c("el-input", {
                staticClass: "handle-input mr10",
                attrs: { placeholder: "用户名" },
                model: {
                  value: _vm.query.username,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "username", $$v)
                  },
                  expression: "query.username",
                },
              }),
              _c("el-input", {
                staticClass: "handle-input mr10",
                attrs: { placeholder: "姓名" },
                model: {
                  value: _vm.query.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "name", $$v)
                  },
                  expression: "query.name",
                },
              }),
              _c(
                "el-select",
                {
                  staticClass: "handle-input mr10",
                  attrs: { placeholder: "状态" },
                  model: {
                    value: _vm.query.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "status", $$v)
                    },
                    expression: "query.status",
                  },
                },
                _vm._l(_vm.statusOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.handleSearch },
                },
                [_vm._v("搜索")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticClass: "table",
              attrs: {
                data: _vm.tableData,
                border: "",
                "header-cell-class-name": "table-header",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "username", label: "用户名" },
              }),
              _c("el-table-column", { attrs: { prop: "name", label: "姓名" } }),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "手机号" },
              }),
              _c("el-table-column", {
                attrs: { prop: "cityName", label: "账号权限" },
              }),
              _c("el-table-column", {
                attrs: { label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              type:
                                scope.row.status === 0
                                  ? "success"
                                  : scope.row.status === 1
                                  ? "danger"
                                  : "",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.status == 0 ? "启用" : "禁用") +
                                "\n                    "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "updatedTime", label: "创建时间" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.globalPermission.user.edit
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", icon: "el-icon-edit" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(
                                      scope.row.id,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("编辑\n                    ")]
                            )
                          : _vm._e(),
                        _vm.globalPermission.user.delete
                          ? _c(
                              "el-button",
                              {
                                staticClass: "red",
                                attrs: { type: "text", icon: "el-icon-delete" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(
                                      scope.row.id,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("删除\n                    ")]
                            )
                          : _vm._e(),
                        _vm.globalPermission.user.forbid
                          ? _c(
                              "el-button",
                              {
                                class: scope.row.status === 0 ? "red" : "",
                                attrs: {
                                  type: "text",
                                  icon:
                                    scope.row.status === 0
                                      ? "el-icon-warning"
                                      : scope.row.status === 1
                                      ? "el-icon-success"
                                      : "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleForbid(
                                      scope.row.id,
                                      scope.row.status
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.status == 0 ? "禁用" : "启用"
                                  ) + "\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.globalPermission.user.role
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  icon: "el-icon-setting",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRoles(
                                      scope.row.id,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("赋予角色\n                    ")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", icon: "el-icon-setting" },
                            on: {
                              click: function ($event) {
                                return _vm.resetUserPassword(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("重置密码\n                    ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "total, prev, pager, next",
                  "current-page": _vm.query.pageNum,
                  "page-size": _vm.query.pageSize,
                  total: _vm.pageTotal,
                },
                on: { "current-change": _vm.handlePageChange },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑", visible: _vm.editVisible, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.editVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "70px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户姓名" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "登录账号" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "username", $$v)
                      },
                      expression: "form.username",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号码" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "账号权限" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "handle-input mr10",
                      attrs: {
                        disabled: _vm.editShow,
                        placeholder: "是否市州账号",
                      },
                      model: {
                        value: _vm.form.city,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "city", $$v)
                        },
                        expression: "form.city",
                      },
                    },
                    _vm._l(_vm.colOptions, function (item) {
                      return _c("el-option", {
                        key: item.key,
                        attrs: { label: item.label, value: item.key },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.editVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveEdit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "角色管理",
            visible: _vm.editRolesVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editRolesVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "70px" } },
            [
              [
                _c(
                  "el-checkbox-group",
                  {
                    model: {
                      value: _vm.checkUserRolesIds,
                      callback: function ($$v) {
                        _vm.checkUserRolesIds = $$v
                      },
                      expression: "checkUserRolesIds",
                    },
                  },
                  _vm._l(_vm.allUserRoles, function (role) {
                    return _c(
                      "el-checkbox",
                      {
                        key: role,
                        staticClass: "checkbox-rows",
                        attrs: { label: role.id, border: "" },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(role.roleName) +
                            "\n                    "
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.editRolesVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveUserRoleList },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }