import { render, staticRenderFns } from "./CompetitionDialog.vue?vue&type=template&id=60c75ffe&scoped=true&"
import script from "./CompetitionDialog.vue?vue&type=script&lang=js&"
export * from "./CompetitionDialog.vue?vue&type=script&lang=js&"
import style0 from "./CompetitionDialog.vue?vue&type=style&index=0&id=60c75ffe&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60c75ffe",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/wp/Desktop/chuanti-pc/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('60c75ffe')) {
      api.createRecord('60c75ffe', component.options)
    } else {
      api.reload('60c75ffe', component.options)
    }
    module.hot.accept("./CompetitionDialog.vue?vue&type=template&id=60c75ffe&scoped=true&", function () {
      api.rerender('60c75ffe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/page/competition/components/CompetitionDialog.vue"
export default component.exports