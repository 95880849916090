var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑图片",
            visible: _vm._dialogVisible,
            "before-close": _vm.close,
            width: "832px",
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm._dialogVisible = $event
            },
          },
        },
        [
          _c("div", [
            _vm.isMore2M
              ? _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                  _vm._v("当前图片大小："),
                  _c("span", { staticStyle: { color: "red" } }, [
                    _vm._v(_vm._s(_vm.currentImageSize)),
                  ]),
                ])
              : _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                  _vm._v("当前图片大小："),
                  _c("span", { staticStyle: { color: "black" } }, [
                    _vm._v(_vm._s(_vm.currentImageSize)),
                  ]),
                ]),
            _c(
              "div",
              { staticStyle: { width: "800px", height: "600px" } },
              [
                _c("vueCropper", {
                  ref: "cropper",
                  attrs: {
                    img: _vm.option.img,
                    outputSize: _vm.option.size,
                    outputType: _vm.option.outputType,
                    info: _vm.option.info,
                    canScale: _vm.option.canScale,
                    autoCrop: _vm.option.autoCrop,
                    autoCropWidth: _vm.option.autoCropWidth,
                    autoCropHeight: _vm.option.autoCropHeight,
                    fixed: _vm.option.fixed,
                    fixedNumber: _vm.option.fixedNumber,
                    centerBox: _vm.option.centerBox,
                    enlarge: 1,
                  },
                  on: {
                    cropMoving: _vm.cropMoving,
                    changeCropNow: _vm.changeCropNow,
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.close } },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.submit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }