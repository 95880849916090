var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { "separator-class": "el-icon-arrow-right" } },
            [
              _c("el-breadcrumb-item", [_vm._v("赛事编辑")]),
              _c("el-breadcrumb-item", [
                _c("span", { staticClass: "compile" }, [_vm._v("组别详情")]),
              ]),
              _c("el-breadcrumb-item", [_vm._v("报名表单")]),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "top",
              attrs: { type: "primary" },
              on: { click: _vm.addBtn },
            },
            [_vm._v("添加组别")]
          ),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticClass: "top",
              attrs: {
                data: _vm.tableData,
                border: "",
                "header-cell-class-name": "table-header",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "colName",
                  align: "center",
                  label: "序号",
                  width: "60",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.pager.pageIndex - 1) * _vm.pager.pageSize +
                                scope.$index +
                                1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "groupName",
                  align: "center",
                  label: "组别名称",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "price", align: "center", label: "参赛价格" },
              }),
              _c("el-table-column", {
                attrs: { prop: "stock", align: "center", label: "参赛人数" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "参赛年龄" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.minAge))]),
                        _c("div", [_vm._v("至")]),
                        _c("div", [_vm._v(_vm._s(scope.row.maxAge))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "gender", align: "center", label: "参赛性别" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(_vm._s(_vm.genderType[scope.row.gender])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "groupType", align: "center", label: "类别" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "matchStartTime",
                  align: "center",
                  label: "比赛开始时间",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "matchEndTime",
                  align: "center",
                  label: "比赛结束时间",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "positionName",
                  align: "center",
                  label: "可兼报",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(scope.row, scope.$index)
                              },
                            },
                          },
                          [_vm._v("编辑 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.apply(
                                  scope.row.enrolls,
                                  scope.$index
                                )
                              },
                            },
                          },
                          [_vm._v("兼报 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "red",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(scope.$index)
                              },
                            },
                          },
                          [_vm._v("删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "top" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.back } },
                [_vm._v("上一步")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.nextStep } },
                [_vm._v("下一步")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("DialogNnter", {
        attrs: {
          dialogVisible: _vm.dialogVisible,
          tableData: _vm.tableData,
          pluralisticArr: _vm.pluralisticArr,
          indexs: _vm.indexs,
        },
        on: {
          "update:dialogVisible": function ($event) {
            _vm.dialogVisible = $event
          },
          "update:dialog-visible": function ($event) {
            _vm.dialogVisible = $event
          },
          "update:pluralisticArr": function ($event) {
            _vm.pluralisticArr = $event
          },
          "update:pluralistic-arr": function ($event) {
            _vm.pluralisticArr = $event
          },
          pluralistic: _vm.pluralistic,
        },
      }),
      _c("DialogGroup", {
        attrs: {
          dialogVisible: _vm.dialogVisible2,
          groupInfoData: _vm.groupInfo,
          tableData: _vm.tableData,
          indexs: _vm.indexs,
          state: _vm.state,
        },
        on: {
          "update:dialogVisible": function ($event) {
            _vm.dialogVisible2 = $event
          },
          "update:dialog-visible": function ($event) {
            _vm.dialogVisible2 = $event
          },
          "update:indexs": function ($event) {
            _vm.indexs = $event
          },
          "update:state": function ($event) {
            _vm.state = $event
          },
          group: _vm.group,
          edit: _vm.edit,
          initialize: _vm.initialize,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }