
import DialogHCode from './DialogHCode';
import DialogTime from './DialogTime';
import TinymceEditor from './TinymceEditor';
import VueImageCropper from './VueImageCropper'
import UploadImg from './UploadImg'
import VueImageCropperNew from './VueImageCropperNew'
import UploadImgCropperNew from './UploadImgCropperNew'


export default {
  install: function (Vue, options) {
    const components = Object.assign(
      {
        DialogHCode,
        DialogTime,
        TinymceEditor,
        VueImageCropper,
        UploadImg,
        VueImageCropperNew,
        UploadImgCropperNew
      },
    );
    Object.keys(components).forEach((key) => {
      Vue.use(components[key]);
    });
  },
};
