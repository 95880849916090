<template>
  <div>
    <!-- 兼报弹框 -->
    <el-dialog title="兼报" :visible.sync="_dialogVisible" :show-close="false" :close-on-click-modal="false"
      width="600px">
      <div>
        <el-checkbox-group v-model="enrolls">
          <el-checkbox v-for="item,index in tableData" :key="index" :label="item.groupName"></el-checkbox>
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirm" type="primary">确定</el-button>
        <el-button @click="cancel">取消</el-button>
      </span>

    </el-dialog>
  </div>
</template>
<script>
export default {
    name: 'index',
    data() {
        return {
            // enrolls: []
        };
    },
    props: {
        dialogVisible: {
            type: Boolean,
            required: true,
            default: false
        },
        tableData: {
            type: Array,
            required: true,
            default: []
        },
        pluralisticArr: {
            type: Array,
            required: true,
            default: []
        },
        indexs: {
            type: Number,
            required: true,
            default: 0
        }
    },
    computed: {
        _dialogVisible: {
            get() {
                return this.dialogVisible;
            },
            set(val) {
                this.$emit('update:dialogVisible', val);
            }
        },
        enrolls: {
            get() {
                return this.pluralisticArr;
            },
            set(val) {
                return this.$emit('update:pluralisticArr', val);
            }
        }
    },
    methods: {
        //取消
        cancel() {
            this.enrolls = []
            this._dialogVisible = false;
        },
        //确定
        confirm() {
            this.$emit('pluralistic', { enrolls: this.enrolls, index: this.indexs });
            this._dialogVisible = false;
        }
    },
    mounted() {}
};
</script>
<style  scoped lang="less">
.addIcon {
    margin-bottom: 18px;
    margin-left: 20px;
    font-size: 22px;
    cursor: pointer;
}
</style>