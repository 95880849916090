<template>
  <div>
    <el-dialog :title="title" :visible.sync="_dialogVisible" :show-close="false" :close-on-click-modal="false">
      <div>
        <el-form label-width="130px">
          <el-form-item label="奖章/证书：" required>
            <UploadImg v-model="icon"></UploadImg>
          </el-form-item>
          <el-form-item label="关联赛事：" required>
            <el-select v-model="matchId" filterable remote reserve-keyword placeholder="活动名称"
              :remote-method="remoteMethod" clearable :loading="loading">
              <el-option v-for="item in activityList" :key="item.id" :label="item.matchName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="奖章名称：" required>
            <el-input v-model="medalName" clearable placeholder="请输入"></el-input>
          </el-form-item>

        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirm" type="primary">确定</el-button>
        <el-button @click="cancel">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import UploadImg from '@/components/page/Activity/components/UploadImg'; //上传图片
import * as API from '../api/index';
export default {
    name: 'index',
    components: {
        UploadImg
    },
    data() {
        return {
            ids: '',
            loading: false,
            activityList: [],
            icon: '',
            matchId: '',
            medalName: '',
            value: ''
        };
    },
    props: {
        dialogVisible: {
            type: Boolean,
            required: true,
            default: false
        },
        title: {
            type: String,
            required: true,
            default: ''
        },
        editData: {
            type: Object,
            default: () => {}
        }
    },
    watch: {
        editData: {
            immediate: true,
            deep: true,
            handler: function (newVal) {
                this.icon = newVal.icon;
                this.matchId = newVal.matchId;
                this.medalName = newVal.medalName;
                this.ids = newVal.id;
            }
        }
    },
    computed: {
        _dialogVisible: {
            get() {
                return this.dialogVisible;
            },
            set(val) {
                this.$emit('update:dialogVisible', val);
            }
        }
    },

    methods: {
        //获取活动列表
        async GetactivityList(query) {
            let parameter = {
                applyStatus: '',
                matchName: query,
                pageNum: 1,
                pageSize: 20
            };
            let res = await API.GetactivityList(parameter);
            this.activityList = res.data.list;
            this.loading = false;
        },
        //下拉框远程搜索数据
        async remoteMethod(query) {
            this.loading = true;
            this.GetactivityList(query);
        },
        //新增赛事奖牌
        async createAchievement() {
            let parameter = {
                icon: this.icon,
                matchId: this.matchId,
                medalName: this.medalName
            };
            let res = await API.createAchievement(parameter);
            this.$message({
                message: '新增成功！',
                type: 'success'
            });
            this.$emit('update');
            this._dialogVisible = false;
        },
        //更新
        async achievementUpdate() {
            let parameter = {
                id: this.ids,
                icon: this.icon,
                matchId: this.matchId,
                medalName: this.medalName
            };
            let res = await API.achievementUpdate(parameter);
            this.$message({
                message: '更新成功！',
                type: 'success'
            });
            this.$emit('update');
            this._dialogVisible = false;
        },
        //确定
        confirm() {
            if (this.title == '新增') {
                this.createAchievement();
            } else {
                this.achievementUpdate();
            }
        },
        //取消
        cancel() {
            this._dialogVisible = false;
            this.$emit('update');
        }
    },
    mounted() {}
};
</script>
<style  scoped lang="less">
.el-input-number .el-input {
    width: 100%;
}
</style>