<template>
	<div>
		<!-- 活动预览弹框 -->
		<el-dialog :title="title" :visible.sync="_dialogVisible" :show-close="false" :close-on-click-modal="false" width="600px">
			<div class="img">
				<el-image
						v-if="imageUrl"
						:src="imageUrl"
						:preview-src-list="[imageUrl]"
				/>
				<div v-else>暂无图片</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<!-- <el-button @click="notarize" type="primary">确认</el-button> -->
				<el-button @click="close">关闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>

export default {
	name: 'DialogHCode',
	data() {
		return {
		};
	},
	props: {
		dialogVisible: {
			type: Boolean,
			required: true,
			default: false
		},
		imageUrl: {
			type: String,
			default: ''
		},
		title: {
			type: String,
			default: ''
		}
	},
	computed: {
		_dialogVisible: {
			get() {
				return this.dialogVisible;
			},
			set(val) {
				this.$emit('update:dialogVisible', val);
			}
		}
	},
	methods: {
		//关闭
		close() {
			// this.imageUrl = '';
			this._dialogVisible = false;
		},

		//确认
		notarize() {

			this.close();

		}
	},
	mounted() {}
};
</script>
<style  scoped lang="less">
.top-tip {
	color: red;
}
.img {
	display: flex;
	align-items: center;
		justify-content: center;

}

</style>