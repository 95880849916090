
import axios from '@/utils/request';



// 新建
export function add(params) {
  return axios.post(`/jump/admin/create`, params);
}
// 编辑
export function update(params) {
  return axios.post(`/jump/admin/update`, params);
}
// 列表
export function list(params) {
  return axios.post(`/jump/admin/list`, params)
}
// 上下架
export function modity(params) {
  return axios.post(`/jump/admin/status/modity`, params);
}
// 新闻列表
export function queryNewsRequestList(params) {
  return axios.post(`/manager/article/getArticles`, params)
}
// 新闻详情
export function getArticleDetail(params) {
  return axios.get(`/manager/article/getArticleDetail`, {params})
}



