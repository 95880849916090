<template>
	<div class="login-wrap">
		<div class="ms-login">
			<div class="ms-title">chuanti 资讯管理系统</div>
			<el-form :model="param" :rules="rules" ref="login" label-width="0px" class="ms-content">
				<el-form-item prop="username">
					<el-input v-model="param.username" placeholder="用户名">
						<el-button slot="prepend" icon="el-icon-lx-people"></el-button>
					</el-input>
				</el-form-item>
				<el-form-item prop="password">
					<el-input type="password" placeholder="密码" v-model="param.password" @keyup.enter.native="submitForm()">
						<el-button slot="prepend" icon="el-icon-lx-lock"></el-button>
					</el-input>
				</el-form-item>
				<div class="login-btn">
					<el-button type="primary" @click="submitForm()">登录</el-button>
				</div>
			</el-form>
		</div>
	</div>
</template>

<script>
import { validPassword } from '../../utils/validate'
import { loginPost } from '../../api/login';
import { getPermission } from '../../api/user';
var loginRequest = { url: '/usercenter/login' };
var perRequest = { url: '/usercenter/user-permission/current-user-permission' };
let Base64 = require('js-base64').Base64;

export default {
	data: function () {
		return {
			param: {
				username: '',
				password: ''
			},
			rules: {
				username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
				password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
			},
			permission: {}
		};
	},
	methods: {
		setUserPermission(userPermissionList) {
			this.doSetUserPermission(userPermissionList);
			sessionStorage.setItem('permission', JSON.stringify(this.permission));
			//sessionStorage.permission = JSON.stringify(this.permission);
		},
		doSetUserPermission(userPermissionList) {
			//逐级遍历
			for (var i = 0; i < userPermissionList.length; i++) {
				//若不是顶级，将冒号转化为层级关系
				this.toLevelShow(userPermissionList[i].permissionCode);
				//如果还有子集，进行递归设置
				if (userPermissionList[i].children.length > 0) {
					this.doSetUserPermission(userPermissionList[i].children);
				}
			}
		},
		toLevelShow(code) {
			var codeArr = code.split(':');
			if (codeArr.length == 1) {
				this.permission[codeArr[0]] = true;
			} else if (codeArr.length == 2) {
				if (typeof this.permission[codeArr[0]] == 'undefined' || this.permission[codeArr[0]] == true) {
					this.permission[codeArr[0]] = {};
				}
				this.permission[codeArr[0]][codeArr[1]] = true;
			} else if (codeArr.length == 3) {
				if (typeof this.permission[codeArr[0]] == 'undefined' || this.permission[codeArr[0]] == true) {
					this.permission[codeArr[0]] = {};
				}
				if (typeof this.permission[codeArr[0]][codeArr[1]] == 'undefined' || this.permission[codeArr[0]][codeArr[1]] == true) {
					this.permission[codeArr[0]][codeArr[1]] = {};
				}
				this.permission[codeArr[0]][codeArr[1]][codeArr[2]] = true;
			}
		},
		submitForm() {
			this.$refs.login.validate((valid) => {
				if (valid) {
					//对密码进行base64加密
					// this.param.password = Base64.encode('ct'+this.param.password);

					//  最新密码验证规则
					if (!validPassword(this.param.password)) {
						this.$message.error('密码不符合规则，请联系管理员修改')
						console.log('password='+this.param.password);
						return
                    }
					let parameter = {
						username: this.param.username,
						password: Base64.encode('ct' + this.param.password)
					};
					loginRequest.data = parameter;
					loginPost(loginRequest)
						.then((res) => {
							switch (res.code) {
								case 0:
									//登录成功，写入token
									this.$message.success('登录成功');
									localStorage.setItem('ms_username', this.param.username);
									localStorage.setItem('token', res.data);
									getPermission(perRequest).then((res2) => {
										localStorage.setItem('userPermissions', JSON.stringify(res2.data));
										this.setUserPermission(res2.data);
										this.$router.push('/user');
									});
									//this.$router.push('/');
									break;
								case 200003:
									//用户名密码错误
									this.$message.error(res.message);
									break;
								case 200004:
									//用户被禁用
									this.$message.error(res.message);
									this.$message.error('请联系管理员');
									break;
								default:
									//系统维护中，跳页面吧
									this.$message.warning('系统维护中....');
							}
						})
						.catch((error) => {
							this.$message.error(error.message);
							return Promise.reject();
						});
				} else {
					this.$message.error('请输入账号和密码');
					return false;
				}
			});
		}
	}
};
</script>

<style scoped>
.login-wrap {
	position: relative;
	width: 100%;
	height: 100%;
	background-image: url(../../assets/img/login-bg.jpg);
	background-size: 100%;
}
.ms-title {
	width: 100%;
	line-height: 50px;
	text-align: center;
	font-size: 20px;
	color: #fff;
	border-bottom: 1px solid #ddd;
}
.ms-login {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 400px;
	margin: -190px 0 0 -175px;
	border-radius: 5px;
	background: rgba(255, 255, 255, 0.3);
	overflow: hidden;
}
.ms-content {
	padding: 30px 30px;
}
.login-btn {
	text-align: center;
}
.login-btn button {
	width: 100%;
	height: 36px;
	margin-bottom: 10px;
}
.login-tips {
	font-size: 12px;
	line-height: 30px;
	color: #fff;
}
</style>