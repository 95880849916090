var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "card",
          attrs: { shadow: "always" },
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "btn" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.addTotalActivity },
                  },
                  [_vm._v("新增")]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "location" }, [
              _vm._v("\n                总条数:"),
              _c("span", [_vm._v(_vm._s(_vm.pager.total))]),
            ]),
          ]),
          _c(
            "el-table",
            { attrs: { data: _vm.tableData, border: "" } },
            [
              _c("el-table-column", {
                attrs: { align: "center", prop: "id", label: "ID" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", prop: "url", label: "图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.url
                          ? _c("el-image", {
                              staticStyle: {
                                width: "100px",
                                height: "100px",
                                "object-fit": "contain",
                              },
                              attrs: {
                                src: scope.row.url,
                                "preview-src-list": [scope.row.url],
                              },
                            })
                          : _c("div", [_vm._v("暂无图片")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", prop: "categoryId", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteApply(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "paging" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "current-page": _vm.currentPage,
                  "page-sizes": [10, 15, 30, 50, 100],
                  "page-size": 10,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.pager.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("DialogAddEditPhotos", {
        ref: "DialogAddEdit",
        attrs: { dialogVisible: _vm.dialogVisible },
        on: {
          "update:dialogVisible": function ($event) {
            _vm.dialogVisible = $event
          },
          "update:dialog-visible": function ($event) {
            _vm.dialogVisible = $event
          },
          reloadData: _vm.reloadData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }