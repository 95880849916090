<script src="../../main.js"></script>
<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-edit-outline"></i> 意见反馈
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <el-input v-model="query.sugDesc" placeholder="问题描述" class="handle-input mr10"></el-input>
                <el-select v-model="query.status" placeholder="状态" class="handle-input mr10">
                    <el-option
                            v-for="item in statusOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
                <el-button type="primary" icon="el-icon-search"  @click="handleSearch">搜索</el-button>
            </div>
            <el-table
                    :data="tableData"
                    border
                    class="table"
                    ref="multipleTable"
                    header-cell-class-name="table-header"
            >
                <el-table-column prop="sugDesc" label="问题描述"></el-table-column>
                <el-table-column prop="sugStatus" label="状态">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.sugStatus==0" type="info">未处理</el-tag>
                        <el-tag v-if="scope.row.sugStatus==1" type="success">已处理</el-tag>
                        <el-tag v-if="scope.row.sugStatus==2" type="danger">未受理</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="cusUid" label="联系人ID"></el-table-column>
                <el-table-column prop="nickName" label="昵称"></el-table-column>
                <el-table-column prop="phone" label="手机号"></el-table-column>
                <el-table-column prop="qq" label="QQ号"></el-table-column>
                <el-table-column label="建议问题图片" align="center">
                    <template slot-scope="scope">
                        <el-button
                                type="text"
                                icon="el-icon-lx-attention"
                                @click="handleImage(scope.row.id,scope.row.opinionImg)"
                        >查看</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="createdTime" label="提交时间"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button
                                type="text"
                                icon="el-icon-edit"
                                @click="handleEdit(scope.row.id, scope.row)"
                                v-if="globalPermission.suggest.edit"
                        >处理用户反馈
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                        background
                        layout="total, prev, pager, next"
                        :current-page="query.pageNum"
                        :page-size="query.pageSize"
                        :total="pageTotal"
                        @current-change="handlePageChange"
                ></el-pagination>
            </div>
        </div>

        <el-dialog title="建议问题图片" :visible.sync="imageVisible" width="40%">
            <el-table
                    :data="picData"
                    border
                    class="table"
                    ref="multipleTable"
                    header-cell-class-name="table-header"
            >
                <el-table-column prop="imageName" label="图片(点击预览大图)" align="center">
                    <template slot-scope="scope">
                        <el-image
                                class="table-td-thumb"
                                :src="picData[scope.$index]"
                                :preview-src-list="picData"
                        ></el-image>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>

        <!-- 编辑弹出框 -->
        <el-dialog title="编辑" :visible.sync="editVisible" width="30%">
            <el-form ref="form" :model="form" label-width="70px">
                <el-form-item label="问题描述">
                    <el-input v-model="form.sugDesc" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="处理意见">
                    <el-input v-model="form.handleDesc"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="form.sugStatus" placeholder="状态" class="handle-input mr10"@change="handleSugStatus">
                        <el-option
                                v-for="item in statusOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="clean">取 消</el-button>
                <el-button type="primary" @click="saveEdit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {getAllSuggest,saveSuggest} from '../../api/suggest';

    var queryRequest = {url: "/manager/opinion/getSuggest"};
    var updateRequest = {url: "/manager/opinion/updateSuggestInfo"};
    export default {
        name: 'usertable',
        data() {
            return {
                query: {
                    colName: '',
                    positionName: '',
                    pageNum: 1,
                    pageSize: 10
                },
                globalPermission: this.getPermission(),
                tableData: [],
                multipleSelection: [],
                delList: [],
                picData:[],
                editVisible: false,
                editRolesVisible: false,
                formIdShow: false,
                imageVisible:false,
                pageTotal: 0,
                form: {},
                idx: -1,
                id: -1,
                statusOptions: [{
                    value: 0,
                    label: '未处理'
                }, {
                    value: 1,
                    label: '已经处理'
                }, {
                    value: 2,
                    label: '未受理'
                }],
            };
        },
        created() {
            queryRequest.data = this.query;
            this.getData();
        },
        methods: {
            // 获取数据
            getData() {
                //获取栏目列表
                getAllSuggest(queryRequest).then(res => {
                    this.tableData = res.data.list;
                    this.pageTotal = res.data.total;
                });
            },
            clean(){
                this.editVisible=false;
                this.handleSearch();
            },
            getPermission() {
                return JSON.parse(sessionStorage.getItem("permission"));
            },
            // 触发搜索按钮
            handleSearch() {
                this.$set(this.query, 'pageNum', 1);
                this.getData();
            },
            handleSugStatus(id){
                let obj = this.statusOptions.find(item =>{
                    return item.value === id
                });

            },
            // 打开图片窗口
            handleImage(id,imgs) {
                this.id=id;
                if (imgs!==''){
                    this.picData = imgs.split(',');
                }
                this.imageVisible = true;
            },
            // 编辑操作
            handleEdit(id, row) {
                this.id = id;
                this.form = row;
                this.form.id = this.id;
                this.editVisible = true;
            },
            // 保存编辑
            saveEdit() {
                //this.$set(this.tableData, this.idx, this.form);
                    updateRequest.data = this.form;
                    saveSuggest(updateRequest).then(res => {
                        if (res.code == 0) {
                            this.$message.success(`修改成功`);
                            this.editVisible = false;
                            this.handleSearch();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
            },
            // 分页导航
            handlePageChange(val) {
                this.$set(this.query, 'pageNum', val);
                this.getData();
            }
        }
    };
</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 130px;
        display: inline-block;
    }

    .table {
        width: 100%;
        font-size: 14px;
    }

    .red {
        color: #ff0000;
    }

    .mr10 {
        margin-right: 10px;
    }

    .table-td-thumb {
        display: block;
        margin: auto;
        width: 40px;
        height: 40px;
    }

    .show {
        display: block;
    }

    .hide {
        display: none;
    }

    .checkbox-rows {
        width: 130px;
        margin-top: 10px;
    }

    .el-checkbox.is-bordered + .el-checkbox.is-bordered {
        margin-left: 0px;
    }
</style>
