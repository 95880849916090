<template>
	<div>
		<!-- 审核弹框 -->
		<el-dialog title="审核" :visible.sync="_dialogVisible" :show-close="false" :close-on-click-modal="false" width="600px">
			<div>
				<el-form>
					<el-form-item label="审核：" label-width="100px">
						<el-radio v-model="radio" label="101">通过</el-radio>
						<el-radio v-model="radio" label="103">不通过</el-radio>
					</el-form-item>
					<el-form-item label="备注：" label-width="100px" v-if="radio == 103">
						<el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="textarea"> </el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="notarize" type="primary">确认</el-button>
				<el-button @click="close">关闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
//107 发布
//103 不通过
//101 通过
import * as API from '@/api/activity';
export default {
	name: 'index',
	data() {
		return {
			radio: '',
			textarea: ''
		};
	},
	props: {
		dialogVisible: {
			type: Boolean,
			required: true,
			default: false
		},
		auditId: {
			required: true,
			default: ''
		}
	},
	computed: {
		_dialogVisible: {
			get() {
				return this.dialogVisible;
			},
			set(val) {
				this.$emit('update:dialogVisible', val);
			}
		}
	},
	methods: {
		//关闭
		close() {
			this.radio = '';
			this.textarea = '';
			this._dialogVisible = false;
		},
		//审核活动
		async ApplyActivity() {
			let parameter = {
				id: this.auditId,
				applyStr: this.textarea,
				matchStatus: this.radio
			};
			let res = await API.ApplyActivity(parameter);
			this.close();
			this.$emit('refresh');
			this.$message({
				message: '操作成功',
				type: 'success'
			});
		},
		//确认审核
		notarize() {
			if (!this.radio) {
				return this.$message.error('请选择审核状态');
			}
			if (this.radio == '103' && !this.textarea) {
				return this.$message.error('请填写备注');
			}
			this.ApplyActivity();
		}
	},
	mounted() {}
};
</script>
<style  scoped lang="less">
</style>