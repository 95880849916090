import request from '../utils/request';

export const getAllSuggest = query => {
    return request({
        url: query.url,
        method: 'post',
        data: query.data
    });
};


export const saveSuggest = query => {
    return request({
        url: query.url,
        method: 'post',
        data: query.data
    });
};


