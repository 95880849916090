
import request from '@/utils/request';

// 创建赛事奖牌
export const createAchievement = params => {
    return request({
        url: `/manager/achievement/all/match/create`, 
        method: 'post',
        data: params
    });
};

// 获取活动列表
export const GetactivityList = params => {
    return request({
        url: `/manager/activity/getActivityList`,
        method: 'post',
        data: params
    });
};
// 获取奖章列表
export const achievementList = params => {
    return request({
        url: `/manager/achievement/all/get/match/page`,
        method: 'post',
        data: params
    });
};

// 更新赛事奖牌,上下架也通过这接口(传ID deleted)
export const achievementUpdate = params => {
    return request({
        url: `/manager/achievement/all/match/update`,
        method: 'post',
        data: params
    });
};

