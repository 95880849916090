<script src="../../main.js"></script>
<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-people"></i> 栏目管理
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <el-button
                        type="primary"
                        icon="el-icon-lx-roundadd"
                        class="handle-del mr10"
                        @click="addItem"
                        v-if="globalPermission.tabs.add"
                >新增栏目
                </el-button>
                <el-input v-model="query.colName" placeholder="栏目名称" class="handle-input mr10"></el-input>
                <el-button type="primary" icon="el-icon-search"  @click="handleSearch">搜索</el-button>
            </div>
            <el-table
                    :data="tableData"
                    border
                    class="table"
                    ref="multipleTable"
                    header-cell-class-name="table-header"
                    @selection-change="handleSelectionChange"
            >
                <el-table-column prop="colName" label="栏目名"></el-table-column>
                <el-table-column prop="positionName" label="栏目位置">
                    <template slot-scope="scope">
                        <el-tag
                                :type="scope.row.positionName==='first_page'?'success':(scope.row.positionName==='live_page'?'danger':'')"
                        >{{scope.row.positionName=='first_page'?"首页栏目":"直播栏目"}}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="city" label="栏目类型">
                  <template slot-scope="scope">
                    <el-tag
                        :type="scope.row.city===0?'success':(scope.row.city===1?'danger':'')"
                    >{{scope.row.city==0?"省局栏目":"市州栏目"}}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="colSort" label="排序编号"></el-table-column>
                <el-table-column prop="createdTime" label="创建时间"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button
                                type="text"
                                icon="el-icon-edit"
                                @click="handleEdit(scope.row.id, scope.row)"
                                v-if="globalPermission.tabs.edit"
                        >编辑
                        </el-button>
                        <el-button
                                type="text"
                                icon="el-icon-delete"
                                class="red"
                                @click="handleDelete(scope.row.id, scope.row)"
                                v-if="globalPermission.tabs.delete"
                        >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                        background
                        layout="total, prev, pager, next"
                        :current-page="query.pageNum"
                        :page-size="query.pageSize"
                        :total="pageTotal"
                        @current-change="handlePageChange"
                ></el-pagination>
            </div>
        </div>

        <!-- 编辑弹出框 -->
        <el-dialog title="编辑" :visible.sync="editVisible" width="30%">
            <el-form ref="form" :model="form" label-width="70px">
                <el-form-item label="栏目名1">
                    <el-input v-model="form.colName"></el-input>
                </el-form-item>
                <el-form-item label="排序编号">
                    <el-input-number v-model="form.colSort"  :min="0" :max="this.pageTotal" label="序号"></el-input-number>
                </el-form-item>
                <el-form-item prop="form.city" label="市州栏目">
                  <el-radio-group v-model="form.city" :disabled="isCurEdit">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                  </el-radio-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="clean">取 消</el-button>
                <el-button type="primary" @click="saveEdit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
    import {getAllTabs, delTabs, saveTabs, editTabs} from '../../api/tabs';

    var queryRequest = {url: "/manager/column/getColumns"};
    var addRequest = {url: "/manager/column/addColumn"};
    var updateRequest = {url: "/manager/column/updateColumn"};
    var delRequest = {url: "/manager/column/delColumn"};
    export default {
        name: 'usertable',
        data() {
            return {
                query: {
                    colName: '',
                    positionName: '',
                    pageNum: 1,
                    pageSize: 10
                },
                globalPermission: this.getPermission(),
                tableData: [],
                multipleSelection: [],
                delList: [],
                editVisible: false,
                editRolesVisible: false,
                formIdShow: false,
                isCurEdit: false,
                pageTotal: 0,
                form: {},
                idx: -1,
                id: -1,
                statusOptions: [{
                    value: 'first_page',
                    label: '首页栏目'
                }, {
                    value: 'live_page',
                    label: '直播栏目'
                }],
            };
        },
        created() {
            queryRequest.data = this.query;
            this.getData();
        },
        methods: {
            // 获取数据
            getData() {
                //获取栏目列表
                getAllTabs(queryRequest).then(res => {
                    this.tableData = res.data.list;
                    this.pageTotal = res.data.total;
                });
            },
            clean(){
                this.editVisible=false;
                this.handleSearch();
            },
            getPermission() {
                return JSON.parse(sessionStorage.getItem("permission"));
            },
            // 触发搜索按钮
            handleSearch() {
                this.$set(this.query, 'pageNum', 1);
                this.getData();
            },
            // 删除操作
            handleDelete(id, row) {
                // 二次确认删除
                this.$confirm('确定要删除吗？', '提示', {
                    type: 'warning'
                }).then(() => {
                    delRequest.id = id;
                    delTabs(delRequest).then(res => {
                        if (res.code == 0) {
                            this.$message.success(`删除成功`);
                            this.handleSearch();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                    //this.tableData.splice(index, 1);
                }).catch(() => {
                });
            },
            // 多选操作
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },

            // 新增
            addItem() {
                this.form={};
                this.editVisible = true;
                this.isCurEdit = false;
            },
            // 编辑操作
            handleEdit(id, row) {
                this.id = id;
                this.form = row;
                this.form.id = this.id;
                this.editVisible = true;
                this.isCurEdit = true;
            },
            // 保存编辑
            saveEdit() {
                //this.$set(this.tableData, this.idx, this.form);
                if (this.isCurEdit) {
                    updateRequest.data = this.form;
                    editTabs(updateRequest).then(res => {
                        if (res.code == 0) {
                            this.$message.success(`修改成功`);
                            this.editVisible = false;
                            this.handleSearch();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                } else {
                    addRequest.data = this.form;
                    saveTabs(addRequest).then(res => {
                        if (res.code == 0) {
                            this.$message.success(`新增成功`);
                            this.editVisible = false;
                            this.handleSearch();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                }
            },
            // 分页导航
            handlePageChange(val) {
                this.$set(this.query, 'pageNum', val);
                this.getData();
            }
        }
    };
</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 130px;
        display: inline-block;
    }

    .table {
        width: 100%;
        font-size: 14px;
    }

    .red {
        color: #ff0000;
    }

    .mr10 {
        margin-right: 10px;
    }

    .table-td-thumb {
        display: block;
        margin: auto;
        width: 40px;
        height: 40px;
    }

    .show {
        display: block;
    }

    .hide {
        display: none;
    }

    .checkbox-rows {
        width: 130px;
        margin-top: 10px;
    }

    .el-checkbox.is-bordered + .el-checkbox.is-bordered {
        margin-left: 0px;
    }
</style>
