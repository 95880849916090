var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "crumbs" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", [
                _c("i", { staticClass: "el-icon-edit-outline" }),
                _vm._v(" 意见反馈\n            "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "div",
            { staticClass: "handle-box" },
            [
              _c("el-input", {
                staticClass: "handle-input mr10",
                attrs: { placeholder: "问题描述" },
                model: {
                  value: _vm.query.sugDesc,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "sugDesc", $$v)
                  },
                  expression: "query.sugDesc",
                },
              }),
              _c(
                "el-select",
                {
                  staticClass: "handle-input mr10",
                  attrs: { placeholder: "状态" },
                  model: {
                    value: _vm.query.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "status", $$v)
                    },
                    expression: "query.status",
                  },
                },
                _vm._l(_vm.statusOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.handleSearch },
                },
                [_vm._v("搜索")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticClass: "table",
              attrs: {
                data: _vm.tableData,
                border: "",
                "header-cell-class-name": "table-header",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "sugDesc", label: "问题描述" },
              }),
              _c("el-table-column", {
                attrs: { prop: "sugStatus", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.sugStatus == 0
                          ? _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v("未处理"),
                            ])
                          : _vm._e(),
                        scope.row.sugStatus == 1
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("已处理"),
                            ])
                          : _vm._e(),
                        scope.row.sugStatus == 2
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("未受理"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "cusUid", label: "联系人ID" },
              }),
              _c("el-table-column", {
                attrs: { prop: "nickName", label: "昵称" },
              }),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "手机号" },
              }),
              _c("el-table-column", { attrs: { prop: "qq", label: "QQ号" } }),
              _c("el-table-column", {
                attrs: { label: "建议问题图片", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              icon: "el-icon-lx-attention",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleImage(
                                  scope.row.id,
                                  scope.row.opinionImg
                                )
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "createdTime", label: "提交时间" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.globalPermission.suggest.edit
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", icon: "el-icon-edit" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(
                                      scope.row.id,
                                      scope.row
                                    )
                                  },
                                },
                              },
                              [_vm._v("处理用户反馈\n                    ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "total, prev, pager, next",
                  "current-page": _vm.query.pageNum,
                  "page-size": _vm.query.pageSize,
                  total: _vm.pageTotal,
                },
                on: { "current-change": _vm.handlePageChange },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "建议问题图片",
            visible: _vm.imageVisible,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.imageVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticClass: "table",
              attrs: {
                data: _vm.picData,
                border: "",
                "header-cell-class-name": "table-header",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "imageName",
                  label: "图片(点击预览大图)",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          staticClass: "table-td-thumb",
                          attrs: {
                            src: _vm.picData[scope.$index],
                            "preview-src-list": _vm.picData,
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑", visible: _vm.editVisible, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.editVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "70px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "问题描述" } },
                [
                  _c("el-input", {
                    attrs: { disabled: true },
                    model: {
                      value: _vm.form.sugDesc,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sugDesc", $$v)
                      },
                      expression: "form.sugDesc",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "处理意见" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.handleDesc,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "handleDesc", $$v)
                      },
                      expression: "form.handleDesc",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "handle-input mr10",
                      attrs: { placeholder: "状态" },
                      on: { change: _vm.handleSugStatus },
                      model: {
                        value: _vm.form.sugStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "sugStatus", $$v)
                        },
                        expression: "form.sugStatus",
                      },
                    },
                    _vm._l(_vm.statusOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clean } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveEdit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }