var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("v-head"),
      _c("v-sidebar"),
      _c(
        "div",
        {
          staticClass: "content-box",
          class: { "content-collapse": _vm.collapse },
        },
        [
          _c("v-tags"),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "transition",
                { attrs: { name: "move", mode: "out-in" } },
                [
                  _c(
                    "keep-alive",
                    { attrs: { include: _vm.tagsList } },
                    [_c("router-view")],
                    1
                  ),
                ],
                1
              ),
              _c("el-backtop", { attrs: { target: ".content" } }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }