<template>
  <div>
    <div class="container">
      <!-- 2.组别详情 -->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>赛事编辑</el-breadcrumb-item>
        <el-breadcrumb-item> <span class="compile">组别详情</span></el-breadcrumb-item>
        <el-breadcrumb-item>报名表单</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button type="primary" class="top" @click="addBtn">添加组别</el-button>
      <!-- 表格区域 -->
      <el-table :data="tableData" border class="top" ref="multipleTable" header-cell-class-name="table-header">
        <el-table-column prop="colName" align="center" label="序号" width="60">
          <template slot-scope="scope">
            <span>{{ (pager.pageIndex - 1) * pager.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="groupName" align="center" label="组别名称"> </el-table-column>
        <el-table-column prop="price" align="center" label="参赛价格"></el-table-column>
        <el-table-column prop="stock" align="center" label="参赛人数"></el-table-column>
        <el-table-column align="center" label="参赛年龄">
          <template slot-scope="scope">
            <div>{{ scope.row.minAge }}</div>
            <div>至</div>
            <div>{{ scope.row.maxAge }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="gender" align="center" label="参赛性别">
          <template slot-scope="scope">
            <div>{{ genderType[scope.row.gender] }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="groupType" align="center" label="类别"></el-table-column>
        <el-table-column prop="matchStartTime" align="center" label="比赛开始时间"></el-table-column>
        <el-table-column prop="matchEndTime" align="center" label="比赛结束时间"></el-table-column>
        <el-table-column prop="positionName" align="center" label="可兼报"></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row, scope.$index)">编辑 </el-button>
            <el-button type="text" @click="apply(scope.row.enrolls, scope.$index)">兼报 </el-button>
            <el-button type="text" class="red" @click="handleDelete(scope.$index)">删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="top">
        <el-button type="primary" @click="back">上一步</el-button>
        <el-button type="primary" @click="nextStep">下一步</el-button>
      </div>
    </div>
    <DialogNnter :dialogVisible.sync="dialogVisible" :tableData="tableData" :pluralisticArr.sync="pluralisticArr"
      :indexs="indexs" @pluralistic="pluralistic" />
    <DialogGroup :dialogVisible.sync="dialogVisible2" :groupInfoData="groupInfo" :tableData="tableData"
      :indexs.sync="indexs" :state.sync="state" @group="group" @edit="edit" @initialize="initialize" />
  </div>
</template>
<script>
import DialogNnter from './components/DialogNnter'; //兼报弹框
import DialogGroup from './components/DialogGroup'; //添加组别弹框
export default {
    components: {
        DialogNnter,
        DialogGroup
    },
    name: 'groupDetail',
    data() {
        return {
            pluralisticArr: [], //兼报数组
            info: {},
            indexs: 0, //下标
            state: '', //判断增加还是编辑
            genderType: {
                0: '女',
                1: '男',
                2: '不限'
            },
            dialogVisible: false,
            dialogVisible2: false,
            tableData: [],
            pager: {
                pageIndex: 1,
                pageSize: 15,
                total: 0
            },
            groupInfo: {
                groupName: '', //组别名称
                price: '', //参赛价格
                stock: '', //参赛人数
                gender: '', //参赛性别
                groupType: '', //组别类型
                address: '', //三级联动地址 通过-拼接(省-市-区)
                provinceId: 26, //省编号-ID
                cityId: '', //市编号-ID
                areaId: '', //区编号-ID
                addressDetail: '', //详细地址
                payType: 0, //线上缴付
                matchStartTime: '', //比赛开始时间
                matchEndTime: '', //比赛结束时间
                minAge: '', //最小年龄
                maxAge: '', //最大年龄
                enrolls: '', //兼报
            }
        };
    },
    methods: {
        //兼报数据回调
        pluralistic(val) {
            this.tableData[val.index].enrolls = val.enrolls.join(',');
            localStorage.setItem('tableData', JSON.stringify(this.tableData));
        },
        //每次都让侦听器执行
        initialize() {
            this.groupInfo = {
                groupName: '', //组别名称
                price: '', //参赛价格
                stock: '', //参赛人数
                gender: '', //参赛性别
                groupType: '', //组别类型
                address: '', //三级联动地址 通过-拼接(省-市-区)
                provinceId: 26, //省编号-ID
                cityId: '', //市编号-ID
                areaId: '', //区编号-ID
                addressDetail: '', //详细地址
                payType: 0, //线上缴付
                matchStartTime: '', //比赛开始时间
                matchEndTime: '', //比赛结束时间
                minAge: '', //最小年龄
                maxAge: '' ,//最大年龄
                 enrolls: '', //兼报
            };
            this.state = '';
        },
        //拿到添加组别的数据
        group(val) {
            this.tableData.push(val);
            localStorage.setItem('tableData', JSON.stringify(this.tableData));
        },
        //拿到编辑组别的数据
        edit(val) {
            this.$set(this.tableData, val.index, val.groupInfo);
            localStorage.setItem('tableData', JSON.stringify(this.tableData));
        },
        //下一步
        nextStep() {
            if (this.tableData.length == 0) return this.$message.error('组别不能为空');
            this.$router.push({
                name: 'registrationForm',
                params: { info: this.info }
            });
        },
        //上一步
        back() {
            this.$router.push({
                path: 'activityEdit'
            });
        },
        //兼报
        apply(val, index) {
          console.log(val)
            if (val) {
                this.pluralisticArr = val.split(',');
            }
            this.indexs = index;
            this.dialogVisible = true;
        },
        //添加组别
        addBtn() {
            this.dialogVisible2 = true;
            this.state = 'add';
        },
        //编辑
        handleEdit(val, index) {
            this.groupInfo = val;
            this.dialogVisible2 = true;
            this.state = 'edit';
            this.indexs = index;
        },
        //删除
        handleDelete(index) {
            this.$confirm('此操作将永久删除该内容, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.tableData.splice(index, 1);
                this.$message({
                    type: 'success',
                    message: '操作成功!'
                });
            });
        }
    },
    mounted() {
        // let res = localStorage.getItem('tableData');
        // this.tableData = JSON.parse(res)
    },
    activated() {
        if (this.$route.params.groupInfo) {
            this.tableData = this.$route.params.groupInfo;
            this.info = this.$route.params.info;
        }
    }
};
</script>
<style  scoped lang="less">
.compile {
    color: #409eff;
    font-weight: bold;
}
.top {
    margin-top: 20px;
}
</style>